import { useDispatch, useIsLoggedIn, useSelections } from "hooks";
import {
  type RaceMarketsType,
  RaceMarketTypeTypes,
  type RaceOutcomeType,
  RaceStatuses,
} from "sections/Betting/Race/hooks/RacingTypes";
import React, { useContext, useEffect, useState } from "react";

import cx from "classnames";

import * as styles from "./RaceCustomMarkets.module.scss";
import Odds from "sections/Betting/Odds";
import { FirebaseContext } from "context/Firebase";
import { toggleSelection } from "sections/Betting/Betslip/betslipSlice";
import { mapRaceToEvent } from "../../utilities/racingUtilities";
import { RaceContext } from "sections/Betting/Race";
import { useRaceMarketsAndCompetitors } from "sections/Betting/Race/hooks/useRace";
import { RaceIcon } from "sections/Betting/Racing/components";
import { OddsChanges } from "components/OddsChanges";
import { ReactComponent as ArrowDown } from "components/assets/flucs-down.svg";
import { ReactComponent as ArrowUp } from "components/assets/flucs-up.svg";

const Outcomes = ({
  outcomes,
  customMarket,
}: {
  outcomes: [string, RaceOutcomeType][];
  customMarket: RaceMarketsType;
}) => {
  const {
    props: { selections },
  } = useSelections();

  const { userPreferences } = useContext(FirebaseContext);

  const { toggleRace } = useContext(RaceContext);

  return (
    <div className={styles.outcomes}>
      {outcomes.length > 0 &&
        outcomes.map(([outcomeId, outcome]) => (
          <OddsChanges
            odds={Number(outcome.odds)}
            key={outcomeId}
            className={cx(styles.outcome, {
              [styles.active]: selections.includes(outcomeId),
              [styles.disabled]: customMarket.status !== "ACTIVE",
            })}
            onClick={() => toggleRace(outcomeId, customMarket.id, outcome.odds)}
          >
            <span className={styles.label}>{outcome.name}</span>
            <div className={styles.oddsContainer}>
              <Odds
                base={Number(outcome.odds)}
                zero={`-`}
                format={userPreferences.oddsFormat}
                className={styles.odds}
              />
              <div className={styles.arrowUp}>
                <ArrowUp />
              </div>

              <div className={styles.arrowDown}>
                <ArrowDown />
              </div>
            </div>
          </OddsChanges>
        ))}
    </div>
  );
};

export const RaceCustomMarkets = ({ marketId }: { marketId?: string }) => {
  const { markets, race } = useContext(RaceContext);

  const customMarkets = markets?.filter(
    (market) =>
      market.marketType === RaceMarketTypeTypes.Custom &&
      (marketId ? market.id === marketId : market.status === "ACTIVE"),
  );

  if (!customMarkets || customMarkets.length == 0) return null;

  return (
    <div>
      {Object.entries(customMarkets).map(([marketId, customMarket]) => {
        const outcomes = Object.entries(customMarket.outcomes);

        return (
          <div className={styles.content} key={marketId}>
            <div>
              <div className={styles.heading}>
                <div>
                  <RaceIcon promotionIsAvailable={true} sport={race.sport} />
                </div>
                <div className={styles.title}>
                  {customMarket.name} <br />
                  <span className={styles.subTitle}>Promotional Market</span>
                </div>
              </div>
              <Outcomes outcomes={outcomes} customMarket={customMarket} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const RaceCustomMarketsWithContext = ({
  eventId,
  marketId,
  outcomeId,
  addToSlip,
}: {
  eventId: string;
  marketId: string;
  outcomeId: string;
  addToSlip: boolean;
}) => {
  const dispatch = useDispatch();

  const [addedToBetSlip, setAddedToBetSlip] = useState(false);
  const {
    props: { selections },
  } = useSelections();
  const { race, competitors, markets, raceLoading } =
    useRaceMarketsAndCompetitors(eventId);

  useEffect(() => {
    if (
      !raceLoading &&
      !addedToBetSlip &&
      addToSlip &&
      race?.status === RaceStatuses.OPEN &&
      !selections.includes(outcomeId)
    ) {
      if (!raceLoading && markets.length > 0) {
        handleToggleSelection(
          outcomeId,
          marketId,
          markets?.find((market) => market.id === marketId)?.outcomes?.[
            outcomeId
          ]?.odds,
        );
        setAddedToBetSlip(true);
      }
    }
  }, [
    raceLoading,
    markets,
    competitors,
    addToSlip,
    addedToBetSlip,
    race?.status,
    outcomeId,
    selections,
  ]);

  const handleToggleSelection = (
    outcomeId: string,
    marketId: string,
    odds: string,
  ) => {
    dispatch(
      toggleSelection(
        mapRaceToEvent({ event: race, competitors, markets }),
        {
          type: "race",
          outcomeId: outcomeId,
          marketId: marketId,
          sourceId: eventId,
          eventId: eventId,
          odds: Number(odds),
          attributes: {
            raceNumber: race?.number?.toString(),
            region: race?.region,
          },
        },
        "racing",
      ),
    );
  };

  if (raceLoading) return null;

  return (
    <RaceContext.Provider
      value={
        {
          race,
          competitors,
          markets,
          toggleRace: handleToggleSelection,
          raceMeeting: { sport: race.sport } as any,
        } as any
      }
    >
      <RaceCustomMarkets marketId={marketId} />
    </RaceContext.Provider>
  );
};

import React, { useContext, useMemo, useState } from "react";
import { zeroRightClassName } from "react-remove-scroll-bar";

import cx from "classnames";
import { useSelector, useDispatch, useProfile, useDisplayAtLeast } from "hooks";
import { Icon, Link, BackButton, PageLoader, navigate } from "library";
import { NavLogo } from "./NavLogo";
import { selectUnfilteredSelectionsCount } from "sections/Betting/Betslip/betslipSlice";
import { FirebaseContext } from "context/Firebase";
import { selectPickemsNotificationCount } from "sections/Pickems/PickSlip/pickslipSlice";
import { useIsLoggedIn, useNotifications } from "hooks";
import { setModal, togglePanel } from "utilities/UI/uiSlice";
import { useCashAvailable, usePreferences } from "hooks/firestore";
import { useIsClient } from "usehooks-ts";
import { usePageContext } from "hooks/usePageContext";
import { useDisplayMode, useIsMobile } from "hooks";
import useCalculatePlayerOffset from "hooks/useCalculatePlayerOffset";
import useCalculateSmartBannerOffset from "hooks/useCalculateSmartBannerOffset";
import { clearToaster } from "hooks/ui/useToast";
import { useIsLoading } from "hooks/ui/useLoading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Popover/Popover";
import { ScrollArea } from "components/ScrollArea";

import { Notification } from "components/layout/Notification";
import { onDismissNotification } from "sections/Account/accountSlice";
import { ContextMenu } from "sections/Account/Tabs/Notifications/ContextMenu";
import { QuickBalance } from "components/layout/QuickBalance/QuickBalance";
import { Button } from "components/Button";

import * as styles from "./Navigation.module.scss";

type NavPanelProps = {
  name: string;
  badge?: number;
  iconType?: string;
  className?: string;
};

const NavAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const isClient = useIsClient();

  if (isLoggedIn || !isClient) {
    return null;
  }

  return (
    <div className={styles.auth}>
      <Button size={`sm`} onClick={() => dispatch(setModal("signUp"))}>
        Sign Up
      </Button>
      <Button
        size={`sm`}
        variant={`secondary`}
        onClick={() => dispatch(setModal("signIn"))}
      >
        Log In
      </Button>
    </div>
  );
};

const NavWallet = () => {
  const isLoggedIn = useIsLoggedIn();
  const [profile, loading] = useProfile();
  const { available, promo } = useCashAvailable();

  if (!isLoggedIn) {
    return <div className={styles.walletSpacing} />;
  }

  return (
    <Link to={`/wallet/deposit/`}>
      <QuickBalance
        loading={loading}
        available={available}
        promo={promo}
        name={profile?.username || ""}
        avatarSrc={profile?.avatarUri}
      />
    </Link>
  );
};

const NavPanels = () => {
  const isLoggedIn = useIsLoggedIn();
  const { notifications } = useContext(FirebaseContext);
  const betCount = useSelector(selectUnfilteredSelectionsCount);
  const { section } = usePageContext();
  const pickemsNotificationCount = useSelector(selectPickemsNotificationCount);

  return (
    <div className={styles.panels}>
      {isLoggedIn ? (
        <NotificationPanel badge={notifications?.count || 0} />
      ) : null}
      <NavPanel name={`search`} iconType={`search`} />
      {section === "pickems" ? (
        <NavPanel
          name={`pickslip`}
          iconType={`pick`}
          badge={pickemsNotificationCount}
        />
      ) : null}
      <NavPanel
        className={styles.hideOnMobile}
        name={`betslip`}
        iconType={`betslip`}
        badge={betCount}
      />
    </div>
  );
};

const NotificationPanel = ({ badge }: { badge?: number }) => {
  const [notifications, loading] = useNotifications(1, 10);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();

  return (
    <div
      className={cx(styles.panel, {
        [styles.active]: isOpen,
      })}
      // on mobile just send user to the notifications page
      onClick={() => isMobile && navigate("/account/notifications")}
    >
      <Popover
        open={isOpen}
        onOpenChange={(isOpen) => setIsOpen(isMobile ? false : isOpen)}
      >
        <PopoverTrigger className={styles.panelButton}>
          <Icon type={`bell`} />
          {badge ? (
            <div className={styles.badge}>{badge >= 10 ? "9+" : badge}</div>
          ) : null}
        </PopoverTrigger>

        <PopoverContent sideOffset={20} className={styles.panelPopover}>
          <div className={styles.notificationsHeader}>
            <h4>Notifications</h4>
            <ContextMenu />
          </div>

          <ScrollArea>
            <div className={styles.notifications}>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  {notifications.map((notification) => (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      onDismissNotification={(id) =>
                        dispatch(onDismissNotification(id))
                      }
                    />
                  ))}
                </>
              )}
            </div>
          </ScrollArea>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const NavPanel = ({ name, iconType, badge, className = "" }: NavPanelProps) => {
  const isClient = useIsClient();
  const dispatch = useDispatch();
  const activePanel = useSelector((state: any) => state.utilities.ui.panel);
  const menu = useSelector((state: any) => state.utilities.ui.menu);
  const displayMode = useDisplayMode();
  const atLeastDesktopXl = useDisplayAtLeast("desktopXl");

  // make sure we don't set any panels as active on SSR
  const panelsVisible = isClient ? atLeastDesktopXl || menu === "right" : false;

  return (
    <div
      key={displayMode}
      className={cx(
        styles.panel,
        {
          [styles.active]: panelsVisible && activePanel === name,
        },
        className,
      )}
      onClick={() => {
        clearToaster();
        dispatch(togglePanel(name as any));
      }}
    >
      <Icon type={iconType} />
      {badge ? (
        <div className={styles.badge}>{badge >= 10 ? "9+" : badge}</div>
      ) : null}
    </div>
  );
};
export const Navigation = () => {
  const isMobile = useIsMobile();
  const isClient = useIsClient();
  const smartBannerOffset = useCalculateSmartBannerOffset();
  const playerOffset = useCalculatePlayerOffset();
  const topOffset = playerOffset + smartBannerOffset;
  const isLoading = useIsLoading();
  const [preferences] = usePreferences();

  const homeUrl = useMemo(() => {
    if (!isClient) {
      return "/";
    }

    // fixes warning about homeUrl being different between server and client.
    if (typeof window !== "undefined") {
      if (preferences?.defaultHub === "sports") {
        return `/sports/betting/?page=1&tab=next`;
      } else if (preferences?.defaultHub === "esports") {
        return `/betting/?page=1&tab=next`;
      }
    }

    return `/racing/betting/next-to-jump/both/?page=1`;
  }, [preferences?.defaultHub, isClient]);

  return (
    <header
      className={cx(styles.container, zeroRightClassName)}
      style={{ top: topOffset }}
    >
      {isMobile && isClient ? <BackButton /> : null}
      <Link to={homeUrl}>
        <NavLogo loading={isLoading} />
      </Link>

      <div className={styles.right}>
        <NavAuth />
        <NavPanels />
        <NavWallet />
      </div>
    </header>
  );
};

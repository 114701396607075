import React from "react";
import { Provider } from "react-redux";
import { getStore } from "./getStore";
import { FirebaseContextProvider } from "context/Firebase";

export function wrapWithProvider(element) {
  const store = getStore();

  return (
    <Provider store={store}>
      <FirebaseContextProvider element={element} />
    </Provider>
  );
}

export default wrapWithProvider;

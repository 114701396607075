import pick from "lodash/pick";
import { isMarketOpenForBets } from "./markets";
import type { SubOutcome } from "sections/Entries/types";
import type { SubOutcomesType } from "types/BetTypes";

export const getSubSelectionsFromOutcomes = (
  outcomes: Record<string, SubOutcome>,
): SubOutcomesType[] => {
  return Object.entries(outcomes).flatMap(([outcomeId, outcome]) => {
    if (!isMarketOpenForBets(outcome.marketStatus)) return [];

    return {
      outcomeId,
      name: outcome.outcomeName,
      ...pick(outcome, ["marketId", "marketName", "marketStatus"]),
    } satisfies SubOutcomesType;
  });
};

import React, { useCallback, useReducer } from "react";
import { Icon } from "../Icon";
import type { StreamType } from "types/ContentTypes";
import { hideStreamIconBefore } from "utilities/display";
import { useTimeout } from "usehooks-ts";
import { useDispatch, useIsLoggedIn } from "hooks";
import { openStream as doOpenStream, setModal } from "utilities/UI/uiSlice";
import { parseDate } from "utilities/dateUtilities";

import * as styles from "./StreamButton.module.scss";

type StreamButtonProps<T> = T & {
  title: string;
  sport?: string;
  startTime?: Date;
  status: string;
  className?: string;
  onOpen?: () => void;
  eventId: string;
  isPlaceholder: boolean;
};

const normaliseURL = (url: string) => {
  if (url && url.endsWith("/")) {
    return url.substring(0, url.length - 1);
  }
  return url;
};

export const StreamButton = (props: StreamButtonProps<StreamType>) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0); //https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const { url, status, isPlaceholder, startTime, className } = props;
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  let delay = null;
  let visible = false;
  if (!url && !isPlaceholder) {
    //no stream or placeholder so ignore
  } else if (status === "DEACTIVATED") {
    visible = true;
  } else if (!["ACTIVE", "SUSPENDED", "LIVE"].includes(status)) {
    visible = false;
  } else {
    const parsedStartTime =
      startTime instanceof Date ? startTime : parseDate(startTime);
    const showAt = hideStreamIconBefore(parsedStartTime);
    delay = showAt - new Date().valueOf();
    if (delay < 0) {
      delay = null;
      visible = true;
    }
  }

  useTimeout(() => {
    //because the visibility rules are time based we want to trigger a rerender, so they are executed again.
    forceUpdate();
  }, delay);

  const openStream = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();

      if (isLoggedIn) {
        dispatch(
          doOpenStream({
            ...props,
            url: normaliseURL(url),
            muted: true,
            playing: true,
          }),
        );
      } else {
        dispatch(setModal("signIn"));
      }
    },
    [isLoggedIn],
  );

  return visible ? (
    <div>
      <div
        className={[styles.active, className].filter(Boolean).join(" ")}
        onClick={openStream}
        title="Watch Now"
      >
        <Icon svg type="video" />
      </div>
    </div>
  ) : null;
};

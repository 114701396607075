const regionToCurrencyMapping = {
  au: "AUD",
  us: "USD",
  nz: "NZD",
  ca: "CAD",
};

export function getCurrencyForRegion(regionCode) {
  return regionToCurrencyMapping[regionCode] || "USD";
}

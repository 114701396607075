import { Alert } from "components/Alert";
import { SupportLink } from "components/SupportLink";
import React from "react";

export default ({ isSP }: { isSP: boolean }) => {
  return isSP ? (
    <Alert variant="info" indicator="top" size="small">
      <span>
        SP - indicates a bet can be placed at{" "}
        <SupportLink options={{ articleId: "5351471094799" }} hideChevron>
          Starting Price
        </SupportLink>
        , the final fixed price available at race close
      </span>
    </Alert>
  ) : null;
};

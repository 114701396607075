import React, { useContext } from "react";
import { Link } from "../Link";
import {
  Hits,
  Index,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom";
import { TitleIcon } from "../Icon/TitleIcon";
import { Icon } from "../Icon";
import Countdown from "../Countdown";

import { HUDPanelEmpty } from "../HUDPanel";
import StatusPill from "../StatusPill";
import { usePermissions } from "hooks/useHasPermission";
import { FirebaseContext } from "context/Firebase";
import {
  useIsApplePayAvailable,
  useIsGooglePayAvailable,
  useIsLoggedIn,
} from "hooks";

import * as styles from "./SearchBox.module.scss";
import type { Hit } from "react-instantsearch-core";
import { parseDate } from "utilities/dateUtilities";
import { useTitles } from "hooks/graphql/useTitles";

const PickemHit = ({
  hit: { objectID: id, title, category, sport, startTime, status },
}) => {
  const titles = useTitles();

  return (
    <div className={styles.pickemsHit}>
      <Link to={`pickems/contest/${id}/`}>
        <div className={styles.icon}>
          {sport === "multiple" ? (
            <Icon type="cup" />
          ) : (
            <TitleIcon title={titles[sport.toLowerCase()]} />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.eventName}>{title}</div>
          {category ? <div className={styles.category}>{category}</div> : null}
        </div>
        <div className={styles.countdown}>
          {status === "live" ? (
            <StatusPill color={"orange"}>Live</StatusPill>
          ) : (
            <Countdown
              className={styles.starts}
              onlyBiggest={true}
              until={parseDate(startTime)}
              expired={"Waiting"}
            />
          )}
        </div>
      </Link>
    </div>
  );
};

const SportHit = ({ hit: { sport, slug, hub } }) => {
  const titles = useTitles();
  return (
    <div className={styles.sportHit}>
      <Link
        to={`${
          hub === "sports" ? "/sports" : hub === "racing" ? "/racing" : ""
        }/${slug}/betting`}
      >
        <div className={styles.icon}>
          <TitleIcon title={titles[slug]} />
        </div>
        <div className={styles.sport}>{sport}</div>
      </Link>
    </div>
  );
};

const RacingHit = ({
  hit: {
    objectID,
    event,
    meetingId,
    venue,
    sport,
    scheduledStartTime,
    raceNumber,
    competitors,
    jockeys,
    trainers,
  },
  query,
}: {
  hit: Hit & {
    event: string;
    meetingId: string;
    venue: string;
    sport: string;
    scheduledStartTime: string;
    raceNumber: number;
    competitors: string[];
    jockeys: string[];
    trainers: string[];
  };
  query: string;
}) => {
  const titles = useTitles();

  const competitor = competitors?.find(
    (competitor) =>
      competitor?.toLowerCase().indexOf(query?.toLowerCase()) >= 0,
  );

  const jockey = jockeys?.find(
    (jockey) => jockey?.toLowerCase().indexOf(query?.toLowerCase()) >= 0,
  );

  const trainer = trainers?.find(
    (trainer) => trainer?.toLowerCase().indexOf(query?.toLowerCase()) >= 0,
  );

  return (
    <div className={styles.matchHit}>
      <Link to={`/racing/betting/race/${meetingId}/${objectID}`}>
        <div className={styles.title}>
          <TitleIcon title={titles[sport?.toLowerCase()]} />
        </div>
        <div className={styles.info}>
          <div className={styles.eventName}>
            {venue}
            {" - "}
            <span className={styles.eventNameDetail}>R{raceNumber}</span>
          </div>
          <div className={styles.tournament}>
            {event} <br />
            <div className={styles.details}>
              {competitor
                ? `Competitor: ${competitor} `
                : jockey
                  ? `Jockey: ${jockey} `
                  : trainer
                    ? `Trainer: ${trainer}`
                    : null}
            </div>
          </div>
        </div>
        {scheduledStartTime && (
          <div className={styles.countdown}>
            <Countdown
              className={styles.starts}
              onlyBiggest={true}
              until={parseDate(scheduledStartTime)}
              disabledMouseover
            />
          </div>
        )}
      </Link>
    </div>
  );
};

const BettingHit = ({
  hit: {
    sport,
    objectID: id,
    event,
    tournament,
    type,
    startTime,
    bettingType,
    sportCategory,
  },
}) => {
  const titles = useTitles();
  const urlPrefix = sportCategory === "conventional" ? "/sports" : "";

  return (
    <div className={styles.matchHit}>
      <Link
        to={
          type === "match"
            ? `${urlPrefix}/betting/match/${id}`
            : `${urlPrefix}/betting/outright/${id}`
        }
      >
        <div className={styles.title}>
          <TitleIcon title={titles[sport?.toLowerCase()]} />
        </div>
        <div className={styles.info}>
          <div className={styles.eventName}>{event}</div>
          {tournament ? (
            <div className={styles.tournament}>{tournament}</div>
          ) : null}
        </div>
        {startTime && type === "match" ? (
          <div className={styles.countdown}>
            {bettingType === "live" ? (
              <StatusPill color={`orange`}>Live</StatusPill>
            ) : (
              <Countdown
                className={styles.starts}
                onlyBiggest={true}
                until={parseDate(startTime)}
                disabledMouseover
              />
            )}
          </div>
        ) : null}
      </Link>
    </div>
  );
};

const PageHit = ({ hit: { title, url, icon } }) => {
  return (
    <div className={styles.pageHit}>
      <Link to={`/${url}`}>
        <div className={styles.icon}>
          <Icon type={icon || "pageLink"} />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>{title}</div>
        </div>
      </Link>
    </div>
  );
};

const IndexResults = connectStateResults<any>(({ searchResults, children }) =>
  searchResults && searchResults.nbHits !== 0 ? children : null,
);

const NoResults = connectStateResults(({ allSearchResults }) => {
  const hasResults =
    allSearchResults &&
    Object.values(allSearchResults).some((index) => index.nbHits > 0);

  if (hasResults) {
    return null;
  }

  return (
    <div className={styles.empty}>
      <HUDPanelEmpty
        icon="searchRemove"
        title="No results found"
        message="Please try a new search term"
      />
    </div>
  );
});

const getPagesFilters = (
  index: { name: string },
  loggedIn: boolean,
  country: string,
  isApplePayAvailable: boolean,
  isGooglePayAvailable: boolean,
  permissions: {
    viewEsportsMarkets: string;
    viewSportsMarkets: string;
    viewLiveMarkets: string;
  },
): string[] => {
  const filters = [];
  if (index.name === "pages") {
    if (!loggedIn) {
      filters.push(`country:false AND requiresSession:false`);
    } else if (!isApplePayAvailable && !isGooglePayAvailable) {
      filters.push(
        `(country:false OR country:${country}) AND requiresApplePay:false AND requiresGooglePay:false`,
      );
    } else if (!isApplePayAvailable) {
      filters.push(
        `(country:false OR country:${country}) AND requiresApplePay:false`,
      );
    } else if (!isGooglePayAvailable) {
      filters.push(
        `(country:false OR country:${country}) AND requiresGooglePay:false`,
      );
    } else {
      filters.push(`(country:false OR country:${country})`);
    }
  }

  // figure out required filters
  if (permissions?.viewLiveMarkets !== "GRANTED" && index.name === "matches") {
    filters.push(`bettingType:"prematch"`);
  }

  if (
    index.name === "matches" ||
    index.name === "outrights" ||
    index.name === "sports"
  ) {
    if (
      permissions.viewEsportsMarkets === "GRANTED" &&
      permissions.viewSportsMarkets !== "GRANTED"
    ) {
      filters.push("hub:esports");
    }

    if (
      permissions.viewEsportsMarkets !== "GRANTED" &&
      permissions.viewSportsMarkets === "GRANTED"
    ) {
      filters.push("hub:sports");
    }
  }

  return filters;
};

const HitsInIndex = ({ index, query }) => {
  const permissions = usePermissions();
  const isApplePayAvailable = useIsApplePayAvailable();
  const isGooglePayAvailable = useIsGooglePayAvailable();
  const loggedIn = useIsLoggedIn();
  const { profile } = useContext(FirebaseContext);

  let Component;

  switch (index.name) {
    case "races":
      Component = RacingHit;
      break;
    case "matches":
    case "outrights":
      Component = BettingHit;
      break;
    case "sports":
      Component = SportHit;
      break;
    case "pickems_upcoming":
    case "pickems_live":
      Component = PickemHit;
      break;
    case "pages":
      Component = PageHit;
      break;
  }

  const filters = getPagesFilters(
    index,
    loggedIn,
    profile?.country,
    isApplePayAvailable,
    isGooglePayAvailable,
    permissions as any,
  );

  return (
    <Index indexName={index.name}>
      <IndexResults>
        <div className={styles.index}>
          <span>{index.title}</span>
        </div>
        <Hits
          hitComponent={({ hit }) => <Component hit={hit} query={query} />}
        />
      </IndexResults>
      <Configure filters={filters.join(" AND ")} hitsPerPage={6} />
    </Index>
  );
};

const SearchResult = ({ indices, query }) => {
  if (query && query.length) {
    return (
      <div className={styles.searchResult}>
        {indices.map((index) => (
          <HitsInIndex index={index} key={index.name} query={query} />
        ))}
        <NoResults />
      </div>
    );
  }

  return (
    <div className={styles.empty}>
      <HUDPanelEmpty
        icon="search"
        message="Search for matches, outrights, pick’ems and more"
        title="Looking for something?"
      />
    </div>
  );
};

export default SearchResult;

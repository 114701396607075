import React from "react";
import { ReactComponent as SrmLogo } from "components/assets/srm-tag.svg";
import { ReactComponent as SgmLogo } from "components/assets/sgm-tag.svg";
import type { sameEventMultiType } from "sections/Betting/components/SameEventMultiBuilder/SameEventMultiBuilder";

export default ({ type }: { type: sameEventMultiType }) => {
  switch (type) {
    case "srm":
      return <SrmLogo type="srmIcon" />;
    case "sgm":
      return <SgmLogo type="sgmIcon" />;
    default:
      return null;
  }
};

import React, { memo } from "react";
import cx from "classnames";
import { TitleIcon } from "library/components/Icon/TitleIcon";
import type { ITitleCounts } from "types/EventCounts";
import { Icon } from "../Icon";
import { BadgeCount } from "../BadgeCount";
import { ScrollBox } from "library";
import type { Title } from "hooks/graphql/useTitles";

import * as styles from "./TitleFilter.module.scss";

export const TitleFilter = memo(
  ({
    titles,
    activeTitle,
    activeTitles,
    selectTitle,
    showAll: propShowAll = false,
    titleCounts,
  }: {
    titles?: Title[];
    activeTitle?: string;
    activeTitles?: string[];
    selectTitle?: (id: string) => void;
    showAll?: boolean;
    titleCounts: ITitleCounts;
  }) => {
    const allTitles = titles || [];
    activeTitles = (activeTitles || (activeTitle ? [activeTitle] : [])).filter(
      Boolean,
    );
    const showAll =
      propShowAll || activeTitles.length == 0
        ? titleCounts.total == 0
        : !activeTitles.some((it) => titleCounts[it] > 0);
    const displayTitles = showAll
      ? allTitles
      : allTitles.filter(
          (t) => titleCounts[t.slug] > 0 || activeTitles.indexOf(t.slug) >= 0,
        );
    const allActive =
      activeTitles.length < 1 || activeTitles.length >= allTitles.length;
    const displayItems = displayTitles.map((x) => ({
      ...x,
      count: titleCounts[x.slug],
      icon: x.icon,
      isActive: activeTitles.includes(x.slug),
      isDisabled: allTitles.includes(x) === false,
    }));

    return (
      <ScrollBox scrollDistance={400} dependencies={[displayItems]}>
        <div className={styles.titleFilter}>
          <div
            key={"title-all-heading"}
            className={cx(styles.title, {
              [styles.active]: allActive,
              [styles.greyed]: !allActive,
            })}
            onClick={() => selectTitle && selectTitle("")}
          >
            <Icon type={allActive ? "all" : "close"} inline />
          </div>
          {displayItems.map((title) => (
            <div
              key={"title-" + title.slug}
              className={cx(styles.title, {
                [styles.active]: title.isActive,
                [styles.greyed]:
                  !allActive && activeTitles.length > 0 && !title.isActive,
                [styles.disabled]: title.isDisabled,
              })}
              onClick={() => selectTitle && selectTitle(title.slug)}
            >
              <TitleIcon title={title} isActive={title.isActive || allActive} />
              {title.count > 0 ? <BadgeCount count={title.count} /> : null}
            </div>
          ))}
        </div>
      </ScrollBox>
    );
  },
);

TitleFilter.displayName = "DisplayName";

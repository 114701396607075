import { useEffect, useContext } from "react";
import useDisableNativeScrollRestoration from "./useDisableNativeScrollRestoration";
import { useLocation } from "@gatsbyjs/reach-router";
import { ScrollContext } from "gatsby-react-router-scroll/scroll-handler";

const useRestoreScrollPosition = (loading) => {
  useDisableNativeScrollRestoration();
  const location = useLocation();
  const state = useContext<any>(ScrollContext);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const position = state.read(location, location.key);

    if (!loading && position) {
      window.scrollTo(0, position);
    }
  }, [loading]);
};

export default useRestoreScrollPosition;

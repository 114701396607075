import provinces from "provinces";
import countries from "country-list";
import { useEffect, useState } from "react";

const useCountryAndState = (countryCode: string, stateCode: string) => {
  const [country, setCountry] = useState(undefined);
  const [state, setState] = useState(undefined);

  useEffect(() => {
    const country = countries.getName(countryCode);
    if (country) {
      setCountry(country);
    }
  }, [countryCode]);

  useEffect(() => {
    const state = provinces.find((p) => p.short === stateCode);
    if (state) {
      setState(state.name);
    }
  }, [stateCode]);

  return [country ?? countryCode, state ?? stateCode];
};

export default useCountryAndState;

import React from "react";
import * as styles from "./PromoIcon.module.scss";
import type { BettingTabs, CampaignsType } from "hooks";
import { shouldShowPromoIcon } from "sections/Betting/CampaignPromotions";
import cx from "classnames";
import { ReactComponent as SmallPromoIcon } from "components/assets/icon-star-small.svg";

export const TabPromoIcon = ({
  Tab,
  campaigns,
}: {
  Tab: BettingTabs;
  campaigns?: CampaignsType[];
}) =>
  campaigns &&
  campaigns?.some((campaign) =>
    shouldShowPromoIcon(Tab, campaign.bettingTabs),
  ) ? (
    <SmallPromoIcon className={cx(styles.tabPromoIcon)} />
  ) : null;

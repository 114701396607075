// extracted by mini-css-extract-plugin
export var active = "ToggleGroup-Item-module--active--f258d";
export var badge = "ToggleGroup-Item-module--badge--f85e1";
export var blue = "#0eb3f2";
export var emphasis = "ToggleGroup-Item-module--emphasis--7e4e8";
export var green = "#57d33b";
export var greenLink = "ToggleGroup-Item-module--green-link--ec2d3";
export var grey = "var(--tone-mid)";
export var item = "ToggleGroup-Item-module--item--d6bb0";
export var left = "ToggleGroup-Item-module--left--1a49f";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var right = "ToggleGroup-Item-module--right--d846a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import React from "react";
import * as styles from "./ToggleGroup.Root.module.scss";
import cx from "classnames";

type RootProps = React.HTMLProps<HTMLDivElement>;
export const Root = React.forwardRef<HTMLDivElement, RootProps>(
  (props, ref) => {
    const { children, className } = props;
    return (
      <div {...props} className={cx(styles.root, className)} ref={ref}>
        {children}
      </div>
    );
  },
);

Root.displayName = "ToggleRoot";

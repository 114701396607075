import React from "react";
import cx from "classnames";
import type { PickType, SubOutcome } from "sections/Entries/types";
import { useTitle } from "hooks/graphql/useTitles";
import { OutcomeIcon } from "components/layout/OutcomeIcon";
import { Clamp } from "components/Clamp";
import { Live, LiveClampWithStream } from "components/layout/Live";

import * as styles from "./Outcome.module.scss";

type OutcomeProps = {
  index: number;
  outcome: SubOutcome;
  sport: string;
  silksUrl: string;
  isWon: boolean;
  isRace: boolean;
  withStream: boolean;

  pick: PickType;
};

const Outcome = ({
  outcome,
  silksUrl,
  sport,
  isWon,
  isRace,
  pick,
  withStream,
}: OutcomeProps) => {
  const title = useTitle(sport);
  const hasResult = !!outcome.statusText;
  const isVoid = outcome.statusText === "VOID";
  const isLive = outcome.statusText === "LIVE";

  const clamp = isLive ? (
    <>
      {withStream ? (
        <LiveClampWithStream isLive={isLive} pick={pick} />
      ) : (
        <Live />
      )}
    </>
  ) : (
    <Clamp variant={outcome.statusVariant as any}>
      {isRace && isVoid ? "SCRATCHED" : outcome.statusText}
    </Clamp>
  );

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.outcome, {
          [styles.isWon]: isWon,
          [styles.isVoid]: isVoid,
        })}
      >
        <div className={styles.icon}>
          <img alt={title.name} src={title.icon?.file?.url} />
        </div>
        <div
          className={cx(styles.outcomeAndMarket, {
            [styles.hasResult]: hasResult,
          })}
        >
          <div className={styles.outcomeName}>
            <OutcomeIcon
              className={styles.pickIcon}
              outcome={outcome}
              silksUrl={silksUrl}
            />{" "}
            {outcome.outcomeName}
          </div>
          <div className={styles.marketName}>{outcome.marketName}</div>
        </div>
      </div>
      {outcome.statusText && <div className={styles.clamp}>{clamp}</div>}
    </div>
  );
};

export { Outcome };

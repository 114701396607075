import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import cx from "classnames";
import { ReactComponent as DangerIcon } from "components/assets/danger.svg";
import { ReactComponent as CheckIcon } from "components/assets/success.svg";
import { ReactComponent as InfoIcon } from "components/assets/info.svg";
import { ReactComponent as WarningIcon } from "components/assets/warning.svg";

import * as styles from "./Alert.module.scss";

export type AlertProps = {
  className?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  title?: string;
  contentClassName?: string;
} & VariantProps<typeof alertVariants>;

const alertVariants = cva(styles.alert, {
  variants: {
    variant: {
      danger: styles.danger,
      warning: styles.warning,
      info: styles.info,
      success: styles.success,
      neutral: styles.neutral,
    },
    indicator: {
      top: styles.topIndicator,
      bottom: styles.bottomIndicator,
    },
    size: {
      small: styles.small,
      large: styles.large,
    },
  },
  defaultVariants: {
    variant: "info",
    size: "large",
  },
});

const defaultIconsForVariant = {
  danger: <DangerIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
  success: <CheckIcon />,
  neutral: <InfoIcon />,
};

const Alert = ({
  icon,
  children,
  variant = "info",
  className,
  contentClassName,
  actions,
  title,
  indicator,
  size,
}: AlertProps) => {
  const defaultIcon = defaultIconsForVariant[variant];
  // checking for undefined to make sure we can pass null to remove the icon
  const actualIcon = icon !== undefined ? icon : defaultIcon;

  return (
    <div
      className={cx(alertVariants({ variant, indicator, size }), className)}
      data-variant={variant || "info"}
      role="alert"
    >
      <div
        className={cx(styles.iconWithContent, {
          [styles.topAlign]: !!title,
        })}
      >
        {actualIcon && (
          <div data-testid="icon" className={styles.icon}>
            {actualIcon}
          </div>
        )}
        <div className={cx(styles.content, contentClassName)}>
          {title && <div className={styles.title}>{title}</div>}
          {children}
        </div>
      </div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export { Alert, alertVariants };

import React from "react";
import { Stake } from "./Stake";
import { useBetslip } from "hooks";
import cx from "classnames";
import { OddsWithChanges } from "./OddsWithChanges";
import { Payout } from "./Payout";

import * as styles from "./Summary.module.scss";

type SummaryProps = {
  odds: number;
  payout: number;
  stake: number;
  onSetStake: (stake: number) => void;
  isPromo: boolean;
  isActive: boolean;
  newOdds: number;
  isSP: boolean;
};

const Summary = ({
  stake,
  payout,
  odds,
  onSetStake,
  isPromo,
  isActive,
  newOdds,
  isSP,
}: SummaryProps) => {
  const {
    props: { betslipIsReviewingMessages, betType },
  } = useBetslip();
  const isMulti = betType === "MULTI";

  const currentOdds = newOdds || odds;

  return (
    <div className={styles.summary}>
      <div
        className={cx({
          [styles.isPromo]: isPromo,
          [styles.stake]: !stake && !betslipIsReviewingMessages && isActive,
        })}
      >
        <span className={styles.label}>Stake</span>
        <span className={styles.value}>
          <div>
            <Stake
              isActive={isActive}
              onChange={onSetStake}
              amount={stake}
              isPromo={isPromo}
            />
          </div>
          {isPromo && <div className={styles.bonus}>bonus</div>}
        </span>
        <div className={styles.circle}>&times;</div>
      </div>
      <div>
        <span className={styles.label}>Odds</span>
        <span className={styles.value}>
          <OddsWithChanges
            isSP={isMulti ? false : isSP}
            odds={currentOdds}
            animationLength={isMulti ? 0 : newOdds ? null : 3000}
          />
        </span>
        <div className={styles.circle}>=</div>
      </div>
      <div>
        <span className={styles.label}>Payout</span>
        <span className={styles.value}>
          <Payout isSP={isSP} amount={payout} />
        </span>
      </div>
    </div>
  );
};

export { Summary };

import { ScrollArea } from "components/ScrollArea/ScrollArea";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";
import type * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

type MaxHeightScrollAreaProps = {
  maxHeight?: number | string;
} & React.ComponentPropsWithoutRef<typeof ScrollArea>;

export const MaxHeightScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  MaxHeightScrollAreaProps
>(
  (
    { children, viewPortRef: externalViewPortRef, maxHeight, ...props },
    ref,
  ) => {
    const internalViewPortRef = useRef<HTMLDivElement>(null);
    const viewPortRef = externalViewPortRef || internalViewPortRef;

    const [viewPortStyles, setViewPortStyles] = useState({
      maxHeight: `${maxHeight}px`,
    });

    const checkAndSetHeight = () => {
      if (maxHeight) {
        const viewPortEl = viewPortRef?.current;

        if (viewPortEl) {
          const contentHeight = viewPortEl?.scrollHeight;
          const maxHeightPx =
            typeof maxHeight === "number" ? maxHeight : parseInt(maxHeight, 10);

          if (contentHeight > maxHeightPx) {
            setViewPortStyles({ maxHeight: `${maxHeightPx}px` });
          } else {
            setViewPortStyles({});
          }
        }
      }
    };

    useEffect(() => checkAndSetHeight(), [children, maxHeight]);

    useEventListener("resize", checkAndSetHeight);

    return (
      <ScrollArea
        {...props}
        ref={ref}
        viewPortStyles={{ ...viewPortStyles }}
        viewPortRef={viewPortRef}
      >
        {children}
      </ScrollArea>
    );
  },
);

import { usePreviousValue } from "hooks";
import React, { useState, forwardRef, useEffect } from "react";
import { useTimeout } from "usehooks-ts";
import { getOddsChangeDirection } from "utilities/sharedBettingUtilities";

type OddsAnimationProps = React.HTMLAttributes<HTMLElement> & {
  odds: number;
  animationLength?: number;
};

export default forwardRef<HTMLDivElement, OddsAnimationProps>(
  ({ odds, animationLength = 3000, children, ...props }, ref) => {
    const previousOdds = usePreviousValue(odds);

    const [upOrDown, setUpOrDown] = useState<"up" | "down" | undefined>();

    useEffect(() => {
      if (previousOdds && previousOdds !== odds) {
        setUpOrDown(getOddsChangeDirection(previousOdds, odds));
      }
    }, [odds]);

    useTimeout(() => setUpOrDown(undefined), upOrDown ? animationLength : null);

    return (
      <div ref={ref} {...props} data-status={upOrDown}>
        {children}
      </div>
    );
  },
);

import useRoute from "hooks/useRoute";

export type TabType =
  | "yesterday"
  | "next-to-jump"
  | "today"
  | "tomorrow"
  | "saturday"
  | ("sunday" & string);

export enum RegionType {
  au = "au",
  world = "world",
}

export type RacingRouteParams = {
  region: RegionType[];
  sports?: string[];
  sport?: string;
  page: string;
  selectedTitles?: string[];
  tab: TabType;
  customDate?: string;
};

export const useRacingRoute = () => {
  const defaults = {
    tab: "next-to-jump" as TabType,
    page: "1",
    region: [RegionType.au, RegionType.world],
  };

  const [setRouteValue, route] = useRoute<RacingRouteParams>(
    "/racing(/:sport)/betting(/:tab)(/)(?)(sports=:sports)(&)(page=:page)(&)(region=:region)",
    defaults,
  );

  return {
    setRouteValue,
    route,
  };
};

// extracted by mini-css-extract-plugin
export var alignCenterCenter = "Top4-module--alignCenterCenter--51c07";
export var backToTop4Button = "Top4-module--backToTop4Button--4f83c";
export var betOutcomesContainer = "Top4-module--betOutcomesContainer--e3d55";
export var blue = "#0eb3f2";
export var clickable = "Top4-module--clickable--f3a3d";
export var content = "Top4-module--content--c8b61";
export var desc = "Top4-module--desc--c01d2";
export var emphasis = "Top4-module--emphasis--928f0";
export var flucsContainer = "Top4-module--flucsContainer--ce68c";
export var green = "#57d33b";
export var greenLink = "Top4-module--green-link--bf668";
export var grey = "var(--tone-mid)";
export var hairline = "Top4-module--hairline--de21e";
export var heading = "Top4-module--heading--f497a";
export var icon = "Top4-module--icon--b8249";
export var inactive = "Top4-module--inactive--586b1";
export var message = "Top4-module--message--a4fda";
export var multi = "Top4-module--multi--a26c3";
export var noSilk = "Top4-module--noSilk--1a55c";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pillInfo = "Top4-module--pillInfo--477b4";
export var red = "#ff2741";
export var relative = "Top4-module--relative--03865";
export var row = "Top4-module--row--3336e";
export var scratchedRow = "Top4-module--scratchedRow--32dfd";
export var scratching = "Top4-module--scratching--c1ce3";
export var shortFormArrow = "Top4-module--shortFormArrow--f5f76";
export var shortFormArrowIcon = "Top4-module--shortFormArrowIcon--23670";
export var title = "Top4-module--title--b0a1f";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

import * as styles from "./ScrollBox.module.scss";
import { Pill } from "../Pill";

const ScrollBox: React.FC<{
  children: React.ReactNode;
  scrollDistance: number;
  dependencies: Array<any>;
}> = ({ children, scrollDistance = 200, dependencies }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollEventCount, setScrollEventCount] = useState(0);

  const stopPropagation = (e: React.SyntheticEvent<Element>): void => {
    e.stopPropagation();
  };

  const scrollEvent = (): void => {
    setScrollEventCount(scrollEventCount + 1);
  };

  const handleClick = (direction: string): boolean => {
    if (containerRef.current) {
      let relativeScrollDistance = 0;
      switch (direction) {
        case "left":
          relativeScrollDistance =
            containerRef.current.scrollLeft - scrollDistance;
          break;
        default:
          relativeScrollDistance =
            containerRef.current.scrollLeft + scrollDistance;
          break;
      }
      containerRef.current.scroll({
        left: relativeScrollDistance,
        behavior: "smooth",
      });
    }
    return true;
  };

  const getCurrentOffsets = (element: HTMLElement) => {
    if (!element) return { left: 0, right: 0 };
    const relativeScrollDistance = element?.scrollLeft || 0;
    const scrolledItemWidth = (element.firstChild as HTMLElement).offsetWidth;
    const containerWidth = element.parentElement.clientWidth;

    return {
      left: relativeScrollDistance,
      right: scrolledItemWidth - containerWidth - relativeScrollDistance,
    };
  };

  const isShadowRequired = (element: HTMLElement) => {
    return element?.scrollWidth > element?.clientWidth;
  };

  const currentOffsets = getCurrentOffsets(containerRef.current);

  useEffect(() => {
    setScrollEventCount(scrollEventCount + 1);
  }, [dependencies]);

  return (
    <div className={styles.scrollBox}>
      <div
        ref={containerRef}
        className={styles.scrollHolder}
        onMouseMove={stopPropagation}
        onTouchMove={stopPropagation}
        onScroll={scrollEvent}
      >
        {children}
      </div>
      {isShadowRequired(containerRef.current) && (
        <div
          className={cx(styles.shadowLeft, {
            [styles.hidden]: currentOffsets.left <= 0,
          })}
        >
          <Pill
            className={styles.scroll}
            iconType="arrow-left"
            onClick={() => handleClick("left")}
          />
        </div>
      )}
      {isShadowRequired(containerRef.current) && (
        <div
          className={cx(styles.shadowRight, {
            [styles.hidden]: currentOffsets.right <= 0,
          })}
        >
          <Pill
            className={styles.scroll}
            iconType="arrow-right"
            onClick={() => handleClick("right")}
          />
        </div>
      )}
    </div>
  );
};

export default ScrollBox;

import { BET_SUBMITTED } from "../../sections/Betting/BettingConfig";

const TYPE_SURVEY = "survey";

const mapper = (action) => {
  switch (action.type) {
    case BET_SUBMITTED:
      return (action) => ({
        hitType: TYPE_SURVEY,
        name: action.meta.name,
        email: action.meta.email,
      });
    default:
      return () => null;
  }
};

export default mapper;

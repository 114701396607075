import { useEventListener } from "usehooks-ts";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { ScrollArea } from "components/ScrollArea";
import { Shadow } from "components/Shadow";
import { SharedEntryCard } from "sections/Share/SharedEntryCard";
import type { SharedEntryPreview } from "sections/Entries/types";

import * as styles from "./ShareEntryPreview.module.scss";

type ShareEntryPreviewProps = {
  entry: SharedEntryPreview;
  isLoading: boolean;
};

export const ShareEntryPreview = ({
  entry,
  isLoading,
}: ShareEntryPreviewProps) => {
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [isStart, setIsStart] = useState(true);

  const onScroll = () => {
    const scroller = scrollAreaRef.current;
    const isStart = scroller.scrollTop <= 0;

    setIsStart(isStart);
  };

  useEventListener("scroll", onScroll, scrollAreaRef);

  useEffect(() => {
    if (scrollAreaRef.current) {
      setTimeout(() => {
        scrollAreaRef.current.scrollTo({
          top: scrollAreaRef.current.offsetHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  }, []);

  return (
    <ScrollArea
      className={styles.scrollArea}
      viewPortRef={scrollAreaRef}
      hideScrollbars
    >
      <div className={styles.preview}>
        <SharedEntryCard
          isCompact
          isPreview
          loading={isLoading}
          entry={entry}
        />

        <AnimatePresence>
          {!isStart && <Shadow className={styles.shadow} position="top" />}
        </AnimatePresence>
      </div>
    </ScrollArea>
  );
};

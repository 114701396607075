import React from "react";
import cx from "classnames";
import type { PickType } from "sections/Entries/types";
import { useTitle } from "hooks/graphql/useTitles";
import { BetOdds } from "library";
import { PickIcon } from "./PickIcon";
import { useBoolean } from "usehooks-ts";
import { Drawer } from "../../../Drawer/Drawer";
import { DrawerLineItem } from "./DrawerLineItem";
import { formatDateTime } from "utilities/datetime";
import { Clamp } from "components/Clamp";
import { Countdown } from "components/layout/Countdown";
import { LiveClampWithStream } from "components/layout/Live";

import * as styles from "./PickemPick.module.scss";
import { MoreInfo } from "components/MoreInfo";

type PickemPickProps = {
  pick: PickType;
  entryId: string;
  isLast: boolean;
};

const resultToVariant = (result: string) => {
  switch (result) {
    case "WIN":
      return "success";
    default:
      return "neutral";
  }
};

const PickemPick = ({ pick, entryId, isLast }: PickemPickProps) => {
  const title = useTitle(pick.sport);
  const { value: isExpanded, toggle: toggleExpanded } = useBoolean(false);
  const hasResult = pick.result !== "UNDECIDED";
  const isLive = pick.marketStatus === "DEACTIVATED";
  const isVoid = pick.result === "VOID";

  const teamNames = (
    <>
      {pick.teamNames[0]} <span className={styles.vs}>vs</span>{" "}
      {pick.teamNames[1]}
    </>
  );

  return (
    <div
      className={cx(styles.container, {
        [styles.isLast]: isLast,
        [styles.isVoid]: isVoid,
      })}
    >
      <div className={styles.pick}>
        <div className={styles.icon}>
          <img alt={title?.name || "pick-icon"} src={title?.icon?.file?.url} />
        </div>
        <div className={styles.selection}>
          <div className={styles.name}>
            <PickIcon className={styles.outcomeIcon} pick={pick} />
            {pick.outcomeName}
          </div>
          <div className={styles.points}>
            <BetOdds base={pick.odds} format={`points`} />
          </div>
        </div>

        <div className={styles.clamp}>
          {hasResult && (
            <Clamp variant={resultToVariant(pick.result)}>{pick.result}</Clamp>
          )}
          {!isLive && !hasResult && (
            <Countdown
              scheduledStartTime={pick.scheduledStartTime}
              pick={pick}
            />
          )}
          {<LiveClampWithStream isLive={isLive} pick={pick} />}
          <div className={styles.caret}>
            <MoreInfo isExpanded={isExpanded} onClick={toggleExpanded} />
          </div>
        </div>
      </div>

      <Drawer isOpen={isExpanded}>
        <div className={styles.drawer}>
          <DrawerLineItem
            label={`Time`}
            value={formatDateTime(pick.scheduledStartTime)}
          />
          <DrawerLineItem label={`Match`} value={teamNames} />
          <DrawerLineItem label={``} value={pick.parentName} />
          <DrawerLineItem label={`Entry Id`} value={entryId} />
        </div>
      </Drawer>
    </div>
  );
};

export { PickemPick };

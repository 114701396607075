// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "Input-module--container--32d98";
export var disabled = "Input-module--disabled--43547";
export var emphasis = "Input-module--emphasis--9def6";
export var focus = "Input-module--focus--8ea29";
export var green = "#57d33b";
export var greenLink = "Input-module--green-link--d76f9";
export var grey = "var(--tone-mid)";
export var hasError = "Input-module--hasError--fe62d";
export var hasIcon = "Input-module--hasIcon--ece8a";
export var icon = "Input-module--icon--9373c";
export var input = "Input-module--input--b2a34";
export var inputWrapper = "Input-module--inputWrapper--96265";
export var label = "Input-module--label--dbc80";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import React, { useState } from "react";
import * as Yup from "yup";
import { useDispatch, useProfile, useSelector } from "hooks";

import { FullScreenModal } from "components/FullScreenModal";
import { setModal } from "utilities/UI/uiSlice";
import { Typography } from "components/Typography";
import { Dialog } from "components/Dialog";
import { isWebView } from "utilities/display";
import { Form } from "components/Form";
import { PhoneInput } from "components/PhoneInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { validateProfile } from "utilities/api";
import { sendMessage } from "utilities/sendMessage";
import * as styles from "./ProfileCompletionModal.module.scss";
import { isValidPhoneNumber } from "utilities/helpers";

const schema = Yup.object().shape({
  phone: Yup.string()
    .required("Please enter a mobile number")
    .test("is-phone", "Please enter a valid phone number", async (value) => {
      if (!value) return true;

      return isValidPhoneNumber(value);
    }),
});

type FormValues = Yup.InferType<typeof schema>;

export const PROFILE_COMPLETION_MODAL = "profileCompletionModal";

const getModalComponent = (fullscreen: boolean) =>
  fullscreen ? FullScreenModal : Dialog;

export const ProfileCompletionModal = () => {
  const [profile] = useProfile();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const [isPosting, setIsPosting] = useState(false);

  const isOpen = useSelector(
    (state) => state.utilities.ui.modal === "profileCompletionModal",
  );

  const dispatch = useDispatch();
  const Modal = getModalComponent(isWebView());

  const handleClose = () => {
    dispatch(setModal(null));
  };

  const onSubmit = handleSubmit(async ({ phone }) => {
    const validation = await validateProfile(profile.username, "", {
      ...profile,
      phone,
    });

    if (validation.length > 0) {
      const invalidPhone = validation.find((e) => e.propertyPath === "phone");
      if (invalidPhone) {
        setError("phone", {
          type: "manual",
          message: invalidPhone.message,
        });

        return;
      }
    }

    setIsPosting(true);

    const [_, error] = await sendMessage("complete-user-profile", { phone });

    if (error) {
      setError("phone", {
        message: "Something went wrong. Try again later" + e,
      });
      setIsPosting(false);

      return;
    }

    dispatch(setModal(null));
    setIsPosting(false);
  });

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={`Incomplete Profile`}>
      <div className={styles.content}>
        <Typography variant={`body`}>
          Due to changes in Australian regulations, all users are required to
          have a valid Australian mobile number associated with their account.
          Your account has been flagged as either not having, or having an
          invalid Australian mobile number. Please update your mobile number
          below to resume betting.
        </Typography>

        <Form onSubmit={onSubmit}>
          <PhoneInput
            countryCode={`AU`}
            name={`phone`}
            control={control}
            label={`Mobile Number`}
            placeholder={`Enter your mobile number`}
            errorMessage={errors.phone?.message}
          />

          <Button
            type={`submit`}
            loading={isSubmitting || isPosting}
            className={styles.submitButton}
            gutterTop={false}
          >
            Submit
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

import React from "react";
import Blockies from "react-blockies";
import cx from "classnames";

import { avatar, image } from "./Avatar.module.scss";

type AvatarProps = {
  src?: string;
  alt: string;
  size?: number;
  name?: string;
  className?: string;

  loading?: boolean;
};

const Avatar = ({ src, alt, size = 16, name, className }: AvatarProps) => {
  return (
    <div
      className={cx(avatar, className)}
      style={{ width: size, height: size }}
    >
      {src ? (
        <img className={image} src={src} alt={alt} />
      ) : (
        <Blockies seed={name} scale={3} size={size / 3} bgColor={`#fff`} />
      )}
    </div>
  );
};

Avatar.displayName = "Avatar";

export { Avatar };

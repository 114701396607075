// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "ScrollBox-module--emphasis--b0e58";
export var green = "#57d33b";
export var greenLink = "ScrollBox-module--green-link--d0bf5";
export var grey = "var(--tone-mid)";
export var hidden = "ScrollBox-module--hidden--51db4";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var scroll = "ScrollBox-module--scroll--c4848";
export var scrollBox = "ScrollBox-module--scrollBox--65801";
export var scrollHolder = "ScrollBox-module--scrollHolder--0beb4";
export var shadowLeft = "ScrollBox-module--shadowLeft--7ad7c";
export var shadowRight = "ScrollBox-module--shadowRight--e6690";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
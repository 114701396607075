// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var buttons = "Section-module--buttons--a06e4";
export var container = "Section-module--container--dacae";
export var content = "Section-module--content--5fb0f";
export var emphasis = "Section-module--emphasis--a73f7";
export var footer = "Section-module--footer--3a553";
export var green = "#57d33b";
export var greenLink = "Section-module--green-link--43f3f";
export var grey = "var(--tone-mid)";
export var loadingBar = "Section-module--loadingBar--6ef40";
export var notchGap = "32px";
export var orange = "#f8662b";
export var overlay = "Section-module--overlay--3c346";
export var overlays = "Section-module--overlays--f3e56";
export var red = "#ff2741";
export var shareModal = "Section-module--shareModal--d1010";
export var visible = "Section-module--visible--16519";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import React, { useMemo, useState } from "react";

import { ReactComponent as IconDate } from "assets/icons/icon-date.svg";
import { addDays, format, isEqual, startOfToday, subDays } from "date-fns";
import cx from "classnames";
import type { RacingRouteParams, TabType } from "../../hooks/useRacingRoute";
import { getDateForSelectedTab } from "sections/Betting/Race/hooks/useRaceMeetings";
import { Popover, PopoverTrigger, PopoverContent } from "components/Popover";
import { Calendar } from "components/Calendar/Calendar";
import { useIsMobile } from "hooks";

import * as styles from "./DateSelector.module.scss";

export enum Days {
  mon = 1,
  tue = 2,
  wed = 3,
  thu = 4,
  fri = 5,
  sat = 6,
  sun = 7,
}

type DateSelectorProps = {
  setRouteValue: (arg: Partial<RacingRouteParams>) => void;
  route: RacingRouteParams;
};

export default ({ route, setRouteValue }: DateSelectorProps) => {
  const dateSettings = useMemo(() => {
    const days = ["yesterday", "next-to-jump", "today", "tomorrow"];

    const showSaturday = [Days.wed, Days.thu].includes(startOfToday().getDay());
    const showSunday = [Days.thu, Days.fri].includes(startOfToday().getDay());

    if (showSaturday) {
      days.push("saturday");
    }
    if (showSunday) {
      days.push("sunday");
    }

    const customDate = !days.includes(route.tab);

    return {
      max: addDays(startOfToday(), 5),
      min: subDays(startOfToday(), 10),
      value: getDateForSelectedTab(route.tab).start,
      isCustomDate: customDate,
      setDate: (date: Date) => {
        if (isEqual(getDateForSelectedTab("yesterday").start, date)) {
          setRouteValue({ tab: "yesterday" });
        } else if (isEqual(getDateForSelectedTab("today").start, date)) {
          setRouteValue({ tab: "today" });
        } else if (isEqual(getDateForSelectedTab("tomorrow").start, date)) {
          setRouteValue({ tab: "tomorrow" });
        } else if (isEqual(getDateForSelectedTab("saturday").start, date)) {
          setRouteValue({ tab: "saturday" });
        } else if (isEqual(getDateForSelectedTab("sunday").start, date)) {
          setRouteValue({ tab: "sunday" });
        } else if (!date) {
          setRouteValue({ tab: "next-to-jump" });
        } else {
          setRouteValue({ tab: format(date, "yyyy-MM-dd") as TabType });
        }

        setIsOpen(false);
      },
    };
  }, [route.tab]);

  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      <div className={cx(styles.dateSelect)}>
        <div
          className={cx(styles.icon, {
            [styles.isOpen]: isOpen,
          })}
        >
          <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
            <PopoverTrigger className={styles.trigger}>
              <IconDate />
            </PopoverTrigger>
            <PopoverContent
              align={`end`}
              alignOffset={isMobile ? 8 : -6}
              className={styles.popover}
              sideOffset={-2}
            >
              <Calendar
                mode="single"
                selected={dateSettings.value}
                disabled={{
                  before: dateSettings.min,
                  after: dateSettings.max,
                }}
                onSelect={dateSettings.setDate}
              />
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { useBetslip } from "hooks";
import { Empty } from "./Empty";
import { BetCard } from "./BetCard";

const Multi = () => {
  const {
    props: { selections },
  } = useBetslip();

  if (!selections.length) {
    return <Empty />;
  }

  return <BetCard outcomeIds={selections} />;
};

export { Multi };

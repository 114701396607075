// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Selections-module--emphasis--1efb6";
export var green = "#57d33b";
export var greenLink = "Selections-module--green-link--9271d";
export var grey = "var(--tone-mid)";
export var isOpen = "Selections-module--isOpen--12c4f";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var selections = "Selections-module--selections--30e71";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clamp = "BetPickPromotion-module--clamp--5f7cc";
export var clampItem = "BetPickPromotion-module--clampItem--8a68a";
export var complete = "BetPickPromotion-module--complete--2a814";
export var content = "BetPickPromotion-module--content--3cf60";
export var dashedLine = "BetPickPromotion-module--dashedLine--e6083";
export var emphasis = "BetPickPromotion-module--emphasis--26700";
export var green = "#57d33b";
export var greenLink = "BetPickPromotion-module--green-link--d8562";
export var grey = "var(--tone-mid)";
export var heading = "BetPickPromotion-module--heading--30b36";
export var isMobile = "BetPickPromotion-module--isMobile--7ada7";
export var isWon = "BetPickPromotion-module--isWon--baeba";
export var loading = "BetPickPromotion-module--loading--aac73";
export var notchGap = "32px";
export var orange = "#f8662b";
export var payout = "BetPickPromotion-module--payout--6ae47";
export var promoIcon = "BetPickPromotion-module--promoIcon--c95de";
export var promotionContainer = "BetPickPromotion-module--promotionContainer--27839";
export var red = "#ff2741";
export var skeleton = "BetPickPromotion-module--skeleton--54315";
export var statusArea = "BetPickPromotion-module--statusArea--07d9a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import type { Competitors } from "hooks/firestore/betting/useBetting";
import type { ITitle } from "types/ContentTypes";
import type { SourceType } from "hooks/firestore/betting/types";
import type { Timestamp } from "firebase/firestore";

export type EntryType = "BET" | "PICKEM";
export type MarketEventType = "MATCH" | "RACE";
export type OddsFormat =
  | "decimal"
  | "moneyline"
  | "points"
  | "american"
  | "fractional";

// Head to head market competitor outcome types
export const OUTCOME_TYPES = [
  "FRANCHISE_HOME",
  "FRANCHISE_AWAY",
  "PLAYER_HOME",
  "PLAYER_AWAY",
  "DRAW",
  "SRM",
  "CUSTOM",
  "OVER",
  "UNDER",
] as const;

export type OutcomeType = (typeof OUTCOME_TYPES)[number];

export const OUTCOME_RESULTS = [
  "UNDECIDED",
  "WIN",
  "LOSE",
  /**
   * The outcome has been voided. Indicates that the punter's stake should be returned or this outcome should not affect the result of a multi.
   */
  "VOID",
] as const;
export type OutcomeResult = (typeof OUTCOME_RESULTS)[number];

// The top/main market types for each event. If an event does not have one of these markets it will not be displayed
export const TOP_MARKET_TYPES = [
  "WIN_LOSE_DRAW_INC_OT",
  "WINNER_INC_OT",
  "WINNER_INC_EI",
  "WINNER_INC_SUPER_OVER",
  "DRAW_NO_BET_INC_OT",
  "WINNER_INC_OT_PENALTIES",
  //put markets inc OT first
  "WIN_LOSE_DRAW",
  "WINNER",
] as const;
export type TopMarketType = (typeof TOP_MARKET_TYPES)[number];

// these are ordered for display
export const MARKET_TYPES = [
  ...TOP_MARKET_TYPES,
  "DRAW_NO_BET",
  "DOUBLE_CHANCE",
  "MAP_HANDICAP",
  "MAP_HANDICAP_DRAW",
  "CORRECT_MAP_SCORE",
  "MAPN_WINNER_INC_OT",
  "MAPN_ROUNDN_WINNER",
  "MAPN_ROUND_HANDICAP",
  "MAPN_TOTAL_ROUNDS",
  // Lines (+alternate)
  //
  // Totals (+alternate)
  "TOTAL_MAPS",
  "TOTAL",
  "TOTAL_INC_OT",
  "HOME_TOTAL_INC_OT",
  "AWAY_TOTAL_INC_OT",
  // Handicaps (+alternate)
  "HANDICAP",
  "HANDICAP_INC_OT",
  // Doubles markets (HT/FT)
  "HALF_TIME_FULL_TIME",
  // Player Markets
  "PLAYER_TOTAL_POINTS_INC_OT",
  "PLAYER_TOTAL_ASSISTS_INC_OT",
  "PLAYER_TOTAL_REBOUNDS_INC_OT",
  "PLAYER_TOTAL_3POINTS_INC_OT",
  // 1st half markets
  "FIRST_HALF_WIN_LOSE_DRAW",
  "FIRST_HALF_DRAW_NO_BET",
  "FIRST_HALF_TOTAL",
  "FIRST_HALF_HOME_TOTAL",
  "FIRST_HALF_AWAY_TOTAL",
  "FIRST_HALF_HANDICAP",
  "FIRST_HALF_ODD_EVEN",
  // 2nd half markets
  "SECOND_HALF_WIN_LOSE_DRAW",
  "SECOND_HALF_DRAW_NO_BET",
  "SECOND_HALF_TOTAL",
  "SECOND_HALF_HOME_TOTAL",
  "SECOND_HALF_AWAY_TOTAL",
  "SECOND_HALF_HANDICAP",
  "SECOND_HALF_ODD_EVEN",
  // 1st to 4th Quarter markets
  "QUARTERN_ODD_EVEN",
  "QUARTERN_WIN_LOSE_DRAW",
  "QUARTERN_DRAW_NO_BET",
  "QUARTERN_TOTAL",
  "QUARTERN_HANDICAP",
] as const;
export type MarketType = TopMarketType | (typeof MARKET_TYPES)[number];

// these are ordered for display
export const OUTCOME_NAMES = [
  "2:0",
  "0:2",
  "1:1",
  "2:1",
  "1:2",
  "3:0",
  "0:3",
  "3:1",
  "1:3",
  "3:2",
  "2:3",
] as const;
export type OutcomeName = (typeof OUTCOME_NAMES)[number];

export const MARKET_STATUSES = [
  "ACTIVE",
  "SUSPENDED",
  "DEACTIVATED",
  "SETTLED",
  "CANCELLED",
  "UNAVAILABLE",
  "LIVE",
  "WAITING",
  "OPEN",
] as const;
export type MarketStatus = (typeof MARKET_STATUSES)[number];

export const MATCH_STATUSES = [
  "LIVE",
  "HISTORICAL",
  "PENDING",
  "COMPLETED",
  "CANCELLED",
] as const;
export type MatchStatus = (typeof MATCH_STATUSES)[number];

export const RESULT_TYPES = ["UNDECIDED", "WIN", "LOSE", "VOID"] as const;
export type ResultType = (typeof RESULT_TYPES)[number];

export const PRODUCT_TYPES = ["BETTING", "PICKEMS"] as const;
export type ProductType = (typeof PRODUCT_TYPES)[number];

export const MATCH_DISPLAY_TYPES = ["match", "outright", "contest"] as const;
export type MatchDisplayType = (typeof MATCH_DISPLAY_TYPES)[number];

export const CURRENCY_TYPES = ["AUD", "EUR", "USD", "NZD", "CAD"] as const;
export type CurrencyType = (typeof CURRENCY_TYPES)[number];

export const STAKE_SOURCE_TYPES = ["AVAILABLE", "PROMOTION_USER"] as const;
export type StakeSourceType = (typeof STAKE_SOURCE_TYPES)[number];

export const SHOW_ODDS = [
  "ALWAYS", //always show the odds
  "ACTIVE", //only show the odds if the market is active
] as const;
export type ShowOddsType = (typeof SHOW_ODDS)[number];

export interface IBetStatusInfo {
  message: string;
  color: string;
}

export type FranchiseType = {
  id: string;
  abbreviation: string;
  name: string;
  sourceId: string;
  sport: string;

  /* Betting props */
  iconUri?: string;
};

export type CompetitorType = {
  id?: string;
  type?: "PLAYER" | "FRANCHISE" | "RACER" | "FLAG";
  name?: string;
  abbreviation?: string;
  iconUri?: string;
  nationality?: string;
  number?: number;
  silksUrl?: string;
};

export interface IMatchEvent {
  id: string;
  attributes: {
    parentName?: string;
    tournamentName?: string;
    seasonName?: string;
    roundName?: string;
    matchMode?: string;
    streamExpected?: boolean;
  } & any;
  eventId: string;
  eventName: string;
  eventType: MarketEventType;
  scheduledStartTime: Date;
  source: {
    id: string;
    source: string;
  };
  sport: string;
  competitors: Competitors;
  streamUri?: string;
}

export interface IMarket {
  attributes?: {
    custom?: boolean;
    multiable?: boolean;
    promotionCashDisabled?: boolean;
  };
  betStatus?: string;
  bettingMarketId: string;
  createdAt: string;
  disabledProducts?: ProductType;
  event?: IMatchEvent;
  eventReferenceId: string;
  id: string;
  name: string;
  nextBetStop?: Date;
  outcomes: Outcome[];
  scheduledStartTime?: Date;
  specifiers: Record<string, string>;
  status: MarketStatus;
  topMarket?: boolean;
  type: MarketType;
}

export type Outcome = {
  id: string;
  competitorId?: string;
  competitor?: CompetitorType;
  type: OutcomeType;
  result: ResultType;

  /* Betting props */
  selected?: boolean;
  selectedAgainst?: boolean;
  punted?: boolean;
  abbreviation?: string;
  name?: string;
  odds?: number;
  oddsFormat?: string;
  eventId: string;
  marketId: string;
  active?: boolean;
  deadHeatFactor?: number;
  deductions?: number;
  settlementOdds?: number;
  points?: number;
};

export type EntrySelectionType = {
  competitor: CompetitorType;
  competitorId: string;
  event: IMatchEvent;
  marketName: string;
  odds?: number;
  outcomeId: string;
  outcomeType: OutcomeType;
  outcomeName: string;
  result: OutcomeResult;
  marketId: string;
  entryStatus: EntryStatus;
  status: MatchStatus;
  marketStatus: MarketStatus;
  marketBettingType?: string;
  deadHeatFactor?: number;
  deductions: number;
  settlementOdds: number;
  attributes?: Record<string, string>;
};

export interface EntryStatus {
  color?: string;
  text?: string;
}

export interface IEntryBase {
  entryType?: EntryType;
  betslipMessageId?: string;
  createdAt: Date;
  currency: CurrencyType;
  entryId: string;
  id: string;
  name: string;
  paidOut?: number;
  potentialPayout?: number;
  result: ResultType;
  selections: any[];
  stake: number;
  stakeSource?: string;
  status: MatchStatus;
  updatedAt?: Date;
  decided?: boolean;
  isPromo?: boolean;
  payOut?: number;
  entryStatus?: EntryStatus;
  earliestTime: number;
  betId?: string;
  referenceId?: string;
}

export interface IEntry extends IEntryBase {
  selections: EntrySelectionType[];
}

export interface IBetEntry extends IEntry {
  baseStatus: string;
  betSlipMessageId: string;
  stake: number;
  stakeSource: StakeSourceType;
}

export interface IEntries {
  historical: Record<string, IEntry>;
  live: Record<string, IEntry>;
}

export interface IMatchStub {
  id?: string;
  loaded?: boolean;
}

export interface IMatchDisplay {
  active?: boolean;
  title?: string;
  icon?: string;
  tournament?: string;
  round?: string;
  matchMode?: string;
  exoticsCount?: number;
}

export interface IMatch extends IMatchStub {
  topMarket?: IMarket; //outrights dont have a top market
  markets: IMarket[]; //all the active markets on the match inc top
  event: IMatchEvent;
  display?: IMatchDisplay;

  /* Betting props */
  type?: MatchDisplayType;
}

export interface IBetPick {
  stake?: number;
  outcomeId?: string;
  sourceId?: string;
  marketId?: string;
  roundId?: string;
  odds?: number;
  type?: string;
  outcome?: Outcome;
  eventId?: string;
}

export interface IBetPickBetting extends IBetPick {
  isUsingPromo?: boolean;
  accepted?: boolean;
  hasRelated?: boolean;
  rejected?: IBetRejection;
  betId?: number;
}

export interface IBetPickDetails extends IBetPick {
  competitor?: CompetitorType;
  eventName: string;
  selectionName: string;
  marketName: string;
  accepted?: boolean;
  oddsChanged?: boolean;
  freshOdds?: number;
  recentOdds?: number;
  lockedOdds?: number;
  scheduledStartTime?: Date;
  matchMode?: string;
  status?: string;
  statusMessage?: { color: string; text: string };
  isSP?: boolean;
  statusDisplay?: string;
}

export interface IMatchDisplayEntry {
  id: string;
  type: MatchDisplayType;
  title: ITitle;
  tournament: string;
  startTime: Date;
  repeatGame?: boolean;
  repeatTournament?: boolean;
}

export interface ITournamentDisplay {
  name: string;
  sport?: string;
  slug?: string;
  startTime?: Date;
  title?: ITitle;
}

export type BetPickFunc = (pick: IBetPick) => void;

export interface IBetRejection {
  reason: string;
  message: string;
  alternativeStake?: number;
}

export type betslipHubType = "sports" | "esports" | "racing";

export type SubOutcomesType = {
  name: string;
  outcomeId: string;
  marketId: string;
  marketName: string;
  marketStatus: MarketStatus;
};

export type BetSelectionType = {
  sourceType: SourceType;
  market: string;
  eventName: string;
  selectionName: string;
  outcomeId?: string;
  subOutcomes?: SubOutcomesType[];
  eventId: string;
  marketId: string;
  odds: number;
  stake: number;
  isMultiable?: boolean;
  isUsingPromo: boolean;
  outcome?: Outcome;
  competitors: Competitors;
  sport: string;
  startTime: Date;
  hub: betslipHubType;
  changes: {
    status?: MarketStatus;
    newOdds?: number;
  };
  rejected?: {
    reason: string;
    message: string;
    alternativeStake?: number;
  };
  isSP?: boolean;
  betId?: string;
  entryId?: number;
  independentPerEvent: boolean;
  relatedEventIds?: Record<string, string>;
  isIntercepted?: boolean;
  alternativeStakeReference?: string;
  timezone?: string;
  attributes?: Record<string, string>;
  semAvailableAtTs?: Timestamp;
  filters?: string[];
};

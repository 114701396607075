import { useStaticQuery, graphql } from "gatsby";

export const BANNER_DISPLAY_SECONDS = "banner_display_seconds";
export const RESPONSIBLE_GAMBLING_MESSAGE = "responsible_gambling_message";

const useContentfulSettings = () => {
  const { allContentfulSettings } = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          key
          value
        }
      }
    }
  `);

  return allContentfulSettings.nodes.reduce((settings, current) => {
    return {
      ...settings,
      [current.key]: current.value,
    };
  }, {});
};

export const useContentfulSetting = (key) => {
  const settings = useContentfulSettings();

  return settings[key];
};

export default useContentfulSettings;

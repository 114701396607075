// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clamp = "shared-module--clamp--09217";
export var close = "shared-module--close--a5deb";
export var emphasis = "shared-module--emphasis--b7128";
export var eventName = "shared-module--eventName--0b79c";
export var green = "#57d33b";
export var greenLink = "shared-module--green-link--1b3ef";
export var grey = "var(--tone-mid)";
export var header = "shared-module--header--62652";
export var hidden = "shared-module--hidden--b6197";
export var icon = "shared-module--icon--7d7b9";
export var isLast = "shared-module--isLast--1f943";
export var legs = "shared-module--legs--1003b";
export var marketName = "shared-module--marketName--0c66b";
export var multiIcon = "shared-module--multiIcon--d58b3";
export var multiSeparator = "shared-module--multiSeparator--101be";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outcomeIcon = "shared-module--outcomeIcon--81791";
export var pick = "shared-module--pick--fe58e";
export var red = "#ff2741";
export var selectionName = "shared-module--selectionName--0e21b";
export var subOutcome = "shared-module--subOutcome--cf20b";
export var subOutcomes = "shared-module--subOutcomes--75769";
export var title = "shared-module--title--f83b3";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
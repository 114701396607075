import React from "react";
import cx from "classnames";
import * as styles from "./Trigger.module.scss";

export type IconProps = {
  active: boolean;
  className?: string;
  children?: React.ReactNode;
  disableFade?: boolean;
};

export const Trigger = ({
  active,
  className,
  children,
  disableFade = false,
}: IconProps) => (
  <div
    className={cx(
      { [styles.active]: active, [styles.disableFade]: disableFade },
      styles.icon,
      className,
    )}
  >
    {children}
  </div>
);

Trigger.displayName = "ToggleTrigger";

import React, { useCallback } from "react";
import cx from "classnames";
import type { StreamType } from "types/ContentTypes";
import { useDispatch, useIsLoggedIn } from "hooks";
import { openStream as doOpenStream, setModal } from "utilities/UI/uiSlice";
import { ReactComponent as LiveIcon } from "components/layout/assets/icon-live.svg";

import * as styles from "./StreamButton.module.scss";
import { IconButton } from "components/IconButton";

type StreamButtonProps<T> = T & {
  title: string;
  sport?: string;
  startTime?: Date;
  status: string;
  className?: string;
  onOpen?: () => void;
  eventId: string;
  isPlaceholder: boolean;
};

const normaliseURL = (url: string) => {
  if (url && url.endsWith("/")) {
    return url.substring(0, url.length - 1);
  }
  return url;
};

const StreamButton = (props: StreamButtonProps<StreamType>) => {
  const { url, isPlaceholder, className } = props;
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();

  const openStream = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      if (isLoggedIn) {
        dispatch(
          doOpenStream({
            ...props,
            url: normaliseURL(url),
            muted: true,
            playing: true,
          }),
        );
      } else {
        dispatch(setModal("signIn"));
      }
    },
    [isLoggedIn],
  );

  if (!url && !isPlaceholder) {
    //no stream or placeholder so ignore
    return null;
  }

  return (
    <IconButton
      className={cx(styles.streamButton, className)}
      variant="danger"
      size="xs"
      onClick={openStream}
    >
      <LiveIcon />
    </IconButton>
  );
};

export { StreamButton };
export type { StreamButtonProps };

// extracted by mini-css-extract-plugin
export var back = "BetslipFooter-module--back--91cfd";
export var blue = "#0eb3f2";
export var button = "BetslipFooter-module--button--9ab7c";
export var buttons = "BetslipFooter-module--buttons--dfa01";
export var emphasis = "BetslipFooter-module--emphasis--53ef5";
export var footer = "BetslipFooter-module--footer--2b378";
export var green = "#57d33b";
export var greenLink = "BetslipFooter-module--green-link--57eb6";
export var grey = "var(--tone-mid)";
export var iconButton = "BetslipFooter-module--iconButton--c3653";
export var info = "BetslipFooter-module--info--a897a";
export var isReviewing = "BetslipFooter-module--isReviewing--fd89b";
export var label = "BetslipFooter-module--label--99670";
export var notchGap = "32px";
export var orange = "#f8662b";
export var panel = "BetslipFooter-module--panel--d39a3";
export var red = "#ff2741";
export var trash = "BetslipFooter-module--trash--0613a";
export var value = "BetslipFooter-module--value--b35f6";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import { useSelector } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { selectIsMultiOutcomeInBetslip } from "sections/Betting/Betslip/betslipSlice";
import type { ToggleSelectionPayload } from "./firestore/betting/types";
import { toast } from "hooks/ui/useToast";

const footerReplacementErrorMessages = [
  "Selected SGM in your betslip",
  "Add 2+ Selections",
];

export const useSameGameMulti = () => {
  const [sgmSelections, setSgmSelections] = useState<string[]>([]);
  const [latestValidSelections, setLatestValidSelections] = useState<string[]>(
    [],
  );
  const loadingOdds = useSelector((state) => state.sameEventMulti.loading);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorMessageToReplaceFooter, setErrorMessageToReplaceFooter] =
    useState<boolean>(false);

  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const toggleSgmSelection = useCallback(
    (selection: ToggleSelectionPayload) => {
      setErrorMessage(null);
      const newSelections = Array.isArray(sgmSelections) ? sgmSelections : [];

      if (newSelections.includes(selection.outcomeId)) {
        setSgmSelections(
          newSelections.filter(
            (outcomeId) => outcomeId !== selection.outcomeId,
          ),
        );
      } else {
        setSgmSelections([...newSelections, selection.outcomeId]);
      }

      if (newSelections.length <= 1) {
        setLatestValidSelections(newSelections);
      }
    },
    [sgmSelections, setSgmSelections, loadingOdds],
  );

  const removeAllSgmSelections = useCallback(() => {
    // we don't want to clear selections if we are loading odds
    if (loadingOdds) return;
    setSgmSelections([]);
  }, [loadingOdds]);

  const rollBackSelectionChange = useCallback(() => {
    setSgmSelections(latestValidSelections);
    toast({
      description: "SGM not available for these selections.",
    });
  }, [latestValidSelections]);

  const removeSgmSelection = useCallback(
    (outcomeId: string) => {
      // we don't want to remove selections if we are loading odds
      if (loadingOdds) return;
      setSgmSelections(sgmSelections.filter((x) => x !== outcomeId));
    },
    [sgmSelections, loadingOdds],
  );

  useEffect(() => {
    if (!loggedIn && sgmSelections.length > 0) {
      setSgmSelections([]);
    }
  }, [loggedIn]);

  const selectIsCurrentSelectionInBetslip = useSelector(
    selectIsMultiOutcomeInBetslip,
  );
  const isCurrentSelectionInBetslip =
    selectIsCurrentSelectionInBetslip(sgmSelections);

  useEffect(() => {
    setErrorMessage(null);
    sgmSelections.length === 0 && setErrorMessage("Add 2+ Selections");
    isCurrentSelectionInBetslip &&
      setErrorMessage("Selected SGM in your betslip");
  }, [sgmSelections, isCurrentSelectionInBetslip, errorMessage]);

  useEffect(() => {
    setErrorMessageToReplaceFooter(
      footerReplacementErrorMessages.includes(errorMessage),
    );
  }, [errorMessage]);

  return {
    SGMSelections: sgmSelections,
    toggleSgmSelection,
    removeAllSgmSelections,
    errorMessage,
    errorMessageToReplaceFooter,
    removeSgmSelection,
    rollBackSelectionChange,
    setErrorMessage,
    setLatestValidSelections,
    latestValidSelections,
  };
};

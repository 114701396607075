import React, { type PropsWithChildren } from "react";
import cx from "classnames";
import useLockScroll from "hooks/useLockScroll";
import { NavLogo } from "library/components/Navigation/NavLogo";
import { Button } from "components/Button";
import { ReactComponent as CloseIcon } from "assets/icons/icon-close-2.svg";
import { Typography } from "components/Typography";
import { Separator } from "components/Separator";

import * as styles from "./FullScreenModal.module.scss";

type FullScreenModalProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  className?: string;
}>;

const FullScreenModal = ({
  isOpen,
  onClose,
  title,
  className,
  children,
}: FullScreenModalProps): JSX.Element => {
  useLockScroll(isOpen);
  return (
    <>
      {isOpen && (
        <div
          className={cx(styles.modal, className)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div className={styles.header}>
            <div className={styles.container}>
              <div className={styles.headerContent}>
                <NavLogo />
                <Button
                  className={styles.close}
                  onClick={() => onClose()}
                  variant={"link"}
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            {title ? (
              <>
                <Typography variant={"h1"}>{title}</Typography>
                <Separator />
              </>
            ) : null}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

FullScreenModal.displayName = "FullScreenModal";

export default FullScreenModal;

import * as React from "react";
import { type VariantProps, cva } from "class-variance-authority";
import cx from "classnames";
import { Skeleton, type SkeletonProps } from "components/Skeleton";

import * as styles from "./Typography.module.scss";

type TypographySkeleton = {
  loading: boolean;
} & SkeletonProps;

type TypographyProps = {
  children: React.ReactNode;
  component?: React.ElementType;
  noSpacing?: boolean;
  extraSpacing?: boolean;
  className?: string;
  style?: React.CSSProperties;
  skeleton?: TypographySkeleton;
  htmlFor?: string;
} & VariantProps<typeof typographyVariants>;

const typographyVariants = cva(styles.base, {
  variants: {
    variant: {
      h1: styles.h1,
      h2: styles.h2,
      h3: styles.h3,
      h4: styles.h4,
      h5: styles.h5,
      h6: styles.h6,
      lead: styles.lead,
      body: styles.body,
    },
  },
  defaultVariants: {
    variant: "body",
  },
});

const variantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  lead: "p",
  body: "p",
};

const largeHeadings = ["h1", "h2", "h3"];

const Typography = ({
  variant,
  children,
  component,
  noSpacing,
  extraSpacing,
  className,
  style,
  skeleton = { loading: false },
  ...props
}: TypographyProps) => {
  const Component = component || variantMapping[variant || "body"];

  const { loading, ...rest } = skeleton;
  const skeletonProps: Partial<SkeletonProps> = {
    size: largeHeadings.includes(variant) ? "sm" : "xs",
    span: variant?.length === 2 ? "quart" : null,
    edge: "hard",
    ...rest,
  };

  return (
    <Component
      style={style}
      className={cx(
        typographyVariants({ variant }),
        {
          [styles.noSpacing]: noSpacing,
          [styles.extraSpacing]: extraSpacing,
          [styles.overflowHidden]: loading,
        },
        className,
      )}
      data-variant={variant || "body"}
      {...props}
    >
      {loading ? (
        <Skeleton
          className={cx(typographyVariants({ variant }), styles.skeleton)}
          {...skeletonProps}
        >
          {children}
        </Skeleton>
      ) : (
        children
      )}
    </Component>
  );
};

export { Typography, typographyVariants };

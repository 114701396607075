// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var caret = "BetOutright-module--caret--7e701";
export var closed = "BetOutright-module--closed--a78ff";
export var column = "BetOutright-module--column--e5c29";
export var countdown = "BetOutright-module--countdown--e9146";
export var description = "BetOutright-module--description--5e5fa";
export var emphasis = "BetOutright-module--emphasis--0ac35";
export var game = "BetOutright-module--game--4312b";
export var gameIcon = "BetOutright-module--gameIcon--0d857";
export var green = "#57d33b";
export var greenLink = "BetOutright-module--green-link--faa3b";
export var grey = "var(--tone-mid)";
export var info = "BetOutright-module--info--fd97b";
export var loading = "BetOutright-module--loading--587e7";
export var marketIcon = "BetOutright-module--marketIcon--8aa20";
export var matchType = "BetOutright-module--matchType--b1c0d";
export var missingIcon = "BetOutright-module--missingIcon--a500f";
export var noBg = "BetOutright-module--noBg--32cf3";
export var notchGap = "32px";
export var open = "BetOutright-module--open--bfda3";
export var options = "BetOutright-module--options--8ea09";
export var orange = "#f8662b";
export var outright = "BetOutright-module--outright--14d4f";
export var outrightsContainer = "BetOutright-module--outrightsContainer--6e9dd";
export var pulse = "BetOutright-module--pulse--659fb";
export var red = "#ff2741";
export var row = "BetOutright-module--row--845c2";
export var season = "BetOutright-module--season--a6928";
export var seasonName = "BetOutright-module--seasonName--fd60e";
export var seasonRow = "BetOutright-module--seasonRow--1331f";
export var short = "BetOutright-module--short--5b744";
export var standalone = "BetOutright-module--standalone--8ec46";
export var starts = "BetOutright-module--starts--0d23d";
export var startsStandalone = "BetOutright-module--startsStandalone--24797";
export var title = "BetOutright-module--title--dfd27";
export var toggle = "BetOutright-module--toggle--85bcb";
export var toggleRow = "BetOutright-module--toggleRow--3dcbb";
export var visible = "BetOutright-module--visible--de036";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var withBorder = "BetOutright-module--withBorder--f629b";
export var yellow = "#ffb100";
import {
  useLocation as useGatsbyLocation,
  type WindowLocation,
} from "@gatsbyjs/reach-router";

type LocationWithLocale = {
  pathnameWithoutLocale: string;
} & WindowLocation;

export const useLocation = (): LocationWithLocale => {
  const location = useGatsbyLocation();

  const pathnameWithoutLocale =
    typeof window === "undefined"
      ? location.pathname
      : location.pathname.replace(`/${window.___currentLocale}`, "");

  return {
    ...location,
    pathnameWithoutLocale,
  };
};

import React from "react";
import * as ToggleGroupBase from "@radix-ui/react-toggle-group";
import { type Alignment, ClearButton } from "./ToggleGroup.Item";
import cx from "classnames";
import * as styles from "./ToggleGroup.List.module.scss";

type ListProps = React.ComponentProps<typeof ToggleGroupBase.Root> & {
  alignment: Alignment;
  onClear?: () => void;
};

export const List = React.forwardRef<HTMLDivElement, ListProps>(
  (props, ref) => {
    const selectedValues = props.value;
    const { onClear, ...otherProps } = props;
    return (
      <div
        className={cx(styles.groupContainer, {
          [styles.borderLeft]: props.alignment === "right",
        })}
      >
        {props.alignment === "left" && onClear && (
          <ClearButton onClear={onClear} active={props?.value?.length === 0} />
        )}
        <ToggleGroupBase.Root
          {...otherProps}
          ref={ref}
          className={cx(styles.group, props.className)}
        >
          {React.Children.map(props.children, (child) => {
            if (!React.isValidElement(child)) return null;

            if ("value" in child.props) {
              const isActive = selectedValues.includes(child.props.value);
              return React.cloneElement(child, {
                ...child.props,
                active: isActive,
                alignment: props.alignment,
              });
            }

            return child;
          })}
        </ToggleGroupBase.Root>
        {props.alignment === "right" && onClear && (
          <ClearButton onClear={onClear} active={props?.value?.length === 0} />
        )}
      </div>
    );
  },
);

List.displayName = "ToggleList";

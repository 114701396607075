import * as React from "react";
import type { VariantProps } from "class-variance-authority";
import { Track, type trackVariants } from "./Track";
import has from "lodash/has";

import * as styles from "./Stepper.module.scss";

type StepperProps = {
  children: React.ReactNode;
  activeStep: string;
} & VariantProps<typeof trackVariants>;

type StepProps = {
  children: React.ReactNode;
  name: string;
};

const Stepper = ({ children, activeStep, variant }: StepperProps) => {
  const steps = React.Children.toArray(children).filter(
    (step: React.ReactElement) => {
      if (React.isValidElement(step) && typeof step.type !== "string") {
        return has(step.props, "name");
      }

      return false;
    },
  ) as React.ReactComponentElement<typeof Step>[];

  const currentStepIndex = steps.findIndex(
    (step: React.ReactComponentElement<typeof Step>) =>
      step.props.name === activeStep,
  );

  const stepToRender = steps[currentStepIndex];

  return (
    <>
      <div className={styles.stepper}>
        <Track steps={steps} activeStep={activeStep} variant={variant} />
      </div>
      {stepToRender}
    </>
  );
};

Stepper.DisplayName = "Stepper";

const Step = ({ children }: StepProps) => {
  return <>{children}</>;
};

Stepper.DisplayName = "Step";

export { Stepper, Step };

// zendesk
import React from "react";
import { Script, ScriptStrategy } from "gatsby";

const { idle } = ScriptStrategy;

const zendeskKey = process.env.GATSBY_ZENDESK_KEY;
let zendeskSettings = null;

if (zendeskKey) {
  const connectOnPageLoad =
    process.env.GATSBY_ZENDESK_CONNECT_ON_PAGE_LOAD === "true";
  zendeskSettings = {
    webWidget: {
      zIndex: 950,
      chat: {
        connectOnPageLoad,
      },
    },
  };
}

const zendeskUrl =
  "https://static.zdassets.com/ekr/snippet.js?key=" + zendeskKey;
const zdSettings = "zESettings=" + JSON.stringify(zendeskSettings);

// appsflyer
const appsFlyerSmartBannerSnippet = `
  !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "082a6ad8-d276-4f01-bafe-43a3e33be78c"}});
  `;

const wafChallengeUrl = process.env.GATSBY_WAF_CHALLENGE_JS_URL;
const wafCaptchaApi = process.env.GATSBY_WAF_CAPTCHA_API;

const withScripts = (children) => {
  return (
    <>
      {children}
      {zendeskSettings ? (
        <>
          <Script strategy={idle} id="ze-snippet" src={zendeskUrl} />
          <Script
            strategy={idle}
            dangerouslySetInnerHTML={{ __html: zdSettings }}
          />
        </>
      ) : null}

      <Script
        strategy={idle}
        id="appsflyer-smart-banner-script"
        dangerouslySetInnerHTML={{
          __html: appsFlyerSmartBannerSnippet,
        }}
      />
      {wafChallengeUrl ? (
        <Script
          id="aws-waf"
          strategy={idle}
          type="text/javascript"
          src={wafChallengeUrl}
        />
      ) : null}
      {wafCaptchaApi ? (
        <Script
          id="aws-waf-api"
          strategy={idle}
          type="text/javascript"
          src={wafCaptchaApi}
        />
      ) : null}
    </>
  );
};

export default withScripts;

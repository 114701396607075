import i18next from "i18next";

let instance;

const getI18nInstance = () => {
  if (!instance) {
    instance = i18next.createInstance({
      nsSeparator: "::",
    });
  }

  return instance;
};

export default getI18nInstance;

import { AUTH_LOGGED_OUT_SUCCESS } from "utilities/User/Constants";
import {
  requestStream,
  streamHadError,
  messageTimedOut,
  duplicateBetAttempted,
  appCrashed,
} from "utilities/UI/uiSlice";

import {
  UPLOAD_AVATAR_ATTEMPT,
  UPDATE_PASSWORD_ATTEMPT,
  SUBMIT_VERIFICATION_ATTEMPT,
  CHANGE_NOTIFICATION_SETTINGS,
  SUCCESS_RESENT,
  UNSUBSCRIBE,
} from "sections/Account/accountSlice";

import {
  addCashCardAttempt,
  addPicklebetCardAttempt,
  removeCashCardAttempt,
  removePicklebetCardAttempt,
  depositFundsAttempt,
  depositFundsFailure,
  firstTimeDeposit,
  attemptRemoveBankAccount,
  withdrawCashAttempt,
  addedBankDetails,
  attemptRegisterAddress,
  cancelWithdrawal,
  buySkrAttempt,
} from "sections/Wallet/walletSlice";

import {
  BET_ADDED_TO_SLIP,
  BET_SUBMITTED,
  MATCH_PAGE_VISITED,
} from "sections/Betting/BettingConfig";
import { ENTRY_SUBMITTED, ENTRY_UPDATED } from "sections/Pickems/PickemsConfig";
import {
  trackLogin,
  trackSignUp,
  trackCompletedAddress,
  trackCompletedDetails,
  trackRequestToken,
  trackResetPassword,
  trackAttemptedVerification,
} from "utilities/Auth/authSlice";
import {
  sharedEntryTracking,
  viewedSharedEntry,
} from "sections/Betting/Betslip/betslipSlice";

const getActionTypeMap = () => ({
  [trackAttemptedVerification.type]: "Attempted Verification",
  [trackLogin.type]: "Login Attempt",
  [trackSignUp.type]: "Completed Profile (Identity)",
  [trackCompletedAddress.type]: "Completed Address",
  [trackCompletedDetails.type]: "Completed Details",
  [trackRequestToken.type]: "Requested Password Reset Code",
  [trackResetPassword.type]: "Attempted Password Reset",
  [AUTH_LOGGED_OUT_SUCCESS]: "Logged Out",
  [UPLOAD_AVATAR_ATTEMPT]: "Updated Avatar",
  [UPDATE_PASSWORD_ATTEMPT]: "Updated Password",
  [SUBMIT_VERIFICATION_ATTEMPT]: "Submitted Verification Form",
  [CHANGE_NOTIFICATION_SETTINGS]: "Updated Notification Preferences",
  [SUCCESS_RESENT]: "Validation Code Resent",
  [BET_SUBMITTED]: "Bet Submitted",
  [BET_ADDED_TO_SLIP]: "Bet Added To Slip",
  [MATCH_PAGE_VISITED]: "Match Page Visited",
  [UNSUBSCRIBE]: "Unsubscribed from Emails",
  [ENTRY_SUBMITTED]: "Entry Submitted",
  [ENTRY_UPDATED]: "Entry Updated",
  [streamHadError.type]: "Stream Error",
  [requestStream.type]: "Stream Requested",
  [duplicateBetAttempted.type]: "Duplicate Bet Attempt",
  [messageTimedOut.type]: "Message Timeout",
  [appCrashed.type]: "App Crash",
  [addCashCardAttempt?.type]: "Added Card (Cash Deposit)",
  [removeCashCardAttempt?.type]: "Removed Card (Cash Deposit)",
  [depositFundsAttempt?.type]: "Deposited Cash",
  [depositFundsFailure?.type]: "Deposited Cash Failure",
  [firstTimeDeposit?.type]: "First Time Deposit (FE)",
  [attemptRemoveBankAccount?.type]: "Removed Bank Account",
  [withdrawCashAttempt?.type]: "Withdrew Cash",
  [addPicklebetCardAttempt?.type]: "Added Card (SKR Buy)",
  [removePicklebetCardAttempt?.type]: "Removed Card (SKR Buy)",
  [buySkrAttempt?.type]: "Purchased SKR",
  [attemptRegisterAddress?.type]: "Registered Wallet",
  [cancelWithdrawal?.type]: "Cancel Withdrawal",
  [addedBankDetails?.type]: "Added Bank Details",
  [sharedEntryTracking?.type]: "Shared Entry",
  [viewedSharedEntry?.type]: "Viewed Shared Entry",
});

export const actionTypeToString = (actionType) =>
  getActionTypeMap()[actionType] || actionType;

import type { BetEntry } from "sections/Entries/types";
import { createContext, useContext } from "react";

type SummaryContextType = {
  entry: BetEntry;
  currency: string;
  oddsFormat: string;
};
export const BetSummaryContext = createContext<SummaryContextType>(undefined);

export const useBetSummaryContext = () => {
  const context = useContext(BetSummaryContext);

  if (!context) {
    throw new Error(
      "useBetSummaryContext must be within BetSummaryContextProvider",
    );
  }

  return context;
};

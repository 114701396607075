import React from "react";
import { useImmerReducer } from "use-immer";
import { loadingReducer } from "hooks/ui/useLoading";
import { enableMapSet } from "immer";

enableMapSet();

import type { LoadingAction } from "hooks/ui/useLoading";

const LoadingContext = React.createContext<
  [boolean, React.Dispatch<LoadingAction>]
>([
  false,
  () => {
    /* do nothing */
  },
]);

const LoadingProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(loadingReducer, new Set());

  // Check if there's at least one key loading
  const isLoading = state.size > 0;

  return (
    <LoadingContext.Provider value={[isLoading, dispatch]}>
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };

import React, { useEffect, useState, type ChangeEvent } from "react";
import { Input } from "components/Input";
import type { InputProps } from "components/Input";
import { Icon } from "library";
import { formatValue, stripNonNumeric } from "./CurrencyInput.utils";

import * as styles from "./CurrencyInput.module.scss";

type CurrencyInputProps = {
  currency: Intl.NumberFormatOptions["currency"];
  value: number;
  onChange: (value: number) => void;
  canClear?: boolean;
  renderClearIcon?: (iconProps: {
    onClick: () => void;
    onMouseDown: (event: React.MouseEvent) => void;
  }) => React.ReactNode;
  locale?: string;
} & Omit<InputProps, "value" | "onChange">;

const CurrencyInput = ({
  value: externalValue,
  currency,
  onChange,
  renderClearIcon,
  canClear = true,
  locale = "en-AU",
  ...props
}: CurrencyInputProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const [value, setValue] = useState(
    typeof externalValue !== "undefined"
      ? formatValue(externalValue.toString(), currency, isDirty, locale)
      : "",
  );

  useEffect(() => {
    // if there is no external value, there is no reason to do anything
    if (typeof externalValue === "undefined") return;

    const internalValueAsNumber = Number(stripNonNumeric(value));

    if (externalValue === internalValueAsNumber) {
      // no need to sync value if it's already there
      return;
    }

    // This makes sure that the value is formatted to 2 decimals when set externally for all numbers but zeroes
    const externalValueAsString =
      externalValue > 0 ? externalValue.toFixed(2) : externalValue.toString();

    // when external value changes we need to keep the internal state in sync
    setValue(formatValue(externalValueAsString, currency, isDirty, locale));
  }, [externalValue]);

  const handleChange = (value: string) => {
    if (!isDirty) {
      // track when we first interacted with this input
      setIsDirty(true);
    }

    const inputValue = stripNonNumeric(value);
    const formattedValue = formatValue(inputValue, currency, isDirty, locale);

    setValue(formattedValue);

    onChange(Number(inputValue));
  };

  const placeHolder = formatValue("0", currency, true, locale);

  const clearProps = {
    onClick: () => handleChange(""),
    onMouseDown: (event) => event.preventDefault(),
  };

  // clear button should not be visible when
  // - value is empty
  // - value is the 0
  // - input is disabled
  const shouldHideClear =
    value.length === 0 || value === placeHolder || props.disabled;

  const icon =
    canClear && !shouldHideClear ? (
      renderClearIcon ? (
        renderClearIcon(clearProps)
      ) : (
        <Icon type="clearStake" svg className={styles.clear} {...clearProps} />
      )
    ) : null;

  return (
    <Input
      placeholder={placeHolder}
      inputMode={`decimal`}
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        handleChange(event.target.value)
      }
      icon={icon}
      {...props}
    />
  );
};

export { CurrencyInput };

import { useSelector } from "hooks";
import { useMemo } from "react";

export const useBrazeContentCards = () => {
  const cards = useSelector((state) => state.brazeContentCards.cards);

  const filteredCards = useMemo(
    () =>
      cards.filter((card) => {
        return (
          !card.isControl && (!card.expiresAt || card.expiresAt > new Date())
        );
      }),
    [cards],
  );

  return filteredCards;
};

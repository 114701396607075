import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { Activity } from "@ivliu/react-offscreen";
import { useIsClient } from "usehooks-ts";
import {
  useBetslip,
  useCashAvailable,
  useDispatch,
  useIsLoggedIn,
} from "hooks";
import { BetType } from "sections/Betting/Betslip/betslipSlice";
import PendingBets from "./PendingBets";
import SettledBets from "./SettledBets";
import { usePendingBetsCount } from "hooks/firestore/betting/useEntries";
import { togglePanel } from "utilities/UI/uiSlice";
import { Tab, TabContent, Tabs, TabsList } from "components/Tabs";
import { ScrollArea } from "components/ScrollArea";
import { Single } from "./components/Single";
import { Multi } from "./components/Multi";
import { BetslipFooter } from "./components/BetslipFooter";
import { BetsFooter } from "./components/BetsFooter";
import { QuickDeposit } from "./components/QuickDeposit";
import { GlobalAlerts } from "./components/GlobalAlerts";
import { Duplicate } from "./components/Duplicate";
import { QuickBalance } from "components/layout/QuickBalance/QuickBalance";
import { Intercepted } from "./components/Intercepted";
import { useBetslipHeight } from "./hooks/useBetslipHeight";
import { useGetActiveFooterType } from "./hooks/useGetActiveFooterType";

import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";

import * as styles from "./Betslip.module.scss";

const Betslip = () => {
  const isClient = useIsClient();
  const dispatch = useDispatch();
  const { props, actions } = useBetslip();
  const pendingBetsCount = usePendingBetsCount("bet");
  const isLoggedIn = useIsLoggedIn();
  const isMulti = props.betType === BetType.Multi;
  const requiredDepositValue =
    (isMulti ? props.multiStake : props.combinedSinglesStakeWithoutPromotion) -
    props.availableBalance;
  const tab = props.betType;
  const { available, promo } = useCashAvailable();
  const formatBadge = (count: number) => (count < 10 ? count.toString() : "9+");
  const { betslipFooter, betsFooter } = useGetActiveFooterType();
  const betslipHeight = useBetslipHeight();

  useEffect(() => {
    if (!isLoggedIn && [BetType.Settled, BetType.Pending].includes(tab)) {
      dispatch(actions.setBetType(BetType.Single));
    }
  }, [isLoggedIn]);

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabs}
        value={tab}
        onValueChange={(newTab) =>
          dispatch(actions.setBetType(newTab as BetType))
        }
      >
        <TabsList
          containerClassName={styles.tabListContainer}
          widget={
            <>
              <div
                onClick={() => dispatch(togglePanel("betslip"))}
                className={styles.close}
              >
                <ChevronDown />
              </div>
              {isLoggedIn ? (
                <QuickBalance
                  className={styles.quickBalance}
                  available={available}
                  promo={promo}
                  showAvatar={false}
                />
              ) : null}
            </>
          }
          className={styles.tabList}
        >
          <Tab
            badge={formatBadge(props.unfilteredSelectionsCount)}
            value={BetType.Single}
          >
            Singles
          </Tab>
          <Tab value={BetType.Multi}>Multi</Tab>
          {isLoggedIn && (
            <>
              <Tab
                value={BetType.Pending}
                badge={formatBadge(pendingBetsCount)}
              >
                Pending
              </Tab>
              <Tab value={BetType.Settled}>Settled</Tab>
            </>
          )}
        </TabsList>
        <ScrollArea
          style={{ height: betslipHeight }}
          hideHorizontalScrollbar
          className={styles.scrollArea}
          viewPortClassName={styles.viewport}
        >
          <div className={styles.content}>
            <GlobalAlerts />
            {isClient ? (
              <>
                <Activity mode={tab === BetType.Single ? "visible" : "hidden"}>
                  <TabContent
                    forceMount
                    className={styles.tab}
                    value={BetType.Single}
                  >
                    <Single />
                  </TabContent>
                </Activity>
                <Activity mode={tab === BetType.Multi ? "visible" : "hidden"}>
                  <TabContent
                    forceMount
                    className={styles.tab}
                    value={BetType.Multi}
                  >
                    <Multi />
                  </TabContent>
                </Activity>
                <Activity mode={tab === BetType.Pending ? "visible" : "hidden"}>
                  <TabContent className={styles.tab} value={BetType.Pending}>
                    <PendingBets />
                  </TabContent>
                </Activity>
                <TabContent className={styles.tab} value={BetType.Settled}>
                  <SettledBets />
                </TabContent>
              </>
            ) : null}
          </div>
        </ScrollArea>
      </Tabs>
      {betslipFooter && <BetslipFooter />}
      {betsFooter && <BetsFooter />}
      {props.betslipIsQuickDepositing && (
        <AnimatePresence>
          <QuickDeposit requiredDepositAmount={requiredDepositValue} />
        </AnimatePresence>
      )}
      {props.betslipIsDuplicate && (
        <AnimatePresence>
          <Duplicate />
        </AnimatePresence>
      )}
      {props.betslipIntercepted && (
        <AnimatePresence>
          <Intercepted />
        </AnimatePresence>
      )}
    </div>
  );
};

Betslip.displayName = "Betslip";

export { Betslip };

// extracted by mini-css-extract-plugin
export var active = "TitleFilter-module--active--f94a3";
export var blue = "#0eb3f2";
export var disabled = "TitleFilter-module--disabled--e0815";
export var emphasis = "TitleFilter-module--emphasis--fae2b";
export var green = "#57d33b";
export var greenLink = "TitleFilter-module--green-link--2e582";
export var grey = "var(--tone-mid)";
export var greyed = "TitleFilter-module--greyed--cf05f";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var title = "TitleFilter-module--title--6d8d9";
export var titleFilter = "TitleFilter-module--titleFilter--90328";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
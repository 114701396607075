import React from "react";
import type { Entry as EntryType } from "sections/Entries/types";
import { BetEntry } from "./components/BetEntry";
import { PickemEntry } from "./components/PickemEntry";

export type EntryProps = {
  entry: EntryType;
  isCompact?: boolean;
  isPreview?: boolean;
  footer?: React.ReactNode;
};

const Entry = ({
  entry,
  isCompact = false,
  isPreview = false,
  footer,
}: EntryProps) => {
  if (entry.entryType === "bet") {
    return (
      <BetEntry
        entry={entry}
        isCompact={isCompact}
        isPreview={isPreview}
        footer={footer}
      />
    );
  }

  return <PickemEntry entry={entry} />;
};

export { Entry };

import React from "react";
import cx from "classnames";
import { Icon, Link, type ITitle } from "library";

import type { IBetRowProps } from "./BetMatch";
import * as styles from "./BetOutright.module.scss";
import { TitleIcon } from "library/components/Icon/TitleIcon";

export interface IBetOutrightProps extends IBetRowProps {
  selectedMarketId?: string;
  title: ITitle;
}

export class BetOutright extends React.Component<IBetOutrightProps> {
  private mainRowRef = React.createRef<HTMLDivElement>();

  render() {
    const { match, title } = this.props;

    return (
      <div
        className={cx({
          [styles.outright]: true,
          [styles.loading]: !match.loaded,
        })}
      >
        <div className={styles.row}>
          <div className={styles.info} ref={this.mainRowRef}>
            <div className={styles.title}>
              <div className={styles.gameIcon}>
                <TitleIcon title={title} />
              </div>
            </div>
            <div className={styles.description}>
              <div className={styles.game}>
                {title?.name} <span className={styles.matchType}>Outright</span>
              </div>
              <div className={styles.season}>
                {[
                  match.event?.attributes?.parentName,
                  match.display?.tournament,
                ]
                  .filter(Boolean)
                  .join(" - ")}
              </div>
            </div>
          </div>
          <div className={styles.countdown}>
            {match.loaded && (
              <Link to={`betting/outright/${match.id}`}>
                <div
                  className={cx({
                    [styles.caret]: true,
                    [styles.standalone]: true,
                  })}
                >
                  {match.display.exoticsCount}
                  <Icon type="chevronRight" />
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

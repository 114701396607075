import React, { type ReactNode } from "react";
import cx from "classnames";
import * as uuid from "uuid";
import {
  mapRaceToEvent,
  sameRaceMultiLabelMap,
} from "utilities/racingUtilities";
import type {
  Competitor,
  Event as EventType,
  EventMarket,
} from "hooks/firestore/betting/useBetting";
import type {
  RaceCompetitorType,
  RaceEventType,
  RaceMarketsType,
} from "sections/Betting/Race/hooks/RacingTypes";
import { useSameEventBuilderSlip } from "./hook/useSameEventBuilderSlip";
import * as styles from "./SameEventMultiBuilder.module.scss";
import { Slip } from "./components/Slip/Slip";
import { Selections } from "sections/Betting/components/SameEventMultiBuilder/components/Selections";
import { ErrorMessage } from "sections/Betting/components/SameEventMultiBuilder/components/ErrorMessage";
import type { ToggleSelectionPayload } from "hooks/firestore/betting/types";
import type { MarketStatus } from "types/BetTypes";

export type SameEventMultiSlipProps = {
  selections: string[];
  handleRemoveSelection: (outcomeId: string) => void;
  handleRemoveAllSelections: () => void;
  errorMessage?: string;
  errorMessageToReplaceFooter?: boolean;
  type: "srm" | "sgm";
  event: EventType | RaceEventType;
  markets?: null | RaceMarketsType[] | EventMarket[];
  competitors?: null | RaceCompetitorType[];
  rollBackSelectionChange?: () => void;
  setErrorMessage?: (message: string) => void;
  dependencies?: any[];
  setLatestValidSelections?: (selections: string[]) => void;
  latestValidSelections?: string[];
  renderSelectionName?: (outcomeId: string) => ReactNode;
  errorMessageShouldAllowClear?: boolean;
};

export const config = {
  srm: {
    eventMapper: mapRaceToEvent,
    labelMapper: sameRaceMultiLabelMap,
    pickMapper: ({
      markets,
      odds,
      event,
      selections,
      competitors,
    }: {
      markets: RaceMarketsType[];
      odds: number;
      event: RaceEventType;
      selections: string[];
      competitors: RaceCompetitorType[];
    }): ToggleSelectionPayload => ({
      type: "race" as any,
      outcomeId: uuid.v4(),
      sourceId: event.id,
      odds,
      eventId: event.id,
      stake: 0,
      subSelections: selections.map((x) => {
        const market = markets.find((market) => market.outcomes[x]);
        const competitor = competitors.find(
          (competitor) => competitor.id === market.outcomes[x].competitorId,
        );

        const startingPosition =
          competitor?.startingPosition && event.sport !== "GREYHOUNDS"
            ? ` (${competitor?.startingPosition})`
            : "";
        return {
          outcomeId: x,
          marketId: market?.id,
          name: `${competitor?.number}. ${competitor?.name} ${startingPosition}`,
          marketName: market?.name,
          marketStatus: market?.status,
        };
      }),
    }),
  },
  sgm: {
    eventMapper: ({ event }: { event: EventType }) => event,
    labelMapper: (label: string) => label,
    pickMapper: ({
      odds,
      event,
      selections,
    }: {
      markets: EventMarket[];
      odds: number;
      event: EventType;
      selections: string[];
      competitors: Competitor[];
    }): ToggleSelectionPayload => ({
      type: "match",
      outcomeId: uuid.v4(),
      sourceId: event.eventId,
      odds,
      eventId: event.eventId,
      stake: 0,
      subSelections: selections.map((x) => {
        const market = event.markets.find((market) => market.outcomes[x]);
        return {
          outcomeId: x,
          marketId: market?.id,
          name: market?.outcomes[x]?.name,
          marketName: market?.name,
          marketStatus: market?.status as MarketStatus,
        };
      }),
    }),
  },
};

export type sameEventMultiType = "srm" | "sgm";

export const getFullNameFromOutcomeId = (outcomeId: string, markets: any[]) => {
  const market = markets.find((market) => market.outcomes[outcomeId]);
  return market?.outcomes[outcomeId]?.name;
};

export const SameEventMultiBuilder = (props: SameEventMultiSlipProps) => {
  const {
    isOpen,
    setIsOpen,
    isScrollMode,
    srmElement,
    slipStyles,
    isAtMostTablet,
    isLoggedIn,
    odds,
    loading,
    handleRemoveSelection,
    handleRemoveAllSelections,
    areAnySelectionsNotActive,
    addToBetSlip,
    selections,
    slipHeight,
  } = useSameEventBuilderSlip(props);

  const containerStyles = {
    height: `${slipHeight}px`,
  };

  const shouldShowErrorMessage =
    (isScrollMode && !isAtMostTablet) || isAtMostTablet;

  return (
    <div ref={srmElement} style={containerStyles} className={styles.refElement}>
      <div
        className={cx(styles.container, {
          [styles.isScrollMode]: isScrollMode || isAtMostTablet,
        })}
        style={slipStyles}
      >
        {!props.errorMessageToReplaceFooter ? (
          <>
            {props.errorMessage && shouldShowErrorMessage && (
              <ErrorMessage
                errorMessage={props.errorMessage}
                type={props.type}
                variant="error"
              />
            )}
            <Selections
              selections={selections}
              markets={props.markets}
              handleRemoveSelection={handleRemoveSelection}
              renderSelectionName={props.renderSelectionName}
              isOpen={isOpen}
            />

            <Slip
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isLoggedIn={isLoggedIn}
              odds={odds}
              loading={loading}
              handleRemoveAllSelections={handleRemoveAllSelections}
              addToBetSlip={addToBetSlip}
              areAnySelectionsNotActive={areAnySelectionsNotActive}
              selectionsLength={selections.length}
            />
          </>
        ) : (
          <ErrorMessage
            errorMessage={props.errorMessage}
            type={props.type}
            handleRemoveAllSelections={
              props.errorMessageShouldAllowClear
                ? handleRemoveAllSelections
                : null
            }
          />
        )}
      </div>
    </div>
  );
};

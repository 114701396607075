import type { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useIsSGMReady = (availableFrom: Timestamp) => {
  const [isSGMReady, setIsSGMReady] = useState(false);

  useEffect(() => {
    if (!availableFrom || typeof availableFrom.toDate !== "function") {
      return;
    }

    const now = new Date();
    const availableFromDate = new Date(availableFrom.toDate());
    if (now.getTime() > availableFromDate.getTime()) {
      setIsSGMReady(true);
    }
  }, [availableFrom]);

  return isSGMReady;
};

import React, { createContext, useContext } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ButtonGroup } from "../ButtonGroup";
import { Button, type buttonVariants } from "../Button";
import type { VariantProps } from "class-variance-authority";

import * as styles from "./CurrencyInput.module.scss";

type QuickInputRootProps = {
  className?: string;
  isVisible?: boolean;
  children?: React.ReactNode;
  currencySymbol: string;
} & Pick<VariantProps<typeof buttonVariants>, "variant" | "size">;

type QuickInputOptionProps = {
  onChange: (value: number) => void;
  value: number;
  label?: string;
};

type QuickInputContext = {
  currencySymbol: string;
} & Pick<VariantProps<typeof buttonVariants>, "variant" | "size">;

const QuickInputContext = createContext<QuickInputContext>({
  variant: `primary`,
  currencySymbol: `$`,
  size: `default`,
});

const QuickInputRoot = ({
  className,
  isVisible = true,
  variant,
  children,
  currencySymbol,
  size,
}: QuickInputRootProps) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          className={cx(styles.quickInput, {
            [className]: !!className,
            [styles.green]: variant === `primary`,
            [styles.blurred]: variant === `blurred`,
          })}
        >
          <QuickInputContext.Provider
            value={{
              currencySymbol,
              variant,
              size,
            }}
          >
            <ButtonGroup
              className={cx(styles.options, {
                [styles[variant]]: !!variant,
              })}
            >
              {children}
            </ButtonGroup>
          </QuickInputContext.Provider>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const QuickInputOption = ({
  onChange,
  value,
  label,
}: QuickInputOptionProps) => {
  const { currencySymbol, variant, size } = useContext(QuickInputContext);
  return (
    <Button variant={variant} onClick={() => onChange(value)} size={size}>
      {label ? label : `+${currencySymbol}${value}`}
    </Button>
  );
};
const QuickInput = {
  Root: QuickInputRoot,
  Option: QuickInputOption,
};

export { QuickInput };

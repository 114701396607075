import React from "react";
import cx from "classnames";
import { type VariantProps, cva } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";

import * as styles from "./Clamp.module.scss";

type ClampProps = {
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  asChild?: boolean;
  icon?: React.ReactNode;
} & VariantProps<typeof clampVariants>;

const clampVariants = cva(styles.clamp, {
  variants: {
    variant: {
      success: styles.success,
      danger: styles.danger,
      dangerSoft: styles.dangerSoft,
      warning: styles.warning,
      neutral: styles.neutral,
      grey: styles.grey,
      light: styles.light,
      disabled: styles.disabled,
      info: styles.info,
    },
    shape: {
      default: styles.shapeDefault,
      rounded: styles.shapeRound,
    },
  },
  defaultVariants: {
    variant: "neutral",
    shape: "default",
  },
});

const Clamp = ({
  children,
  variant,
  shape,
  onClick,
  className,
  asChild = false,
}: ClampProps) => {
  const Component = asChild ? Slot : "div";

  return (
    <Component
      onClick={onClick}
      className={cx(clampVariants({ variant, shape }), className)}
    >
      {children}
    </Component>
  );
};

export { Clamp, clampVariants };

import { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTitles } from "./useTitles";

const DEFAULT_HUB = "esports";

export type Hub = Queries.ContentfulHub & {
  pathPrefix: string;
  titles: Queries.ContentfulTitle[];
};

const useHubs = (): Hub[] => {
  const titles = useTitles();

  const { allContentfulHub } = useStaticQuery(graphql`
    {
      allContentfulHub(
        filter: { slug: { in: ["sports", "esports", "racing"] } }
      ) {
        nodes {
          name
          slug
          titles {
            slug
          }
          banners {
            ...Banner
          }
          bettingHeaderFaq {
            ...Faq
          }
        }
      }
    }
  `);

  return useMemo(() => {
    return allContentfulHub.nodes.map((hub) => {
      const mappedTitles = hub.titles.map(({ slug }) => titles[slug]);

      return {
        ...hub,
        pathPrefix: hub.slug === DEFAULT_HUB ? "" : hub.slug,
        titles: mappedTitles,
      };
    });
  }, [allContentfulHub, titles]);
};

export const useHub = (hub: string): Hub => {
  const hubs = useHubs();

  return hubs.find(({ slug }) => slug === hub);
};

export default useHubs;

import { useSelector } from "hooks";
import type { PermissionReason } from "utilities/Auth/authSlice";

/**
 *
 * @param name the name(s) of the permission
 * @param defaultValue is the permission granted if the user doesn't have that permission at all.
 * Useful if we are creating a new permission code to define the behaviour when a user with an existing session attempts the action before reauth
 * @return is the permission granted to the current user
 */
export const useHasPermission = (
  name: string | string[],
  defaultValue = false,
) => {
  const permissions = useSelector((state) => state.auth?.permissions || {});

  if (typeof name === "string") {
    const permissionReason = permissions[name];
    return permissionReason ? permissionReason === "GRANTED" : defaultValue;
  } else {
    return name.every((permission) => {
      const permissionReason = permissions[permission];
      return permissionReason ? permissionReason === "GRANTED" : defaultValue;
    });
  }
};

export const usePermissions = () => {
  const permissions = useSelector((state) => state.auth?.permissions || {});

  return permissions;
};

export const usePermission = (name: string): PermissionReason => {
  const permissions = usePermissions();

  return permissions[name];
};

export const useCanReopenAccount = () => {
  const { reopenAccount } = usePermissions();

  return reopenAccount === "GRANTED";
};

export default useHasPermission;

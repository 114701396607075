// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var brand = "Footer-module--brand--aca9a";
export var button = "Footer-module--button--40348";
export var buttonTextAndLabel = "Footer-module--buttonTextAndLabel--643b7";
export var buttons = "Footer-module--buttons--de4a8";
export var center = "Footer-module--center--bb387";
export var compact = "Footer-module--compact--a0c9b";
export var container = "Footer-module--container--fb1fb";
export var download = "Footer-module--download--1373c";
export var emphasis = "Footer-module--emphasis--26cc6";
export var expanded = "Footer-module--expanded--d9e4e";
export var green = "#57d33b";
export var greenLink = "Footer-module--green-link--b7e31";
export var grey = "var(--tone-mid)";
export var label = "Footer-module--label--04559";
export var link = "Footer-module--link--fe13c";
export var links = "Footer-module--links--41a29";
export var logo = "Footer-module--logo--808bc";
export var notchGap = "32px";
export var orange = "#f8662b";
export var qr = "Footer-module--qr--fefe5";
export var red = "#ff2741";
export var section = "Footer-module--section--9af79";
export var social = "Footer-module--social--b288a";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import React, { type ReactNode } from "react";
import type { EventMarket } from "hooks/firestore/betting/useBetting";
import type { RaceMarketsType } from "sections/Betting/Race/hooks/RacingTypes";
import { Selection } from "../Selection";
import cx from "classnames";
import * as styles from "./Selections.module.scss";

export default ({
  selections,
  markets,
  handleRemoveSelection,
  renderSelectionName,
  isOpen,
}: {
  selections: string[];
  markets: EventMarket[] | RaceMarketsType[];
  handleRemoveSelection: (outcomeId: string) => void;
  renderSelectionName?: (outcomeId: string) => ReactNode;
  isOpen?: boolean;
}) => (
  <>
    {isOpen && (
      <div
        className={cx(styles.selections, {
          [styles.isOpen]: isOpen,
        })}
      >
        {selections.map((outcomeId) => {
          const market = markets.find((market) => market.outcomes[outcomeId]);
          const outcome = market?.outcomes[outcomeId];
          return (
            <Selection
              key={outcomeId}
              outcomeId={outcomeId}
              market={market}
              outcome={outcome}
              handleRemoveSelection={handleRemoveSelection}
              renderSelectionName={renderSelectionName}
            />
          );
        })}
      </div>
    )}
  </>
);

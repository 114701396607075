import { useStaticQuery, graphql } from "gatsby";

export type Title = {
  slug: string;
  name: string;
  icon: {
    file: {
      contentType: string;
      url: string;
    };
  };
  bettingMetaTitle?: string;
  bettingMetaDescription?: string;
  pickemsMetaTitle?: string;
  pickemsMetaDescription?: string;
  logo?: any;
  hero?: any;
};

export type Titles = {
  [slug: string]: Title;
};

export const useTitles = (): Titles => {
  const { allContentfulTitle } = useStaticQuery(graphql`
    {
      allContentfulTitle {
        nodes {
          name
          slug
          icon {
            ...IconImage
          }
          bettingMetaTitle
          bettingMetaDescription
          pickemsMetaTitle
          pickemsMetaDescription
          hero {
            ...HeroImage
          }
          logo {
            ...IconImage
          }
        }
      }
    }
  `);

  return allContentfulTitle.nodes
    .filter(({ slug }) => slug && slug !== "empty")
    .reduce((allTitles, title) => {
      allTitles[title.slug] = {
        slug: title.slug,
        name: title.name,
        icon: title.icon,
        bettingMetaTitle: title.bettingMetaTitle,
        bettingMetaDescription: title.bettingMetaDescription,
        pickemsMetaTitle: title.pickemsMetaTitle,
        pickemsMetaDescription: title.pickemsMetaDescription,
        hero: title.hero,
        logo: title.logo,
      };
      return allTitles;
    }, {});
};

export const useTitle = (title: string): Title | undefined => {
  const titles = useTitles();

  const titleSlug = Object.keys(titles).find((slug) => slug === title);

  return titles[titleSlug];
};

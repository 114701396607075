import { PageMessage } from "components/PageMessage";
import React from "react";
import { type PropsWithChildren, useContext } from "react";
import { RaceContext } from "sections/Betting/Race/Race";
import { getIsSRMAvailable } from "utilities/racingUtilities";
import { ReactComponent as OddsUnavailable } from "components/assets/icon-empty-odds.svg";
import { ButtonGroup } from "components/ButtonGroup";
import { useIsMobile } from "hooks";
import { Button } from "components/Button";

import * as styles from "./SRMUnavailable.module.scss";
const getMessage = ({
  validCompetitorCount,
  isSRMAvailable,
  raceMeeting,
  isSP,
}: {
  validCompetitorCount: number;
  isSRMAvailable: boolean;
  raceMeeting: { sport: string; country: string };
  isSP: boolean;
}): string => {
  if (validCompetitorCount < 4) {
    return "Unavailable for this event, as there are less than 4 runners";
  }

  if (!isSRMAvailable) {
    if (raceMeeting?.sport === "HORSE_RACING") {
      return raceMeeting?.country === "AU"
        ? "Available from 9am (local time) on the day of this event"
        : "Available one hour prior to the scheduled start time for this event";
    }
    return "Available 20 minutes before the scheduled start time for this event";
  }

  if (isSP) {
    return "Available as soon as fixed prices are available for this event";
  }

  return "";
};

export const SRMUnavailable = ({
  children,
  isSP,
  validCompetitorCount,
}: PropsWithChildren<{ isSP: boolean; validCompetitorCount: number }>) => {
  const isMobile = useIsMobile();
  const { raceMeeting, raceTimezone, setRouteValue, races, route } =
    useContext(RaceContext);

  const race = races?.find((r) => r.id === route.raceId);

  const isSRMAvailable = getIsSRMAvailable({
    scheduledStartTime:
      race?.sport === "HORSE_RACING"
        ? raceMeeting?.scheduledStartTime
        : race?.scheduledStartTime,
    raceTimezone,
    sport: race?.sport,
    semAvailableAtTs: race?.semAvailableAtTs,
  });

  const message = getMessage({
    validCompetitorCount,
    isSRMAvailable,
    raceMeeting,
    isSP,
  });

  if (message) {
    return (
      <div className={styles.unavailable}>
        <PageMessage
          title="SRM Unavailable"
          image={<OddsUnavailable />}
          subTitle={getMessage({
            validCompetitorCount,
            isSRMAvailable,
            raceMeeting,
            isSP,
          })}
        >
          <ButtonGroup>
            <Button
              variant={`primary`}
              size={isMobile ? `md` : `default`}
              onClick={() => setRouteValue({ tab: "top4" })}
            >
              Go to Top 4
            </Button>
          </ButtonGroup>
        </PageMessage>
      </div>
    );
  }

  return children;
};

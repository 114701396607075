import React from "react";
import cx from "classnames";
import { Icon } from "library";
import * as styles from "./Pill.module.scss";

type PillProps = {
  iconType?: string;
  icon?: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
};

export const Pill = ({
  iconType,
  icon,
  selected = false,
  onClick,
  className,
  children,
}: PillProps) => {
  return (
    <button
      className={cx(
        styles.pill,
        "pill",
        {
          [styles.selected]: selected,
        },
        className,
      )}
      onClick={onClick}
    >
      {iconType && <Icon type={iconType} svg />}
      {icon}
      {children ? (
        <span className={cx(styles.text, children ? "" : styles.empty)}>
          {children || "_"}
        </span>
      ) : null}
    </button>
  );
};

export default Pill;

import React from "react";
import * as DialogPrimitives from "@radix-ui/react-dialog";
import { ReactComponent as CloseIcon } from "components/assets/close.svg";
import { Typography } from "components/Typography";
import cx from "classnames";

import * as styles from "./Dialog.module.scss";

export type DialogProps = {
  icon?: React.ReactNode;
  title?: string;
  description?: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  children?: React.ReactNode;
  className?: string;
};

const Dialog = ({
  icon,
  title,
  description,
  onClose,
  children,
  isOpen,
  className,
}: DialogProps) => {
  const hasIcon = !!icon;

  return (
    <DialogPrimitives.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <DialogPrimitives.Portal>
        <DialogPrimitives.Overlay className={styles.overlay}>
          <DialogPrimitives.Content className={cx(styles.content, className)}>
            <div>
              <div className={styles.titleAndIcon}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <DialogPrimitives.Title className={styles.title}>
                  {title}
                </DialogPrimitives.Title>
              </div>

              <DialogPrimitives.Description asChild>
                <Typography
                  className={cx(styles.description, {
                    [styles.hasIcon]: hasIcon,
                  })}
                  variant={`body`}
                >
                  {description}
                </Typography>
              </DialogPrimitives.Description>
            </div>

            {children}

            <DialogPrimitives.Close asChild>
              <button className={styles.closeButton} aria-label="Close">
                <CloseIcon />
              </button>
            </DialogPrimitives.Close>
          </DialogPrimitives.Content>
        </DialogPrimitives.Overlay>
      </DialogPrimitives.Portal>
    </DialogPrimitives.Root>
  );
};

export { Dialog };

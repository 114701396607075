import { useState, useEffect, useMemo, useContext } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData } from "hooks";
import { parseISO } from "date-fns";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
  type DocumentData,
  type QueryConstraint,
  type Timestamp,
} from "firebase/firestore";
import useHasPermission, { usePermissions } from "hooks/useHasPermission";
import {
  type AllowedSelectionTypesEnum,
  type CampaignsType,
  type PromotionsAvailableType,
  useEvent,
} from "../useEvent";
import type { OutcomeType } from "types/BetTypes";
import { useLoading } from "hooks/ui/useLoading";
import { defaultPageSize } from "utilities/display";
import { FirebaseContext } from "context/Firebase";
import { processPromotionAvailability } from "utilities/sharedBettingUtilities";
import { useUserEventFilters } from "hooks/firestore/useUserAttributes";

export type TournamentType = {
  id: string;
  category: string;
  iconUri?: string;
  name: string;
  sport: string;
  tab: "both" | "outrights" | "next";
  priority?: any;
};

export type Attributes = {
  parentName?: string;
  tournamentName?: string;
  seasonName?: string;
  roundName?: string;
  matchMode?: string;
  categoryName?: string;
  streamExpected?: boolean;
  sgmAvailableAtTs?: Timestamp;
} & Record<string, string>;

export type Competitor = {
  abbreviation?: string;
  name: string;
  type: "PLAYER" | "FRANCHISE" | "RACER";
  iconUri?: string;
  nationality?: string;
  number?: number;
  silksUrl?: string;
  id?: string;
};

export type Outcome = {
  active: boolean;
  competitorId: string;
  name: string;
  odds: number;
  openingOdds?: number;
  closingOdds?: number;
  result: string;
  type?: OutcomeType;
  abbreviation?: string;
  punted?: boolean;
  selectedAgainst?: boolean;
  silksUrl?: string;
  number?: number;
};

export type Outcomes = {
  [outcomeId: string]: Outcome;
};

export type Competitors = {
  [competitorId: string]: Competitor;
};

export type EventMarket = {
  id: string;
  bettingType: "Prematch" | "Live";
  marketType: string;
  name: string;
  promotional: boolean;
  status: string;
  outcomes: Outcomes;
  attributes?: {
    custom?: boolean;
    multiable?: boolean;
    promotionCashDisabled?: boolean;
    goal?: number;
    points?: number;
    period?: number;
    handicap?: number;
    threshold?: number;
  };
  /**
   * Attributes of a markets that make it unique when combined with the event id and market type
   * @deprecated To replaced with a set of attributes with consistent keys regardless of the source of the market.
   */
  specifiers: Record<string, string>;
  category?: string;
  campaignOnly?: boolean;
  campaignIds?: string[];
  nextBetStop?: Date;
  sourceType: string;
  allowedSelectionTypes?: AllowedSelectionTypesEnum[];
  //Determines the order in which markets are displayed.
  priority?: number;
  promotionIsAvailable?: boolean;
  independentPerEvent?: boolean;
};

export type Event = {
  activeMarketCount: number;
  activeMarketCounts?: {
    SameEventMulti: number;
    SingleOutcome: number;
  };
  attributes: Attributes | any;
  competitors?: Competitors;
  eventId: string;
  eventName: string;
  eventType:
    | "MATCH"
    | "OUTRIGHT"
    | "RACE"
    | "TOURNAMENT"
    | "RACE_MEETING"
    | "SEASON"
    | "RACER";
  mainMarket?: EventMarket;
  markets?: EventMarket[];
  scheduledStartTime: Date;
  sport: string;
  status: string;
  streamUri?: string;
  parentEventName: string;
  hub?: "esports" | "sports" | "racing";
  relatedEventIds?: Record<string, string>;
  nextBetStop?: Date | null;
  promotionVisibility?: PromotionsAvailableType;
  campaigns?: CampaignsType[];
  promotionIsAvailable?: boolean;
  semAvailableAtTs?: Timestamp;
  filters?: string[];
};

const getMarketStatus = (status: string, bettingType: string): string => {
  if (status === "ACTIVE" && bettingType === "Live") {
    return "LIVE";
  }

  if (["ACTIVE", "SETTLED", "CANCELLED"].includes(status)) {
    return status;
  }

  return "SUSPENDED";
};

export const createEventsConverter = (
  userCampaigns: string[],
  permissions: Record<string, string>,
): FirestoreDataConverter<Event> => {
  return {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): Event => {
      const data = snapshot.data(options);

      const promotionIsAvailable = processPromotionAvailability(
        data?.promotionVisibility,
        data?.campaignIds,
        userCampaigns,
        permissions,
      );

      const bettingType =
        data.eventType === "MATCH" ? data.mainMarket?.bettingType : "prematch";

      return {
        ...data,
        mainMarket: {
          ...data.mainMarket,
          bettingType,
          nextBetStop:
            typeof data?.mainMarket?.nextBetStop === "string"
              ? parseISO(data.mainMarket.nextBetStop)
              : typeof data?.mainMarket?.nextBetStop?.toDate === "function"
                ? data?.mainMarket?.nextBetStop?.toDate()
                : null,
        },
        scheduledStartTime: parseISO(data.scheduledStartTime),
        nextBetStop:
          typeof data.nextBetStop === "string"
            ? parseISO(data.nextBetStop)
            : typeof data.nextBetStop?.toDate === "function"
              ? data?.nextBetStop?.toDate()
              : null,
        status:
          data.eventType === "MATCH"
            ? getMarketStatus(
                data?.mainMarket?.status,
                data?.mainMarket?.bettingType,
              )
            : "ACTIVE",
        promotionIsAvailable,
      } as Event;
    },
  };
};

export const createMarketsConverter = (
  userCampaigns: string[],
  permissions: Record<string, string>,
): FirestoreDataConverter<EventMarket> => {
  return {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): EventMarket => {
      const data = snapshot.data(options);

      const promotionIsAvailable = processPromotionAvailability(
        data?.campaignIds?.length > 0 && data?.campaignOnly
          ? "campaignIds"
          : data?.campaignIds?.length > 0 || data?.promotional
            ? "openToAllEligibleUsers"
            : null,
        data?.campaignIds,
        userCampaigns,
        permissions,
      );

      return {
        ...data,
        promotionIsAvailable,
        attributes: {
          ...data.attributes,
          // casting string booleans into real booleans
          multiable: data?.attributes?.multiable !== "false",
          promotionCashDisabled:
            data?.attributes?.promotionCashDisabled === "true",
          custom: data?.attributes?.custom !== "false",
        },
        nextBetStop:
          typeof data.nextBetStop === "string"
            ? parseISO(data.nextBetStop)
            : typeof data.nextBetStop?.toDate === "function"
              ? data.nextBetStop.toDate()
              : null,
      } as EventMarket;
    },
  };
};

export const campaignConverter: FirestoreDataConverter<CampaignsType> = {
  // we are not saving in firestore no need to transform
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): CampaignsType => {
    const data = snapshot.data(options);
    const id = snapshot.id;
    return {
      id,
      ...data,
    } as CampaignsType;
  },
};

export const competitorsConverter: FirestoreDataConverter<Competitor> = {
  // we are not saving in firestore no need to transform
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Competitor => {
    const data = snapshot.data(options);
    const id = snapshot.id;

    return {
      id,
      ...data,
    } as Competitor;
  },
};

const tournamentFiltersConverter: FirestoreDataConverter<TournamentType> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): TournamentType => {
    const data = snapshot.data(options);
    const id = snapshot.id;

    return {
      id,
      ...data,
    } as TournamentType;
  },
};

const getBettingQuery = (
  page: number,
  titles: any[],
  tournaments: string[],
  tab: string,
  hub: string,
  canViewLiveBets: boolean,
  srm = false,
  shouldLimit = true,
  eventFilters: string[],
): QueryConstraint[] => {
  const queries = [
    where("tab", "==", tab === "next" ? "next" : "outrights"),
    where("hub", "==", hub === "Esports" ? "esports" : "sports"),
    where("visible", "==", true),
  ] as QueryConstraint[];

  if (srm) {
    queries.push(where("attributes.sgm", "==", true));
  }

  if (tab === "outrights") {
    queries.push(
      where("activeMarketCount", ">", 0),
      orderBy("activeMarketCount", "asc"),
    );
  }

  if (tab === "next") {
    queries.push(orderBy("nextBetStop", "asc"));
  } else {
    queries.push(orderBy("scheduledStartTime", "asc"));
  }

  if (shouldLimit) {
    queries.push(limit(page * defaultPageSize()));
  }

  if (!canViewLiveBets && tab === "next") {
    // only include prematch if we can't livebet
    queries.push(where("mainMarket.bettingType", "==", "Prematch"));
  }

  if (eventFilters.length) {
    queries.push(where("filters", "array-contains-any", eventFilters));
  }

  if (tournaments?.length) {
    queries.push(where("tournamentId", "in", tournaments));
  } else if (titles?.length) {
    queries.push(
      where(
        "sport",
        "in",
        titles.map((title) => title.toUpperCase()),
      ),
    );
  }

  return queries;
};

export const useBetting = (
  page,
  titles,
  tournaments,
  tab,
  hub,
  srm = false,
): [Event[], boolean, any] => {
  const [startLoading, endLoading] = useLoading("bettingList");
  const { campaigns: userCampaigns } = useContext(FirebaseContext);
  const permissions = usePermissions();
  const eventFilters = useUserEventFilters();

  const eventsConverter = useMemo(
    () => createEventsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const ref = collection(getFirestore(), "bettingEvents").withConverter(
    eventsConverter,
  );

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const canViewLiveBets = useHasPermission("viewLiveMarkets");
  const queries = useMemo(() => {
    return getBettingQuery(
      page,
      titles,
      tournaments,
      tab,
      hub,
      canViewLiveBets,
      srm,
      true,
      eventFilters,
    );
  }, [page, titles, tournaments, tab, hub, canViewLiveBets, srm, eventFilters]);

  const [rawEvents, rawLoading, error] = useCollectionData(
    queries.length ? query(ref, ...queries) : ref,
    ref.path,
  );

  const filteredEvents = useMemo(() => {
    return (
      rawEvents?.filter((event) => {
        // filter out markets we haven't ever received odds for
        return !(
          event.hub !== "racing" &&
          Object.values(event.mainMarket?.outcomes ?? {}).some(
            (outcome: Outcome) =>
              outcome.odds === 0 && outcome.openingOdds === 0,
          )
        );
      }) ?? []
    );
  }, [rawEvents]);

  useEffect(() => {
    if (!filteredEvents || rawLoading) {
      setLoading(true);
      startLoading();
      return;
    }

    setRows(filteredEvents);
    setLoading(false);
    endLoading();
  }, [filteredEvents, rawLoading]);

  if (error) {
    console.error(error);
  }

  return [rows, loading, error];
};

export const useBettingByTournament = (id: string): [Event[], boolean, any] => {
  const [startLoading, endLoading] = useLoading("bettingList");
  const { campaigns: userCampaigns } = useContext(FirebaseContext);
  const permissions = usePermissions();

  const eventsConverter = useMemo(
    () => createEventsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const ref = collection(getFirestore(), "bettingEvents").withConverter(
    eventsConverter,
  );

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const queries = [
    where("tournamentId", "==", id),
    where("visible", "==", true),
  ];

  const [rawEvents, rawLoading, error] = useCollectionData(
    queries.length ? query(ref, ...queries) : ref,
    ref.path,
  );

  useEffect(() => {
    if (!rawEvents || rawLoading) {
      setLoading(true);
      startLoading();
      return;
    }

    setRows(rawEvents);
    setLoading(false);
    endLoading();
  }, [rawEvents, rawLoading]);

  if (error) {
    console.error(error);
  }

  return [rows, loading, error];
};

export const useTournaments = (
  sports?: string[],
  tab?: "next" | "outrights",
): [TournamentType[], boolean] => {
  const eventFilters = useUserEventFilters();
  const [tournaments, setTournaments] = useState([]);

  const ref =
    sports?.length > 0
      ? collection(getFirestore(), "tournaments").withConverter(
          tournamentFiltersConverter,
        )
      : null;

  const tournamentQueries = useMemo(() => {
    const queries = [];
    if (sports?.length > 0) {
      queries.push(where("sport", "in", sports));
    }

    if (tab === "next") {
      queries.push(where("next", "==", true));
    }

    if (tab === "outrights") {
      queries.push(where("outrights", "==", true));
    }

    if (eventFilters.length) {
      queries.push(where("filters", "array-contains-any", eventFilters));
    }

    queries.push(orderBy("priority", "asc"));

    return queries;
  }, [sports, tab]);

  const [rawTournaments, loading] = useCollectionData(
    ref ? query(ref, ...tournamentQueries) : null,
    ref ? ref.path : "",
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!rawTournaments) {
      setTournaments([]);
      return;
    }

    setTournaments(rawTournaments);
  }, [loading, rawTournaments]);

  return [tournaments, loading];
};

export const useEventProperty = (
  eventId: string,
  property:
    | "streamUri"
    | "tab"
    | "visible"
    | "sport"
    | "scheduledStartTime"
    | "parentEventName",
): [any, boolean] => {
  const [event, loading] = useEvent(eventId);
  const [prop, setProp] = useState(undefined);

  useEffect(() => {
    if (loading || !event) {
      return;
    }

    if (event[property] === prop) {
      return;
    }

    setProp(event[property]);
  }, [loading, event]);

  return [prop, loading];
};

import React from "react";
import cx from "classnames";
import { Icon, BetOdds, StatusPill } from "library";
import type { IBetPickDetails, OddsFormat } from "types/BetTypes";

import * as styles from "./BetPick.module.scss";

import type { SourceType } from "hooks/firestore/betting/types";
import OutcomeIcon from "sections/Betting/Event/OutcomeIcon";

type BetPickProps = {
  selection: IBetPickDetails;
  active?: boolean;
  oddsFormat: OddsFormat;
  doRemoveSelection: (x: string) => void;
  isConfirming?: boolean;
  oddsIncreased?: boolean;
  oddsDecreased?: boolean;
  canClose?: boolean;
  isSRM?: boolean;
  sourceType?: SourceType;
};

export const BetPick = (props: BetPickProps) => {
  const {
    selection,
    active,
    oddsFormat,
    doRemoveSelection,
    isConfirming,
    oddsIncreased,
    oddsDecreased,
    canClose,
    isSRM,
    sourceType,
  } = props;

  const {
    outcomeId,
    eventName,
    selectionName,
    oddsChanged,
    freshOdds,
    lockedOdds,
    recentOdds,
    marketName,
    outcome,
    statusMessage,
  } = selection;

  const isCustom = outcome?.type && outcome?.type === "CUSTOM";

  return (
    <div
      className={cx({
        [styles.pick]: true,
        [styles.inactive]: !active,
      })}
    >
      {canClose && typeof doRemoveSelection === "function" && (
        <div
          className={styles.close}
          onClick={() => doRemoveSelection(outcomeId)}
        >
          <Icon type="close" />
        </div>
      )}
      <div className={styles.definition}>
        <div className={styles.match}>
          <div className={styles.matchIcon}>
            {isCustom ? (
              <Icon type="ribbon" className={styles.pickIcon} />
            ) : isSRM ? (
              sourceType === "match" ? (
                <Icon type="sgmIcon" className={styles.pickIcon} />
              ) : (
                <Icon type="srmIcon" className={styles.pickIcon} />
              )
            ) : (
              <OutcomeIcon
                outcome={outcome as any}
                competitor={outcome?.competitor as any}
              />
            )}
          </div>
          <div className={styles.description}>
            <div className={styles.matchInfo} title={eventName}>
              {eventName}
            </div>
            <div className={styles.pick}>{selectionName}</div>
            <div className={styles.odds}>
              {selection.isSP ? (
                <span>{marketName}</span>
              ) : (
                <BetOdds
                  prefix={
                    isSRM
                      ? "@"
                      : freshOdds || lockedOdds
                        ? `${marketName || ""} @`
                        : ""
                  }
                  base={oddsChanged && !isConfirming ? freshOdds : lockedOdds}
                  format={oddsFormat}
                  settlementOdds={outcome?.settlementOdds}
                />
              )}
              {oddsChanged || recentOdds || oddsIncreased || oddsDecreased ? (
                <Icon
                  type="arrow-down"
                  className={cx({
                    [styles.arrow]: true,
                    [styles.up]:
                      (oddsChanged && freshOdds > lockedOdds) ||
                      (recentOdds && recentOdds < lockedOdds) ||
                      oddsIncreased,
                    [styles.down]:
                      (oddsChanged && freshOdds < lockedOdds) ||
                      (recentOdds && recentOdds > lockedOdds) ||
                      oddsDecreased,
                  })}
                />
              ) : null}
              {statusMessage && statusMessage.text === "LIVE" ? (
                <StatusPill
                  color={statusMessage.color as any}
                  className={styles.starts}
                >
                  {statusMessage.text}
                </StatusPill>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetPick;

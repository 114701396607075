import * as responsiveness from "library/styles/mixins/sizes.module.scss";
import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "./AppWebMessagingBridge";

const USER_AGENT =
  typeof navigator !== "undefined" && navigator.userAgent
    ? navigator.userAgent.toLowerCase()
    : "";

const PLATFORM = (typeof navigator !== "undefined" && navigator.platform) || "";

// determines whether to use clientWidth or innerWidth for viewport calculation
// this is to match CSS media query width reporting
const CALCULATE_DISPLAY_WIDTH_BY_CLIENT =
  USER_AGENT &&
  // safari needs to account for scroll bar width
  USER_AGENT.indexOf("safari/") >= 0 &&
  USER_AGENT.indexOf("chrome/") < 0;

/// ordered from smallest to largest
export const DISPLAY_MODES = [
  "mobile",
  "tablet",
  "desktop",
  "desktopXl",
  "expanded",
] as const;
export type DisplayMode = (typeof DISPLAY_MODES)[number];

const DISPLAY_MODE_INDICES: Partial<Record<DisplayMode, number>> = {};
DISPLAY_MODES.forEach((x, i) => (DISPLAY_MODE_INDICES[x] = i));

function getDefaultMode(): DisplayMode {
  return "desktop";
}

export function getDisplayWidth() {
  if (typeof window !== "undefined") {
    if (
      CALCULATE_DISPLAY_WIDTH_BY_CLIENT &&
      window?.document?.body?.clientWidth
    ) {
      return window.document.body.clientWidth;
    }

    return window.innerWidth;
  }
  return getThreshhold(getDefaultMode());
}

export const isTouchEvent = (e: React.TouchEvent) => {
  return (
    e.type == "touchstart" ||
    e.type == "touchmove" ||
    e.type == "touchend" ||
    e.type == "touchcancel"
  );
};

export const isTouchDevice = () => {
  if (typeof window !== "undefined") {
    return "ontouchstart" in window.document.documentElement;
  }
};

export function getDisplayInfo() {
  return {
    width: getDisplayWidth(),
    height: typeof window !== "undefined" ? window.innerHeight : 1000,
    mode: getDisplayMode(),
  };
}

export const isAndroid = () => {
  if (typeof window !== "undefined") {
    return USER_AGENT.includes("android") && USER_AGENT.includes("chrome");
  }

  return false;
};

export const isAndroidWebView = () => {
  if (typeof window === "undefined") return false;
  return USER_AGENT.includes("wv") && USER_AGENT.includes("chrome");
};

export function isSafari() {
  if (typeof window === "undefined") return false;
  // Chrome has both 'Chrome' and 'Safari' inside userAgent string. Safari has only 'Safari'.
  // https://stackoverflow.com/questions/12799212/chrome-is-detected-as-safari-with-javascript
  return !USER_AGENT.includes("chrome/") && USER_AGENT.includes("safari/");
}

export const isMac = () => {
  if (typeof window === "undefined") return false;
  return USER_AGENT.includes("mac");
};

export const isAppleWebView = () => {
  if (typeof window === "undefined") {
    return false;
  }

  return Boolean(window.___isIOSApp || window.parent?.___isIOSApp);
};

export const defaultPageSize = () => {
  if (typeof window === "undefined") return 1;
  return 50;
};

export const iOSVersion = () => {
  const start = USER_AGENT.indexOf("os ");
  if (
    (USER_AGENT.indexOf("iphone") > -1 || USER_AGENT.indexOf("ipad") > -1) &&
    start > -1
  ) {
    return window.Number(USER_AGENT.substr(start + 3, 3).replace("_", "."));
  }
  return 0;
};

export function isAppAvailable() {
  return (
    (isAppleDevice() && !isAppleWebView()) ||
    (isAndroidDevice() && !isAndroidWebView())
  );
}

export function isAppleDevice() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(PLATFORM) ||
    // iPad on iOS 13 detection
    (USER_AGENT.includes("mac") && "ontouchend" in document)
  );
}

export function isWebView() {
  return isAppleWebView() || isAndroidWebView();
}

export function isAndroidDevice() {
  return USER_AGENT.includes("android");
}

export const isInSocialMediaWebview = () => {
  return (
    USER_AGENT.includes("twitter for iphone") ||
    USER_AGENT.includes("twitterandroid") ||
    USER_AGENT.includes("fbav") || // Facebook for Android
    USER_AGENT.includes("fb_iab") || // Facebook in-app browser
    USER_AGENT.includes("fbios") || // Facebook for iOS
    USER_AGENT.includes("instagram") ||
    USER_AGENT.includes("line") ||
    USER_AGENT.includes("linkedin") ||
    USER_AGENT.includes("pinterest") ||
    USER_AGENT.includes("snapchat") ||
    USER_AGENT.includes("reddit") ||
    USER_AGENT.includes("tumblr")
  );
};

export function getDisplayMode(): DisplayMode {
  const thresholds = getThresholds();
  const width = getDisplayWidth();

  if (width < thresholds.tablet) {
    return "mobile";
  }

  if (width >= thresholds.mobile && width < thresholds.desktop) {
    return "tablet";
  }

  if (width >= thresholds.desktop && width < thresholds.desktopXl) {
    return "desktop";
  }

  if (width >= thresholds.desktopXl && width < thresholds.expanded) {
    return "desktopXl";
  }

  return "expanded";
}

function getDisplayModeIndex(mode: DisplayMode) {
  if (!DISPLAY_MODE_INDICES.hasOwnProperty(mode)) {
    mode = getDefaultMode();
  }
  return DISPLAY_MODE_INDICES[mode];
}

export function isDisplayAtMost(mode: DisplayMode) {
  const displayMode = getDisplayMode();
  return getDisplayModeIndex(displayMode) <= getDisplayModeIndex(mode);
}

export function isDisplayAtLeast(mode: DisplayMode) {
  return getDisplayModeIndex(getDisplayMode()) >= getDisplayModeIndex(mode);
}

export function isDisplayMode(mode: DisplayMode) {
  return getDisplayMode() === mode;
}

export function getThreshhold(mode: DisplayMode) {
  return getThresholds()[mode];
}

export function getPixelValue(n: string) {
  return Number.parseInt((n || "").replace(/[^\d]/g, ""), 10);
}

const STREAM_TIME_BEFORE = 30 * 60 * 1000;

export function hideStreamIconBefore(eventStartTime: Date): number {
  if (!eventStartTime) {
    return 0;
  }
  return eventStartTime.valueOf() - STREAM_TIME_BEFORE;
}

export function shouldShowStreamIcon(eventStartTime: Date) {
  return new Date().valueOf() > hideStreamIconBefore(eventStartTime);
}

export const getOptimisedImageUrl = (url: string, width?: number) => {
  const dpi = typeof window !== "undefined" ? window.devicePixelRatio || 1 : 1;
  const imageWidth =
    (width || isDisplayMode("mobile") ? window.screen.availWidth : 1050) * dpi;

  return `url('${url}?w=${Math.round(imageWidth)}&q=98&fm=${
    isWebPSupported() ? "webp" : "png"
  }')`;
};

export const isWebPSupported = () => {
  if (typeof window !== "undefined") {
    const elem = document.createElement("canvas");

    if (!!(elem.getContext && elem.getContext("2d"))) {
      return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    }
  }
  return false;
};

let thresholds: Record<DisplayMode, number>;

export function getThresholds() {
  if (!thresholds) {
    thresholds = {
      mobile: getPixelValue(responsiveness.widthMobile),
      tablet: getPixelValue(responsiveness.widthTablet),
      desktop: getPixelValue(responsiveness.widthDesktop),
      desktopXl: getPixelValue(responsiveness.widthDesktopXl),
      expanded: getPixelValue(responsiveness.widthExpanded),
    };
  }
  return thresholds;
}

export const reloadPage = () => {
  if (isWebView()) {
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_RELOAD, {});
  } else {
    location.reload();
  }
};

import React from "react";
import {
  useSelection,
  useSelectionAlert,
} from "sections/Betting/Betslip/hooks/useSelection";

import { BetCardAlert } from "./BetCardAlert";
import { Clamp } from "components/Clamp";

import * as styles from "./SingleCardAlert.module.scss";

type SingleCardAlertProps = {
  outcomeId: string;
};

const SingleCardAlert = ({ outcomeId }: SingleCardAlertProps) => {
  const selection = useSelection(outcomeId);
  const alert = useSelectionAlert(selection);

  if (alert) {
    return (
      <BetCardAlert
        variant={alert.variant}
        icon={alert.icon}
        clamp={
          alert.cta ? (
            <Clamp
              className={styles.clickable}
              onClick={alert.cta?.action}
              variant={alert.variant as any} // match types here eventually
            >
              {alert.cta?.label}
            </Clamp>
          ) : null
        }
      >
        {alert.content}
      </BetCardAlert>
    );
  }

  return null;
};

export { SingleCardAlert };

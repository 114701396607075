// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Pill-module--emphasis--b8a3a";
export var empty = "Pill-module--empty--b5eae";
export var green = "#57d33b";
export var greenLink = "Pill-module--green-link--d3cac";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pill = "Pill-module--pill--526f2";
export var red = "#ff2741";
export var selected = "Pill-module--selected--4d136";
export var text = "Pill-module--text--f490b";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
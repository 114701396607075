import * as React from "react";
import { type VariantProps, cva } from "class-variance-authority";
import cx from "classnames";
import { Pacman } from "components/Pacman";
import { Skeleton } from "components/Skeleton";

import * as styles from "./Button.module.scss";

const buttonVariants = cva(styles.button, {
  variants: {
    variant: {
      primary: styles.primary,
      secondary: styles.secondary,
      blurred: styles.blurred,
      danger: styles.danger,
      warning: styles.warning,
      info: styles.info,
      link: styles.link,
    },
    size: {
      default: styles.sizeDefault,
      md: styles.sizeMd,
      sm: styles.sizeSm,
      xs: styles.sizeXs,
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "default",
  },
});

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    icon?: React.ReactNode;
    loading?: boolean;
    skeleton?: boolean;
    gutterTop?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      gutterTop,
      variant,
      size,
      icon,
      children,
      loading,
      skeleton = false,
      ...props
    },
    ref,
  ) => {
    const iconComponent = icon ? (
      <span className={styles.icon}>{icon}</span>
    ) : null;

    const isPacmanLight = ["secondary", "blurred", "link"].includes(variant);
    const isPacmanSmall = size === "xs";

    const buttonClassName = cx(buttonVariants({ variant, size }), className, {
      [styles.hasIcon]: !!icon,
      [styles.gutterTop]: gutterTop,
      [styles.skeleton]: skeleton,
      [styles.maxContent]: skeleton && variant === "link",
    });

    return skeleton ? (
      <Skeleton className={buttonClassName}>{children}</Skeleton>
    ) : (
      <button
        className={buttonClassName}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
        data-variant={variant || "primary"}
        data-size={size || "default"}
        type={props.type || "button"}
      >
        {loading && (
          <Pacman
            size={isPacmanSmall ? "sm" : "default"}
            variant={isPacmanLight ? "light" : "dark"}
          />
        )}
        {!loading && (
          <>
            {iconComponent}
            {children}
          </>
        )}
      </button>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };

import React from "react";
import LoadMore from "sections/Betting/LoadMore";
import { defaultPageSize } from "utilities/display";
import useRestoreScrollPosition from "hooks/useRestoreScrollPosition";
import type { RacingRouteParams } from "sections/Betting/Racing/hooks/useRacingRoute";
import { Box } from "components/Box";
import { RaceMeetingEventSEOSchema } from "sections/Betting/Racing/components/RaceMeetings/";
import { RaceRow, RowTemplate } from "sections/Betting/Racing/components/";
import { useRacingEvents } from "sections/Betting/Race/hooks/useRacingEvents";
import { PageMessage } from "components/PageMessage";
import { ReactComponent as OddsUnavailable } from "components/assets/icon-empty-odds.svg";

import * as styles from "./NextToJump.module.scss";
import { NextToJumpSkeleton } from "sections/Betting/Racing/components/RaceRow/RaceRowsSkeleton";

export type NextToJumpProps = {
  route: Partial<RacingRouteParams>;
  setRouteValue: (arg: Partial<RacingRouteParams>) => void;
};

export default ({ route, setRouteValue }: NextToJumpProps) => {
  const [races, loading] = useRacingEvents(route);
  const [page, setPage] = React.useState(Number(route.page) || 1);

  useRestoreScrollPosition(loading);

  if (loading && page === 1) {
    return <NextToJumpSkeleton count={50} />;
  }

  return (
    <>
      {races?.length === 0 && !loading && (
        <div className={styles.unavailable}>
          <PageMessage
            title="Races Unavailable"
            image={<OddsUnavailable />}
            subTitle="There are currently no races which match your filters"
          />
        </div>
      )}

      <Box hideHeader>
        {races.map((race) => (
          <RaceRow
            {...race}
            key={race.id}
            template={RowTemplate.NEXT_TO_JUMP}
          />
        ))}
      </Box>

      <LoadMore
        hasMore={page * defaultPageSize() <= races?.length}
        next={() => {
          setPage(page + 1);
          setRouteValue({ page: (page + 1).toString() });
        }}
      />

      <RaceMeetingEventSEOSchema races={races} />
    </>
  );
};

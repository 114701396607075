import * as uuid from "uuid";
import isEmpty from "lodash/isEmpty";
import { toggleSelection } from "sections/Betting/Betslip/betslipSlice";
import { getSubSelectionsFromOutcomes } from "utilities/sem";
import { useRaceMarketsAndCompetitors } from "sections/Betting/Race/hooks/useRace";
import { mapRaceToEvent } from "utilities/racingUtilities";

import type { PickType } from "sections/Entries/types";

export const useSRMSelectionToggle = (pick: PickType) => {
  const { race, competitors, markets } = useRaceMarketsAndCompetitors(
    pick.eventId,
  );

  if (isEmpty(pick.outcomes)) return;
  if (!pick || !race || !markets) return;

  const subSelections = getSubSelectionsFromOutcomes(pick.outcomes);
  if (subSelections.length <= 1) return;

  return toggleSelection(
    mapRaceToEvent({ event: race, competitors, markets }),
    {
      type: "race",
      outcomeId: uuid.v4(),
      odds: pick.odds,
      sourceId: race.id,
      eventId: race.id,
      subSelections,
    },
    "racing",
  );
};

import { InfoPanel } from "components/InfoPanel";
import React from "react";
import { useLocalStorage } from "usehooks-ts";

export default () => {
  const [open, setOpen] = useLocalStorage("deductionsInformation", true);

  return (
    <InfoPanel
      title="Why are deductions applied?"
      open={open}
      onOpen={setOpen}
      showArrow
    >
      <div>
        <p>
          Deductions are an industry-wide requirement of fixed odds betting on
          racing. They occur due to scratchings and are applied as a deduction
          of cents in the dollar (effectively, a percentage) from your potential
          payout. It is a necessary re-framing of the market if runners are
          scratched from the final field.
        </p>
        <p>
          All final field scratchings will incur a deduction to eligible fixed
          odds bets. A fixed odds bet is eligible for a deduction if the
          relevant scratching occurs after the bet has been placed. Any
          scratchings from before the bet is placed do not impact the bet.
        </p>
        <br />

        <h3>Example</h3>
        <p>
          You place a $10 win bet on a horse with fixed odds of $2.50. Any
          scratchings that occurred before your bet was placed do not apply.
          However, there are scratchings after you place your bet which incur a
          total of $0.20 in deductions per dollar (20%).
        </p>
        <br />
        <h3>To calculate the payout when your bet wins</h3>
        <ul>
          <li>$10 stake x $2.50 odds = $25 potential payout</li>
          <li>
            $25 potential payout x $0.20 deduction per dollar (20%) = $25 x 0.20
            = $5 deducted amount
          </li>
          <li>$25 potential payout - $5 deducted amount = $20 actual payout</li>
        </ul>
      </div>
    </InfoPanel>
  );
};

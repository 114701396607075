export const Delighted = () => (events) => {
  if (!window) {
    return;
  }
  if (!window.delighted) {
    throw new Error(
      "window.delighted is not defined, Have you forgotten to include the Delighted snippet?",
    );
  }

  events.forEach((event) => {
    switch (event.hitType) {
      case "survey":
        window.delighted.survey({
          email: event.email,
          name: event.name,
          properties: event.properties,
        });
        break;
      default:
        break;
    }
  });
};

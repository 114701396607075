// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var dialog = "ShareEntryForm-module--dialog--c632a";
export var emphasis = "ShareEntryForm-module--emphasis--e4b86";
export var green = "#57d33b";
export var greenLink = "ShareEntryForm-module--green-link--73017";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var shareForm = "ShareEntryForm-module--shareForm--909f2";
export var toggle = "ShareEntryForm-module--toggle--b6b9f";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
// extracted by mini-css-extract-plugin
export var addButton = "Slip-module--addButton--88263";
export var arrowDown = "Slip-module--arrowDown--d421d";
export var arrowUp = "Slip-module--arrowUp--2a3da";
export var blue = "#0eb3f2";
export var buttonContainer = "Slip-module--buttonContainer--bbaad";
export var caret = "Slip-module--caret--09bbe";
export var chevron = "Slip-module--chevron--a0c75";
export var emphasis = "Slip-module--emphasis--5d499";
export var green = "#57d33b";
export var greenLink = "Slip-module--green-link--19c55";
export var grey = "var(--tone-mid)";
export var label = "Slip-module--label--78662";
export var line = "Slip-module--line--7eb2d";
export var loginButton = "Slip-module--loginButton--ae18b";
export var notchGap = "32px";
export var odds = "Slip-module--odds--1c881";
export var oddsChanging = "Slip-module--oddsChanging--52915";
export var orange = "#f8662b";
export var red = "#ff2741";
export var showSelections = "Slip-module--showSelections--3e696";
export var slip = "Slip-module--slip--46211";
export var text = "Slip-module--text--ccdde";
export var textValue = "Slip-module--textValue--6fbf1";
export var trash = "Slip-module--trash--3bdda";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import cx from "classnames";
import { Slot } from "@radix-ui/react-slot";
import { Link } from "components/Link";
import { ListItemSkeleton } from "./ListItemSkeleton";
import { renderChildrenWithLoading } from "utilities/dom";

import * as styles from "./List.module.scss";

type ListProps = {
  children: React.ReactNode;
  asChild?: boolean;
  loading?: boolean;
};

type ListItemProps = {
  icon?: React.ReactNode;
  label: React.ReactNode;
  value?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  to?: string;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
};

type ListBadgeProps = {
  children: React.ReactNode;
  icon?: React.ReactNode;
} & VariantProps<typeof badgeVariants>;

export const badgeVariants = cva(styles.badge, {
  variants: {
    variant: {
      danger: styles.danger,
      warning: styles.warning,
      info: styles.info,
      success: styles.success,
      neutral: styles.neutral,
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

const ListItem = ({
  icon,
  label,
  value,
  children,
  disabled,
  to,
  onClick,
  loading,
  className: classNameProp,
}: ListItemProps) => {
  const Component = to ? Link : "li";
  const toProps = to ? { to } : {};

  const className = cx(styles.item, {
    [classNameProp]: !!classNameProp,
    [styles.disabled]: disabled,
    [styles.pointer]: !!onClick,
  });
  if (loading) return <ListItemSkeleton className={className} />;

  return (
    // NOTE: This is a hack to get around reach router link ts issues
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component className={className} onClick={onClick} {...toProps}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>{label}</span>
      {value && <span className={styles.value}>{value}</span>}
      {children ? children : null}
    </Component>
  );
};

const List = ({ children, asChild, loading }: ListProps) => {
  const Component = asChild ? Slot : "ul";
  return (
    <Component className={styles.list}>
      {renderChildrenWithLoading(children, loading)}
    </Component>
  );
};

const ListBadge = ({ children, icon, variant }: ListBadgeProps) => {
  return (
    <span className={badgeVariants({ variant })}>
      {icon && <span className={styles.icon}>{icon}</span>}
      {children}
    </span>
  );
};

export { List, ListItem, ListBadge };

import type { EventCounts } from "hooks/firestore/betting/useEventCounts";

export interface ITitleCounts extends Record<string, number> {
  total?: number;
}

export enum EventTypes {
  matches = "matches",
  outrights = "outrights",
  contests = "contests",
  racing = "racing",
}

export type IEventCounts = Record<EventTypes, ITitleCounts>;

export function getTotalCountForSports(
  eventCounts: EventCounts,
  slugs: string[],
  eventTypesFilter: EventTypes[],
): number {
  return eventCounts
    ? Object.entries(eventCounts)
        .filter(([key]) => eventTypesFilter.includes(key))
        .reduce((acc, [, value]) => {
          return (
            acc + slugs.reduce((acc2, slug) => acc2 + (value[slug] || 0), 0)
          );
        }, 0)
    : 0;
}

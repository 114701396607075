import React from "react";
import { ButtonWithState as Button } from "library";
import { navigate } from "library/components/Link";

type PicklebetButtonProps = {
  to: string;
  children: JSX.Element;
};

const PicklebetButton = ({ to, children, ...props }: PicklebetButtonProps) => {
  const handleButtonClick = () => {
    if (to) {
      if (to.startsWith("/")) {
        navigate(to);
      } else {
        window.open(to, "_blank");
      }
    }
  };

  return (
    <Button onClick={handleButtonClick} {...props}>
      {children}
    </Button>
  );
};

export default PicklebetButton;

import { useIsApplePayAvailable } from "hooks/payments/useApplePay";
import useIsGooglePayAvailable from "hooks/payments/useIsGooglePayAvailable";
import type { CreditCard } from "types/UserTypes";
import { useMemo } from "react";

export const useIsQuickDepositAvailable = (creditCards: CreditCard[]) => {
  const isApplePayAvailable = useIsApplePayAvailable();
  const isGooglePayAvailable = useIsGooglePayAvailable();

  const hasValidCreditCard = useMemo(
    () =>
      creditCards &&
      creditCards?.some(
        (card) => card.active && card.successfulDepositCount > 0,
      ),
    [creditCards],
  );

  return isApplePayAvailable || isGooglePayAvailable || hasValidCreditCard;
};

import { useContext, useMemo } from "react";
import { RaceContext } from "sections/Betting/Race/Race";
import { RaceMarketTypeTypes } from "sections/Betting/Race/hooks/RacingTypes";
import { useSortedCompetitors } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { getValidCompetitorCount } from "utilities/racingUtilities";

type RaceCommentResult = {
  competitors: string[];
  comment: string | undefined;
};

export const COMPETITOR_SEPARATOR = "{|}";

export const parseRaceComment = (
  comment: string | undefined,
): RaceCommentResult => {
  const competitorsNumberInCommentsPattern = new RegExp(
    /\b([A-Z][A-Za-z'.]*(?:\s+[A-Z][A-Za-z'.]*)*)\s+\(\d+\)/gm,
  );
  const competitors: string[] = [];

  // Extract just the numbers for the competitors array
  let matches;
  while ((matches = competitorsNumberInCommentsPattern.exec(comment))) {
    if (matches[0]) {
      const match = matches[0].match(/\((\d+)\)/);
      if (match && match[1]) {
        competitors.push(match[1]);
      }
    }
  }

  // Replace competitor names and numbers with the separator
  const cleanedComment =
    comment?.replace(
      competitorsNumberInCommentsPattern,
      COMPETITOR_SEPARATOR,
    ) || "";

  return {
    competitors,
    comment: cleanedComment,
  };
};

const marketsRequired = [RaceMarketTypeTypes.Win];

export const useRaceTips = () => {
  const { race, selectedPills, route } = useContext(RaceContext);
  const parsedRaceComment = useMemo(
    () => parseRaceComment(race?.comment),
    [race?.comment],
  );

  const { mappedCompetitors } = useSortedCompetitors({
    marketsRequired,
    showResulted: true,
  });

  const validCompetitorCount = useMemo(
    () => getValidCompetitorCount(mappedCompetitors),
    [mappedCompetitors],
  );

  const filteredCompetitors = useMemo(
    () =>
      parsedRaceComment?.competitors?.map((commentCompetitorNumber) =>
        mappedCompetitors?.find(
          (x) => commentCompetitorNumber === x?.number.toString(),
        ),
      ),
    [mappedCompetitors, parsedRaceComment],
  );

  const visible = selectedPills?.includes("comments");

  const showPrices = route.tab !== "same-race-multi";

  return {
    parsedRaceComment,
    filteredCompetitors,
    validCompetitorCount,
    showPrices,
    visible,
  };
};

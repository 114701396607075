import { useCopyToClipboard } from "usehooks-ts";
import { toast, useDisplayAtMost } from "hooks";
import {
  handleShareNatively,
  hasNavigatorShare,
  isExpectedError,
} from "utilities/share";
import { isSafari } from "utilities/display";

import type { ShareEntryFormSchema } from "./ShareEntryForm";
import { useShareUrl } from "./useShareUrl";

const SHARE_ACTION_SUCCESS = "Link copied and ready for sharing";
const SHARE_ACTION_ERROR =
  "Unable to generate the share URL. This is unexpected, try again or contact support";

export function useShareEntryHandler(
  referenceId: string,
  onComplete?: () => void,
) {
  const [shareUrl, getShareUrl, setShareUrl] = useShareUrl(referenceId);
  const isTabletOrSmaller = useDisplayAtMost("tablet");

  const [_, copyToClipboard] = useCopyToClipboard();

  const copy = async (url: string, onCopy?: () => void) => {
    await copyToClipboard(url);
    toast({ variant: "success", description: SHARE_ACTION_SUCCESS });

    onCopy?.();
  };

  const reset = () => setShareUrl("");

  const needsSecondSubmit = isSafari() && !isTabletOrSmaller;
  const hasShareUrl = !!shareUrl;

  const handleSubmit = async ({
    title,
    displayStake,
  }: ShareEntryFormSchema) => {
    if (needsSecondSubmit && hasShareUrl) {
      // we already have the url, copy it & get out
      return await copy(shareUrl, onComplete);
    }

    try {
      const url = await getShareUrl({
        referenceId,
        name: title,
        hideStake: !displayStake,
      });

      if (needsSecondSubmit) {
        // do nothing, url will be copied from state on the next submit
        // this works around restrictions in safari as making a network request
        // and firing an action on behalf of the user is interrupted as a risk
      } else if (isTabletOrSmaller && hasNavigatorShare()) {
        await handleShareNatively({ title: "Picklebet", url: url });
      } else {
        await copy(url);
      }
    } catch (error) {
      if (!isExpectedError(error.name)) {
        toast({ variant: "danger", description: SHARE_ACTION_ERROR });
      }
    } finally {
      if (!needsSecondSubmit) onComplete?.();
    }
  };

  return [handleSubmit, needsSecondSubmit, hasShareUrl, reset] as const;
}

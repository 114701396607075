// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var clear = "Stake-module--clear--9946c";
export var container = "Stake-module--container--725de";
export var disabled = "Stake-module--disabled--30583";
export var emphasis = "Stake-module--emphasis--8f12b";
export var green = "#57d33b";
export var greenLink = "Stake-module--green-link--293aa";
export var grey = "var(--tone-mid)";
export var iconContainer = "Stake-module--iconContainer--19a33";
export var input = "Stake-module--input--f3e4d";
export var notchGap = "32px";
export var orange = "#f8662b";
export var quickInput = "Stake-module--quickInput--5a43c";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var wrapper = "Stake-module--wrapper--6c9af";
export var yellow = "#ffb100";
import React from "react";
import cx from "classnames";
import { Link } from "components/Link";
import type { LinkProps } from "components/Link";
import { buttonVariants } from "components/Button";
import type { VariantProps } from "class-variance-authority";

type LinkButtonProps = LinkProps &
  VariantProps<typeof buttonVariants> & {
    className?: string;
    disabled?: boolean;
  };

const LinkButton = React.forwardRef<LinkButtonProps, LinkButtonProps>(
  ({ variant, size, className, disabled = false, ...props }, ref) => {
    return (
      <Link
        ref={ref as any}
        {...props}
        className={cx(buttonVariants({ variant, size }), className)}
        noStyles
        data-disabled={disabled}
        to={disabled ? null : props.to}
      />
    );
  },
);

export { LinkButton };

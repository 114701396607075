import React from "react";
import * as styles from "./Promotions.module.scss";
import {
  type PromotionsType,
  type BettingTabs,
  BETTING_TAB_GROUPS,
  useFiatCurrency,
  type CampaignsType,
} from "hooks";
import { Skeleton } from "components/Skeleton";
import cx from "classnames";
import { Promotion } from "./Promotion";

export const defaultHelpLink =
  "https://help.picklebet.com/hc/en-us/articles/5665601747471-Special-Promotion-Terms";

export const shouldShowCampaignOnTab = (
  tab: BettingTabs,
  campaignBettingTabs?: BettingTabs[],
) => {
  // If campaignBettingTabs is not defined or empty, show on all tabs
  if (!campaignBettingTabs || campaignBettingTabs.length === 0) {
    return true;
  }

  // If the current tab is explicitly included in campaignBettingTabs, show the campaign
  if (campaignBettingTabs.includes(tab)) {
    return true;
  }

  // Determine the group of the current tab
  const currentTabGroup = Object.keys(BETTING_TAB_GROUPS).find((key) =>
    BETTING_TAB_GROUPS[key].includes(tab),
  );

  // If the current tab group is targeted by campaignBettingTabs, do not show the campaign
  const isCurrentTabGroupTargeted = campaignBettingTabs.some((tab) =>
    BETTING_TAB_GROUPS[currentTabGroup]?.includes(tab),
  );

  // Show the campaign only if the current tab's group is not targeted
  return !isCurrentTabGroupTargeted;
};

export const shouldShowPromoIcon = (
  tab: BettingTabs,
  campaignBettingTabs: BettingTabs[],
) => campaignBettingTabs?.includes(tab);

export type PromotionWithoutCurrency = Omit<PromotionsType, "currency">;

export const CampaignPromotions = ({
  campaigns,
  className,
  tab,
  loading = false,
}: {
  campaigns: CampaignsType[];
  className?: string;
  tab?: BettingTabs;
  loading?: boolean;
}) => {
  const usersCurrency = useFiatCurrency();

  if (loading) {
    return <Skeleton edge="hard" className={cx(styles.skeleton, className)} />;
  }

  if (!campaigns || campaigns.length === 0) {
    return null;
  }

  // Filter out campaigns that don't contain at least one promotion for the user's currency
  const filteredCampaigns = campaigns.filter((campaign) => {
    if (Object.keys(campaign?.promotions || {}).length === 0) {
      return true;
    }
    const promotions = Object.entries(campaign?.promotions || {}).filter(
      ([_, promotion]) => promotion?.currency === usersCurrency,
    );

    return promotions.length > 0;
  });

  return (
    <div className={className}>
      {filteredCampaigns?.map((campaign) => {
        const promotions = Object.entries(campaign?.promotions || {}).filter(
          ([_, promotion]) => promotion?.currency === usersCurrency,
        );

        if (!shouldShowCampaignOnTab(tab, campaign?.bettingTabs)) {
          return null;
        }

        if (promotions.length === 0) {
          return (
            <Promotion
              key={campaign?.id}
              promotion={{
                name: campaign?.name,
                description: campaign?.description,
                termsOfUse: campaign?.termsOfUse,
              }}
            />
          );
        }

        return promotions?.map(([promotionsId, promotion]) => (
          <Promotion promotion={promotion} key={promotionsId} />
        ));
      })}
    </div>
  );
};

import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import * as styles from "./Drawer.module.scss";

type DrawerProps = {
  children?: React.ReactNode;
  isOpen: boolean;
};

const drawerVariants = {
  open: {
    opacity: 1,
    height: "auto",
  },
  closed: {
    opacity: 0,
    height: 0,
  },
};

export const Drawer = ({ children, isOpen }: DrawerProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={styles.drawerContainer}
          variants={drawerVariants}
          animate={isOpen ? "open" : "closed"}
          initial={"closed"}
          exit={"closed"}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

import React from "react";
import { motion } from "framer-motion";
import {
  useBetslip,
  useDispatch,
  useSelector,
  useUserEventFilters,
} from "hooks";
import { BetCard } from "./BetCard";
import { Empty } from "./Empty";
import { StakeAll } from "./StakeAll";
import { UnusedSelections } from "./UnusedSelections";
import {
  selectSelectionsWithoutUnused,
  setStakeAllSingles,
} from "sections/Betting/Betslip/betslipSlice";

const Single = () => {
  const {
    props: {
      stakeAll,
      betslipIsConfirming,
      betslipIsReviewingMessages,
      betslipIsSubmitting,
    },
  } = useBetslip();

  const userFilters = useUserEventFilters();

  const selections = useSelector(selectSelectionsWithoutUnused);

  const dispatch = useDispatch();

  const canStakeAll =
    !betslipIsConfirming &&
    !betslipIsReviewingMessages &&
    !betslipIsSubmitting &&
    selections.length > 1;

  if (!selections.length) {
    return <Empty />;
  }

  return (
    <>
      {canStakeAll ? (
        <StakeAll
          label={`Stake All Singles`}
          onChange={(stake) => {
            dispatch(setStakeAllSingles({ stake, userFilters }));
          }}
          currencySymbol={`$`}
          value={stakeAll}
        />
      ) : null}

      <motion.div>
        {selections.map((outcomeId) => (
          <BetCard key={`card-${outcomeId}`} outcomeIds={[outcomeId]} />
        ))}

        <UnusedSelections />
      </motion.div>
    </>
  );
};

export { Single };

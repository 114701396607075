import React from "react";
import cx from "classnames";
import appConfig from "appConfig";
import { isAndroidWebView } from "utilities/display";
import RawGooglePayButton from "@google-pay/button-react";
import { useGooglePay } from "hooks/payments/useGooglePay";

import * as styles from "./GooglePayButton.module.scss";

type GooglePayButtonProps = {
  loading: boolean;
  isFinal: boolean;
  amount: number;
  onValidationError: (callback: () => void) => void;
  size?: "small" | "large";
};

const GooglePayButton = ({
  loading,
  amount,
  isFinal,
  onValidationError,
  size = "large",
}: GooglePayButtonProps) => {
  const { paymentRequest, deposit } = useGooglePay({
    amount,
    isFinal,
    loading,
  });
  return (
    <div
      className={cx(styles.container, {
        [styles.small]: size === "small",
      })}
    >
      <RawGooglePayButton
        className={styles.gpay}
        environment={appConfig.ENV === "production" ? "PRODUCTION" : "TEST"}
        buttonColor="white"
        buttonSizeMode="fill"
        buttonType={"pay"}
        paymentRequest={paymentRequest}
        onClick={(e) => onValidationError(() => e.preventDefault())}
        onError={(error) => {
          if (loading) return;
          console.error("Google Deposit Error:", error);
        }}
        onLoadPaymentData={async (response) => {
          const {
            paymentMethodData: {
              tokenizationData: { token },
            },
          } = response;

          await deposit(token);
        }}
      />
      {isAndroidWebView() && (
        <div onClick={() => deposit()} className={styles.clickCapture}></div>
      )}
    </div>
  );
};

export { GooglePayButton };

import {
  collection,
  type CollectionReference,
  type DocumentData,
  type FirestoreDataConverter,
  type FirestoreError,
  query,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
  where,
} from "firebase/firestore";
import { useCollectionData } from "hooks";
import { useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import type { IEventCounts } from "types/EventCounts";

const defaultEventCounts: Partial<IEventCounts> = {
  contests: {
    total: 0,
  },
};

const sportsConverter: FirestoreDataConverter<string[]> = {
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): any => {
    return snapshot.data(options).sports;
  },
};

/**
 * This should only be used by FirebaseContext for performance reasons until we refactor the layout components so that
 * navigating to a new section doesn't cause the whole site to be remounted.
 */
const usePickemsEventCounts = (): [IEventCounts, boolean, FirestoreError] => {
  const contestsRef = collection(
    getFirestore(),
    "pickemsContests",
  ).withConverter(sportsConverter) as CollectionReference<string[]>;
  const [sports, loading, error] = useCollectionData(
    query(contestsRef, where("status", "in", ["PENDING", "LIVE"])),
    contestsRef?.path,
  );

  const eventCounts = useMemo(() => {
    if (loading) return defaultEventCounts;

    const contestCounts = (sports || []).reduce(
      (acc, sports) => {
        return (
          Object.values(sports)?.reduce(
            (acc2: Record<string, number>, sport: string) => {
              const slug = sport.toLowerCase();
              const { total: currentTotal = 0, [slug]: currentTitle = 0 } =
                acc2;
              return {
                ...acc2,
                total: currentTotal + 1,
                [slug]: currentTitle + 1,
              };
            },
            acc,
          ) || acc
        );
      },
      { total: 0 },
    );
    return { contests: contestCounts };
  }, [sports, loading]);

  return [eventCounts as IEventCounts, loading, error];
};

export default usePickemsEventCounts;

// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "ErrorMessage-module--emphasis--fc910";
export var error = "ErrorMessage-module--error--e97a6";
export var errorMessage = "ErrorMessage-module--errorMessage--28484";
export var green = "#57d33b";
export var greenLink = "ErrorMessage-module--green-link--d59e2";
export var grey = "var(--tone-mid)";
export var info = "ErrorMessage-module--info--80c23";
export var message = "ErrorMessage-module--message--c4c85";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var srmLogo = "ErrorMessage-module--srmLogo--63d74";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import { useState, useEffect, useContext, useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import {
  type PromotionsAvailableType,
  useCollectionData,
  usePermissions,
} from "hooks";
import {
  collection,
  query,
  type FirestoreError,
  type QueryConstraint,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type DocumentData,
  type SnapshotOptions,
} from "firebase/firestore";
import { FirebaseContext } from "context/Firebase";
import {
  type RacingRouteParams,
  RegionType,
} from "sections/Betting/Racing/hooks/useRacingRoute";
import type { RaceStatuses } from "sections/Betting/Race/hooks/RacingTypes";
import {
  addDays,
  endOfDay,
  endOfToday,
  nextSaturday,
  nextSunday,
  parse,
  startOfDay,
  startOfToday,
  subDays,
} from "date-fns";
import { processPromotionAvailability } from "utilities/sharedBettingUtilities";
import { parseDate } from "utilities/dateUtilities";
import type {
  RaceRegionTypes,
  RaceSportTypes,
} from "sections/Betting/Race/hooks/useRacingEvents";
import { useUserEventFilters } from "hooks/firestore/useUserAttributes";

export type RaceMeetingType = {
  id: string;
  date: string;
  scheduledStartTime: Date;
  scheduledEndTime: Date;
  sport: RaceSportTypes;
  venue: string;
  venueId: string;
  eventCount: number;
  country: string;
  state: string;
  trackCondition: string;
  trackRating: string;
  trackType: string;
  weather: string;
  rail: string;
  region: RaceRegionTypes;
  promotionVisibility: PromotionsAvailableType;
  currentRace: {
    id: string;
    name: string;
    number: number;
    scheduledStartTime: Date;
    status: RaceStatuses;
  };
  promotionIsAvailable: boolean;
  filters: string[];
};

export const getDateForSelectedTab = (
  tab: string,
): { start: Date; end: Date } => {
  switch (tab) {
    case "today":
    case "next-to-jump":
      return { start: startOfToday(), end: endOfToday() };
    case "tomorrow":
      return {
        start: addDays(startOfToday(), 1),
        end: addDays(endOfToday(), 1),
      };
    case "yesterday":
      return {
        start: subDays(startOfToday(), 1),
        end: subDays(endOfToday(), 1),
      };
    case "saturday":
      return {
        start: startOfDay(nextSaturday(new Date())),
        end: endOfDay(nextSaturday(new Date())),
      };
    case "sunday":
      return {
        start: startOfDay(nextSunday(new Date())),
        end: endOfDay(nextSunday(new Date())),
      };
    case undefined:
      return { start: startOfToday(), end: endOfToday() };
    default:
      // validate date as yyyy-MM-dd
      if (!parseDate(tab)) {
        return { start: startOfToday(), end: endOfToday() };
      }

      return {
        start: startOfDay(parse(tab, "yyyy-MM-dd", new Date())),
        end: endOfDay(parse(tab, "yyyy-MM-dd", new Date())),
      };
  }
};

export const getRaceMeetingsQuery = (
  route: Partial<RacingRouteParams>,
  userEventFilters: string[],
): QueryConstraint[] => {
  const queries = [];

  if (route?.tab) {
    const dateForSelectedTab = getDateForSelectedTab(route.tab);

    queries.push(
      where("currentRace.scheduledStartTimeTs", ">=", dateForSelectedTab.start),
    );
    queries.push(
      where("currentRace.scheduledStartTimeTs", "<=", dateForSelectedTab.end),
    );
  }

  if (userEventFilters?.length > 0) {
    queries.push(where("filters", "array-contains-any", userEventFilters));
  }

  if (route.region.length === 1 && route.region.includes(RegionType.au)) {
    queries.push(where("region", "==", "ANZ"));
  } else if (
    route.region.length === 1 &&
    route.region.includes(RegionType.world)
  ) {
    queries.push(where("region", "==", "ROW"));
  }

  return queries;
};

export const createRaceMeetingsConverter = (
  userCampaigns: string[],
  permissions: Record<string, string>,
): FirestoreDataConverter<RaceMeetingType> => {
  return {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): RaceMeetingType => {
      const data = snapshot.data(options);

      const promotionIsAvailable = processPromotionAvailability(
        data?.promotionVisibility,
        data?.campaignIds,
        userCampaigns,
        permissions,
      );

      return {
        id: snapshot.id,
        ...data,
        currentRace: {
          ...data.currentRace,
          scheduledStartTime: parseDate(data.currentRace.scheduledStartTime),
        },
        scheduledStartTime: parseDate(data.scheduledStartTime),
        promotionIsAvailable,
      } as RaceMeetingType;
    },
  };
};

export const useRaceMeetings = (
  route: Partial<RacingRouteParams>,
): [RaceMeetingType[], boolean, FirestoreError] => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { campaigns: userCampaigns } = useContext(FirebaseContext);
  const permissions = usePermissions();
  const userEventFilters = useUserEventFilters();

  const raceMeetingsConverter = useMemo(
    () => createRaceMeetingsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const ref = collection(getFirestore(), "raceMeetings").withConverter(
    raceMeetingsConverter,
  );

  const queries = useMemo(
    () => getRaceMeetingsQuery(route, userEventFilters),
    [route, userEventFilters],
  );

  const [rawRows, rawLoading, error] = useCollectionData(
    query(ref, ...queries),
    ref?.path,
  );

  useEffect(() => {
    if (!rawRows || rawLoading) {
      setLoading(true);
      return;
    }

    setRows(rawRows);
    setLoading(false);
  }, [rawRows, rawLoading]);

  return [rows, loading, error];
};

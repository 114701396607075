import React, { useContext } from "react";
import { useBetslip, useDispatch } from "hooks";
import { Clamp } from "components/Clamp";
import { useTitle } from "hooks/graphql/useTitles";
import type { SourceType } from "hooks/firestore/betting/types";
import cx from "classnames";

import { ReactComponent as SGMTitleIcon } from "components/layout/assets/sgm-title.svg";
import { ReactComponent as SRMTitleIcon } from "components/layout/assets/srm-title.svg";
import { ReactComponent as SGMIcon } from "components/layout/assets/icon-sgm.svg";
import { ReactComponent as SRMIcon } from "components/layout/assets/icon-srm.svg";
import { ReactComponent as CloseIcon } from "components/assets/close.svg";
import { removeSubOutcome } from "sections/Betting/Betslip/betslipSlice";
import { BetOdds } from "library";
import { FirebaseContext } from "context/Firebase";
import { useSelection } from "sections/Betting/Betslip/hooks/useSelection";
import type { OddsFormat, SubOutcomesType } from "types/BetTypes";
import { OutcomeAlert } from "../OutcomeAlert";
import { StatusWithStream } from "../StatusWithStream";

import * as styles from "./shared.module.scss";

type MultiOutcomeProps = {
  outcomeId: string;
  isLast: boolean;
};

type SubOutcomeProps = {
  outcomeId: string;
  eventId: string;
  subOutcome: SubOutcomesType;
  sport: string;
  sourceType: SourceType;
  isRemovable: boolean;
};

const SubOutcome = ({
  subOutcome,
  sport,
  isRemovable,
  outcomeId,
}: SubOutcomeProps) => {
  const dispatch = useDispatch();
  const title = useTitle(sport.toLowerCase());

  return (
    <div className={styles.subOutcome}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <img
            alt={title?.name || "bet-pick-icon"}
            src={title?.icon?.file?.url}
          />
        </div>
        <div className={styles.title}>
          <div className={styles.selectionName}>{subOutcome?.name}</div>
          <div className={styles.marketName}>{subOutcome?.marketName}</div>
        </div>
        {isRemovable && (
          <div
            className={styles.clamp}
            onClick={() =>
              dispatch(
                removeSubOutcome({
                  outcomeId: outcomeId,
                  subOutcomeId: subOutcome.outcomeId,
                }),
              )
            }
          >
            <Clamp variant={`grey`} className={styles.close}>
              <CloseIcon />
            </Clamp>
          </div>
        )}
      </div>
    </div>
  );
};

const MultiOutcome = ({ outcomeId, isLast }: MultiOutcomeProps) => {
  const selection = useSelection(outcomeId);
  const {
    props: {
      betslipIsActive,
      betslipIsReviewingMessages,
      betslipIsSubmitting,
      betType,
    },
    actions: { removeSelection },
  } = useBetslip();
  const dispatch = useDispatch();
  const isSrm = selection?.sourceType === "race";
  const TitleIcon = isSrm ? SRMTitleIcon : SGMTitleIcon;
  const MultiIcon = isSrm ? SRMIcon : SGMIcon;
  const legCount = selection?.subOutcomes?.length;
  const isRemovable =
    (selection?.subOutcomes || []).length > 2 &&
    betslipIsActive &&
    !betslipIsReviewingMessages;
  const {
    userPreferences: { oddsFormat },
  } = useContext(FirebaseContext);

  const odds = selection?.changes?.newOdds || selection?.odds;
  const isMulti = betType === "MULTI";

  return (
    <div
      className={cx(styles.pick, {
        [styles.isLast]: isLast,
      })}
    >
      {isMulti && <OutcomeAlert outcomeId={outcomeId} />}

      <div className={styles.header}>
        <div className={styles.icon}>
          <TitleIcon />
        </div>
        <div className={styles.title}>
          <div className={styles.selectionName}>{selection?.eventName}</div>
          <div className={styles.marketName}>{selection?.market}</div>
        </div>
        <div className={styles.clamp}>
          {betslipIsSubmitting ? null : (
            <Clamp
              variant={`grey`}
              onClick={() => dispatch(removeSelection(selection?.outcomeId))}
              className={cx(styles.close, {
                [styles.hidden]: betslipIsSubmitting,
              })}
            >
              <CloseIcon />
            </Clamp>
          )}

          <StatusWithStream
            status={selection?.eventStatus}
            scheduledStartTime={selection?.startTime}
            title={selection.sport}
            url={selection?.streamUri}
            eventId={selection?.eventId}
            isPlaceholder={false}
          />
        </div>
      </div>
      <div className={styles.multiSeparator}>
        <div className={styles.multiIcon}>
          <MultiIcon />
        </div>
        <div className={styles.legs}>
          {legCount} legs @
          <BetOdds base={odds} format={oddsFormat as OddsFormat} />
        </div>
      </div>
      <div className={styles.subOutcomes}>
        {selection?.subOutcomes?.map((outcome: SubOutcomesType) => (
          <SubOutcome
            key={`${outcome.marketId}-${outcome.outcomeId}`}
            eventId={selection?.eventId}
            sourceType={selection?.sourceType}
            sport={selection?.sport}
            subOutcome={outcome}
            outcomeId={selection.outcomeId}
            isRemovable={isRemovable}
          />
        ))}
      </div>
    </div>
  );
};

export { MultiOutcome };

import React from "react";
import cx from "classnames";
import { Clamp } from "components/Clamp";
import { Link } from "components/Link";
import { SmallPromoIcon } from "components/PromoIcon/SmallPromoIcon";
import { Skeleton } from "components/Skeleton";
import { useIsMobile } from "hooks";
import {
  type AutomationProgression,
  useUserProgression,
} from "hooks/useUserProgression";
import { defaultHelpLink } from "sections/Betting/CampaignPromotions";
import type { BetEntry } from "sections/Entries/types";
import * as styles from "./BetPickPromotion.module.scss";

export const BetPickPromotion = ({
  automationProgressions,
  isWon,
  entry,
}: {
  automationProgressions: Pick<AutomationProgression, "progressionId">[];
  isWon: boolean;
  entry: BetEntry;
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      {automationProgressions.map(({ progressionId }) => {
        const {
          campaign,
          isComplete,
          isFailed,
          isPromo,
          loading,
          displayPayout,
          automationId,
          progression,
          hide,
        } = useUserProgression(progressionId, entry);

        if (hide) return null;

        return (
          <div
            className={cx(styles.promotionContainer, {
              [styles.isWon]: isWon,
              [styles.loading]: loading || !progression,
            })}
            key={`${progressionId}-${automationId}`}
          >
            {loading || !progression ? (
              <Skeleton
                className={cx(styles.skeleton, { [styles.isMobile]: isMobile })}
              />
            ) : (
              <>
                <SmallPromoIcon className={styles.promoIcon} />
                <div className={styles.heading}>
                  <div>{campaign?.name}</div>
                  <a href={campaign?.termsOfUse ?? defaultHelpLink}>
                    View Terms
                  </a>
                </div>
                <div className={styles.content}>{campaign?.description}</div>
                <div className={styles.dashedLine} />
                <div className={styles.complete}>
                  <div className={styles.payout}>{displayPayout}</div>
                  <div className={styles.statusArea}>
                    {isComplete && !isFailed && (
                      <Link
                        to={`/wallet/statement/${
                          isPromo ? `?accountPurpose=promotion_user` : ``
                        }`}
                      >
                        View Statement
                      </Link>
                    )}
                    <div className={styles.clamp}>
                      <Clamp variant="warning" className={styles.clampItem}>
                        {isComplete
                          ? isFailed
                            ? `NOT PAID`
                            : `PAID`
                          : `IN PROGRESS`}
                      </Clamp>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

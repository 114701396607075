import React from "react";
import * as ToggleGroupBase from "@radix-ui/react-toggle-group";
import { Trigger } from "./components/Trigger";
import cx from "classnames";
import { cva } from "class-variance-authority";
import { Badge } from "components/Badge";

import { ReactComponent as SelectAllIcon } from "./components/assets/icon-clear.svg";
import { ReactComponent as ClearFilterIcon } from "./components/assets/icon-clear-filter.svg";

import * as styles from "./ToggleGroup.Item.module.scss";

export const itemVariant = cva([styles.item], {
  variants: {
    alignment: {
      left: styles.left,
      right: styles.right,
      none: undefined,
    },
  },
  defaultVariants: {
    alignment: "left",
  },
});

export const ClearButton = ({
  onClear,
  active,
  alignment = "left",
}: {
  onClear?: () => void;
  active: boolean;
  alignment?: Alignment;
}) => {
  return (
    <button
      onClick={() => onClear?.()}
      className={cx(itemVariant({ alignment }), { [styles.active]: active })}
    >
      <Trigger active={active} disableFade={true}>
        {active ? <SelectAllIcon /> : <ClearFilterIcon />}
      </Trigger>
    </button>
  );
};

export type Alignment = "left" | "right" | "none";

export type ItemProps = React.ComponentProps<typeof ToggleGroupBase.Item> & {
  alignment?: Alignment;
  active?: boolean;
  count?: number;
  badgeLoading?: boolean;
};

export const Item = React.forwardRef<
  React.ElementRef<typeof ToggleGroupBase.Item>,
  ItemProps
>((props, ref) => {
  const {
    active,
    alignment = "left",
    count,
    children,
    badgeLoading,
    ...baseProps
  } = props;
  const iconProps = { active, children };

  return (
    <ToggleGroupBase.Item
      {...baseProps}
      ref={ref}
      className={cx(
        itemVariant({ alignment }),
        { [styles.active]: active },
        props.className,
      )}
    >
      <Trigger {...iconProps} />
      {count && count > 0 ? (
        <Badge
          loading={badgeLoading}
          text={count?.toString()}
          className={styles.badge}
        />
      ) : null}
    </ToggleGroupBase.Item>
  );
});

Item.displayName = "ToggleItem";

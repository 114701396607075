import { useSelector } from "hooks";

export const useUserAttributes = () => {
  const attributes = useSelector((state) => state.auth?.attributes);
  return attributes;
};

export const useUserAttribute = (name: string) => {
  const attributes = useUserAttributes();
  return attributes?.[name];
};

export const useUserEventFilters = (): string[] => {
  const filters = useUserAttribute("eventFilters");
  return filters ?? [];
};

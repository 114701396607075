import React from "react";
import cx from "classnames";
import { Button } from "components/Button";
import * as support from "utilities/support";

import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";

import * as styles from "./SupportLink.module.scss";

type SearchSupport = {
  search: string;
  labels?: never;
  articleId?: never;
};

type LabelsSupport = {
  search?: never;
  labels: string[];
  articleId?: never;
};

type ArticleIdSupport = {
  search?: never;
  labels?: never;
  articleId: string;
};

export type SupportOptions = SearchSupport | LabelsSupport | ArticleIdSupport;

type SupportLinkProps = {
  className?: string;
  options?: SupportOptions;
  type?: "text" | "button";
  children?: React.ReactNode;
  hideChevron?: boolean;
  openLiveChat?: boolean;
  loading?: boolean;
};

const SupportLink = ({
  type = "text",
  className,
  options,
  children,
  hideChevron = false,
  openLiveChat = false,
  loading = false,
}: SupportLinkProps) => {
  return (
    <Button
      skeleton={loading}
      className={cx(styles.link, className)}
      variant={type === "button" ? `primary` : `link`}
      onClick={() => {
        if (openLiveChat) {
          support.openWithChat(options);
        } else {
          support.open(options);
        }
      }}
    >
      {children}
      {!hideChevron && (
        <span className={styles.icon}>
          <ChevronDown />
        </span>
      )}
    </Button>
  );
};

export { SupportLink };

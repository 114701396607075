import React from "react";
import { navigateBack } from "library/components/Link";
import { Icon } from "library";
import cx from "classnames";
import { useLocation } from "@gatsbyjs/reach-router";

import * as styles from "./BackButton.module.scss";

const optInPagesWithBackButton = [
  "coverage/",
  "match/",
  "outright/",
  "guides",
  "contest/",
  /wallet\/deposit\/[a-zA-Z0-9-_]+/,
  /racing\/betting\/race\/+/,
  /racing\/betting\/meeting\/+/,
  /account\/verification\/[a-zA-Z0-9-_]+/,
  /account\/about\/[a-zA-Z0-9-_]+/,
  /activity-statement\/+/,
];

const BackButton = () => {
  const { key, pathname } = useLocation();

  const optInAllowedBackbuttonPaths = optInPagesWithBackButton.some((path) =>
    pathname.match(path),
  );

  return (
    <button
      onClick={() => {
        navigateBack();
      }}
      className={cx({
        [styles.chevronLeft]: true,
        [styles.visible]: optInAllowedBackbuttonPaths && key !== "initial",
      })}
    >
      <Icon type="arrow-left" inline />
    </button>
  );
};

export default BackButton;

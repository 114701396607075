import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";

export const CARD_PROCESSED = "CARD_PROCESSED";

const dispatchEvent = (payload) => {
  if (window && window.parent) {
    window.parent.postMessage(
      JSON.stringify({
        type: CARD_PROCESSED,
        ...payload,
      }),
      "*",
    );
  }
};

export const ProcessMW = (props) => {
  const queryParams = queryString.parse(props.location.search);

  useEffect(() => {
    if (queryParams.cardID && queryParams.status === "success") {
      dispatchEvent({
        status: queryParams.status,
        cardID: queryParams.cardID,
      });
    } else if (queryParams.status === "error") {
      dispatchEvent({
        status: queryParams.status,
        cardID: queryParams.cardID,
        responseMessage: queryParams.message,
      });
    }
  });

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>Your card has been added</div>
    </>
  );
};

export default ProcessMW;

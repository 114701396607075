// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "SameEventMultiBuilder-module--container--c00c8";
export var emphasis = "SameEventMultiBuilder-module--emphasis--b2aa1";
export var green = "#57d33b";
export var greenLink = "SameEventMultiBuilder-module--green-link--fbe05";
export var grey = "var(--tone-mid)";
export var greyLine = "SameEventMultiBuilder-module--greyLine--5bfcb";
export var isScrollMode = "SameEventMultiBuilder-module--isScrollMode--d33d6";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var refElement = "SameEventMultiBuilder-module--refElement--26dcd";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
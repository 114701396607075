import { toast } from "hooks";
import { navigate } from "library/components/Link";
import { getRelativeUrl } from "utilities/helpers";

export type InAppMessageType = {
  message: string;
  duration?: number;
  slideFrom?: string;
  openTarget?: string;
  dismissType?: "SWIPE" | "AUTO_DISMISS";
  isControl: boolean;
  extras: Record<string, string>;
  uri: string;
  Af: "FULL" | "SLIDEUP";
};

export const showCustomIAM = (inAppMessage: InAppMessageType) => {
  if (!inAppMessage?.message) return;

  // if received from the app, the duration is a string. We need to convert it to a number
  if (typeof inAppMessage?.duration === "string") {
    inAppMessage.duration = parseInt(inAppMessage?.duration);
  }

  toast({
    description: inAppMessage.message,
    variant: (inAppMessage?.extras?.variant ?? "tips") as any,
    onClick: () => {
      if (inAppMessage.uri) {
        // if the url contains picklebet.com domain, then we only use the relative path.
        // otherwise we use the full url
        navigate(getRelativeUrl(inAppMessage?.uri));
      }
    },
    // Braze user has selected for the user to dismiss. Toast does not seem to support this. So we just set a long duration
    duration: inAppMessage?.duration > 0 ? inAppMessage?.duration : 20000,
  });
};

import React from "react";
import { isPast } from "date-fns";
import { Entry } from "components/layout/Entry";
import { EntrySkeleton } from "components/layout/Entry/EntrySkeleton";
import {
  BetOddsSummary,
  BetPayout,
  BetStake,
} from "components/layout/Entry/components/BetSummary";
import type { SharedEntry, SharedEntryPreview } from "sections/Entries/types";
import type { EntryProps } from "components/layout/Entry/Entry";

import {
  EmptySharePageMessage,
  ExpiredSharePageMessage,
} from "./SharePageMessage";

type SharedEntryCardProps = EntryProps & {
  entry?: SharedEntry | SharedEntryPreview;
  loading: boolean;
};

export const SharedEntryCard = ({
  entry,
  loading,
  ...props
}: SharedEntryCardProps) => {
  if (loading) return <EntrySkeleton />;
  if (!entry) return <EmptySharePageMessage />;
  if (isPast(entry.expiresAtTs)) return <ExpiredSharePageMessage />;

  return (
    <Entry
      entry={entry}
      footer={
        <>
          <BetStake omitStakeValue={entry.hideStake} />
          <BetOddsSummary />
          {!entry.hideStake && <BetPayout />}
        </>
      }
      {...props}
    />
  );
};

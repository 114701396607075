import {
  type IBetStatusInfo,
  type CompetitorType,
  type IMarket,
  type IMatch,
  type IMatchDisplay,
  type IMatchDisplayEntry,
  type IMatchEvent,
  type Outcome,
  MARKET_TYPES,
  type MarketStatus,
  type MatchDisplayType,
  OUTCOME_NAMES,
  type OutcomeResult,
  type ProductType,
  TOP_MARKET_TYPES,
} from "types/BetTypes";
import { FireTransforms } from "../utilities/FireTransforms";
import { toSlug } from "utilities/strings";
import { titleCase } from "title-case";
import * as dateFns from "date-fns";
import type { ITitle } from "types/ContentTypes";

function matchesSearchTerms(search: string[], match: IMatch, title: ITitle) {
  if (search?.length) {
    // build search references
    let searchRefs = [
      match.display.title,
      match.display.tournament,
      match.event.eventId,
    ];
    if (title) {
      searchRefs.push(
        ...(title?.terms || []),
        ...(title?.genre || []),
        title?.name,
        title?.slug,
      );
    }
    for (const market of match.markets) {
      searchRefs.push(market.name);
      searchRefs.push(market.id);
      for (const outcome of market.outcomes) {
        if (outcome.competitor) {
          searchRefs.push(outcome.competitor.name);
          searchRefs.push(outcome.competitor.id);
        }
      }
    }
    searchRefs = searchRefs.filter(Boolean).map((x) => toSlug(x));
    for (const searchWord of search) {
      for (const ref of searchRefs) {
        if (ref.indexOf(toSlug(searchWord)) >= 0) {
          return true;
        }
      }
    }
    return false;
  } else {
    return true;
  }
}

export namespace BettingTransforms {
  // Status Colours
  // Map of colours to resolve based on status name for the status pills.
  const OUTCOME_STATUS_COLORS = {
    WIN: "green",
    LOSE: "red",
    UNDECIDED: "yellow",
    VOID: "red",
  };

  export function getHoursUntil(date: Date) {
    date = FireTransforms.parseDate(date);
    if (dateFns.isValid(date) === false) {
      return Number.POSITIVE_INFINITY;
    }
    return dateFns.differenceInHours(date, new Date());
  }

  export function getOutcomeStatusMessage(
    outcomeResult: OutcomeResult,
    marketStatus: MarketStatus,
    marketBettingType?: string,
  ): IBetStatusInfo {
    if (outcomeResult === "UNDECIDED") {
      if (
        marketStatus == "DEACTIVATED" ||
        marketStatus === "SUSPENDED" ||
        marketBettingType?.toLowerCase() === "live"
      ) {
        return { message: "LIVE", color: "orange" };
      } else {
        return null;
      }
    } else {
      return {
        message: outcomeResult,
        color: OUTCOME_STATUS_COLORS[outcomeResult] || "blue",
      };
    }
  }

  export function filterAndSortMatches(matches: IMatch[]) {
    matches.sort((a, b) => {
      const timeA = getMatchStart(a);
      const timeB = getMatchStart(b);
      return timeA < timeB ? -1 : 1;
    });
    return matches;
  }

  export function sortOutcomes(outcomes: Outcome[], marketType: string) {
    outcomes.sort((a, b) =>
      marketType === "CORRECT_MAP_SCORE"
        ? OUTCOME_NAMES.indexOf(a.abbreviation as any) -
          OUTCOME_NAMES.indexOf(b.abbreviation as any)
        : a.type?.includes("HOME") && b.type?.includes("AWAY")
          ? -1
          : a.type?.includes("AWAY") && b.type?.includes("HOME")
            ? 1
            : a.name?.localeCompare(b?.name),
    );
    return outcomes;
  }

  function getMapNumber(market: IMarket): number {
    const mapString = market.specifiers?.map || market.specifiers?.mapnr;
    return mapString ? parseInt(mapString) : 0;
  }

  function compareSpecifiers(
    marketA: IMarket,
    marketB: IMarket,
    specifierKeys: string[],
  ): number {
    for (const specifierKey of specifierKeys) {
      const comparison =
        getSpecifierNumber(marketA, specifierKey) -
        getSpecifierNumber(marketB, specifierKey);
      if (comparison !== 0) {
        return comparison;
      }
    }
    return 0;
  }
  function getSpecifierNumber(market: IMarket, specifierKey: string): number {
    const string = market.specifiers?.[specifierKey];
    return string ? parseFloat(string) : 0;
  }

  function compareNames(a: IMarket, b: IMarket): number {
    return a.name?.localeCompare(b.name);
  }

  /**
   * Sort the markets for display.
   * 1. use the MARKET_TYPES list to sort and place these special markets first
   * 2. put any non map markets first and then sort map markets by the map number
   * 3. for markets of the same type that have a threshold sort them by the threshold number
   * 4. finally sort by name
   * @param markets
   */
  export function sortMarkets(markets: IMarket[]) {
    markets.sort((a, b) => {
      const aOrder = MARKET_TYPES.indexOf(a.type);
      const bOrder = MARKET_TYPES.indexOf(b.type);
      if (aOrder > -1 || bOrder > -1) {
        const predefinedOrder = aOrder - bOrder;
        if (predefinedOrder === 0) {
          //fall through to other comparisons
        } else if (aOrder === -1) {
          return 1;
        } else if (bOrder === -1) {
          return -1;
        } else {
          return predefinedOrder;
        }
      }

      const aMap = getMapNumber(a);
      const bMap = getMapNumber(b);
      if (aMap != bMap) {
        return aMap - bMap;
      }

      if (a.type === b.type) {
        const comparison = compareSpecifiers(a, b, [
          "threshold",
          "hcp",
          "total",
        ]);
        if (comparison !== 0) {
          return comparison;
        }
      }

      return compareNames(a, b);
    });
    return markets;
  }

  export function marketIsActive(
    market: IMarket,
    productType: ProductType = "BETTING",
  ) {
    if (!market) {
      return false;
    }
    if (market?.disabledProducts?.includes(productType)) {
      return false;
    }
    if (market.status !== "ACTIVE") {
      return false;
    }
    if (market.nextBetStop) {
      if (FireTransforms.parseDate(market.nextBetStop) < new Date()) {
        return false;
      }
    }
    return true;
  }

  export function parseOutcome(
    data: any,
    event: IMatchEvent,
    selections: any,
    marketId: string,
  ) {
    const id = data.id;
    const competitor: CompetitorType = event?.competitors?.[data.competitorId];
    return {
      ...data,
      id,
      selected: !!selections?.[id],
      competitor,
      eventId: event.eventId,
      marketId,
    } as Outcome;
  }

  export function parseMarket(
    data: any,
    event: IMatchEvent,
    selections: any[],
    activeOnly: boolean,
  ) {
    if (!data) {
      return null;
    }
    const id = data.id;
    const marketType = data.type;
    //Filter out inactive markets
    //a header for the event
    if (activeOnly && !marketIsActive(data)) {
      return null;
    }
    const outcomes = FireTransforms.unwrapSet(data.outcomes).map((entry) =>
      parseOutcome(entry, event, selections, id),
    );
    const topMarket = TOP_MARKET_TYPES.indexOf(data.type) > -1;
    sortOutcomes(outcomes, marketType);
    return {
      ...data,
      id,
      active: data.status === "ACTIVE",
      type: marketType,
      topMarket,
      name: titleCase(data.name),
      specifiers: data.specifiers,
      attributes: data.attributes,
      nextBetStop: FireTransforms.parseDate(data.nextBetStop),
      outcomes,
    } as IMarket;
  }

  export function parseEvent(entry: any) {
    if (!entry) {
      return null;
    }
    const competitors = entry.competitors as Record<string, CompetitorType>;
    const scheduledStartTime = FireTransforms.parseDate(
      entry.scheduledStartTime,
    );

    const result = {
      ...entry,
      scheduledStartTime,
      competitors,
    } as IMatchEvent;

    return result;
  }

  export function parseMatch(
    matchData: any,
    selections: any[],
    activeOnly: boolean,
  ) {
    return parseCommonEventData(matchData, selections, "match", activeOnly);
  }

  export function parseMarkets(
    fbMarkets,
    event: IMatchEvent,
    selections: any[],
    activeOnly: boolean,
  ) {
    const markets = FireTransforms.unwrapSet(fbMarkets)
      .map((x) => parseMarket(x, event, selections, activeOnly))
      .filter(Boolean);

    sortMarkets(markets);
    return markets;
  }

  function parseCommonEventData(
    matchData: any,
    selections: any[],
    type: string,
    activeOnly?: boolean,
  ) {
    if (!matchData || !matchData.event) {
      return null;
    }

    const event: IMatchEvent = parseEvent(matchData.event);
    const markets = matchData.markets
      ? parseMarkets(matchData.markets, event, selections, activeOnly)
      : [];

    //All the markets should be main markets now with the first active one deemed the most important
    //Outrights wont have a topMarket market
    const topMarket = markets.find((it) => it?.status === "ACTIVE");

    const exoticsCount = topMarket
      ? matchData.event.activeMarketCount - 1
      : matchData.event.activeMarketCount;

    const display = eventDisplayAttributes(event);
    display.exoticsCount = exoticsCount;

    return {
      ...matchData,
      loaded: true,
      id: event.eventId,
      type,
      title: event.sport,
      topMarket,
      markets,
      event,
      display,
    } as IMatch;
  }

  export function parseOutright(outright: any, selections: any[]) {
    const match = parseCommonEventData(outright, selections, "outright");
    if (match) {
      match.display.tournament = titleCase(
        match.event?.attributes?.tournamentName ||
          match.event?.attributes?.seasonName ||
          match.event?.eventName ||
          "",
      );
    }
    return match;
  }

  export function getMatchStart(match: IMatch) {
    return getMatchStartByMarket(null, match);
  }

  export function getMatchStartByMarket(market: IMarket, match: IMatch) {
    return FireTransforms.parseDate(
      market?.nextBetStop ||
        match.topMarket?.nextBetStop ||
        match.event?.scheduledStartTime,
    );
  }

  export function matchDoesExpire(match: IMatch) {
    return match.type === "match" && Boolean(match.topMarket?.nextBetStop);
  }

  export function eventDisplayAttributes(event: IMatchEvent) {
    let round = event.attributes?.roundName;
    if (round === "group") {
      round = "group stage";
    }
    return {
      icon: event.sport.toLowerCase(),
      tournament: event?.attributes?.tournamentName || "Unknown Tournament",
      round,
      matchMode: event?.attributes?.matchMode,
    } as IMatchDisplay;
  }

  function sortOutright(a: MatchDisplayType, b: MatchDisplayType) {
    if (a !== b) {
      if (a === "outright") {
        return -1;
      }
      if (b === "outright") {
        return 1;
      }
    }
    return 0;
  }

  function sortDate(a: Date, b: Date) {
    return FireTransforms.parseDate(a) < FireTransforms.parseDate(b) ? -1 : 1;
  }

  export function getDisplayMatchSet(
    matches: IMatch[],
    {
      search = [] as string[],
      limit = 0 as number,
      activeGames = null as string[],
      activeTournaments = null as string[],
      types = null as MatchDisplayType[],
      titleMap = null as Record<string, ITitle>,
    },
  ): { displays: IMatchDisplayEntry[]; limited: boolean } {
    search = search?.map((x) => x.trim().toLowerCase()).filter(Boolean) || [];
    const displays: IMatchDisplayEntry[] = [];
    let limited = false;

    const filters = [];
    // filter first
    if (types?.length) {
      // filter by match.outright
      filters.push((x) => types.includes(x.type));
    }
    // active only
    if (activeGames?.length) {
      // filter by game
      filters.push((x) =>
        activeGames.includes((x.event?.sport as string).toLowerCase()),
      );
    }
    if (activeTournaments?.length) {
      // filter by tournament
      filters.push((x) => {
        const tournamentHash = toSlug(x.event?.attributes?.tournamentName);
        return tournamentHash && activeTournaments.includes(tournamentHash);
      });
    }

    //prior sort so we can limit
    matches = matches.sort((a, b) => {
      return (
        sortOutright(a.type, b.type) ||
        sortDate(getMatchStart(a), getMatchStart(b))
      );
    });

    for (const match of matches) {
      if (!filters.every((it) => it(match))) {
        continue;
      }
      const title = titleMap[match.event?.sport.toLowerCase()];

      if (matchesSearchTerms(search, match, title)) {
        displays.push({
          id: match.id,
          type: match.type,
          title: title,
          tournament: match.display.tournament,
          startTime: getMatchStart(match),
        });
      }
      if (limit && displays.length >= limit) {
        limited = true;
        break;
      }
    }
    displays.sort(
      (a, b) =>
        sortOutright(a.type, b.type) || sortDate(a.startTime, b.startTime),
    );

    // mark repeats
    if (displays.length > 1) {
      for (let i = 1; i < displays.length; i++) {
        const current = displays[i];
        const prev = displays[i - 1];
        current.repeatGame = prev.title?.slug === current.title?.slug;
        current.repeatTournament =
          prev.tournament === current.tournament && prev.type == current.type;
      }
    }
    return {
      displays,
      limited,
    };
  }
}

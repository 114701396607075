// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var checkbox = "Checkbox-module--checkbox--9ffcb";
export var container = "Checkbox-module--container--3481a";
export var disabled = "Checkbox-module--disabled--2907e";
export var emphasis = "Checkbox-module--emphasis--7342b";
export var error = "Checkbox-module--error--79e76";
export var green = "#57d33b";
export var greenLink = "Checkbox-module--green-link--f0a78";
export var grey = "var(--tone-mid)";
export var icon = "Checkbox-module--icon--c88aa";
export var iconContainer = "Checkbox-module--iconContainer--94d47";
export var label = "Checkbox-module--label--b9bae";
export var noSpacing = "Checkbox-module--noSpacing--8a8e0";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
import React, { createContext, useMemo } from "react";
import {
  useCreditCards,
  useIsLoggedIn,
  useIsProfileComplete,
  useCampaigns,
  useIsQuickDepositAvailable,
  useCreditCardVerifications,
} from "hooks";
import usePreferences, {
  type Preferences,
} from "../hooks/firestore/user/usePreferences";
import useAuthState from "hooks/firestore/useAuthState";
import {
  useNotificationsCount,
  usePickemsEventCounts,
  usePromotionClaims,
} from "hooks/firestore";
import type { Notification } from "hooks/firestore/user/useNotifications";
import type { Profile } from "hooks/firestore/user/types";
import type { IEntry } from "types/BetTypes";
import type { CreditCard } from "types/UserTypes";
import type { PickslipState } from "sections/Pickems/PickSlip/pickslipSlice";
import { useEventCounts } from "hooks/firestore/betting/useEventCounts";
import type { EventCounts } from "hooks/firestore/betting/useEventCounts";
import { getDefaultBettingPath } from "utilities/sharedBettingUtilities";
import type { CreditCardVerification } from "hooks/firestore/user/useCreditCardVerifications";
import type { PromotionClaim } from "types/PromotionTypes";

export type FirebaseContextObject = {
  eventCounts: EventCounts;
  userPreferences?: Preferences;
  creditCards?: CreditCard[];
  bettingPath?: string;
  profile?: Profile;
  entries?: {
    upcomingAndLiveEntries: IEntry[];
    isStreamAvailable: boolean;
    upcomingCount: number;
    liveCount: number;
    upcomingAndLiveCount: number;
  };
  notifications?: {
    top: Notification[] | undefined;
    count: number;
  };
  loggedIn?: boolean;
  profileComplete?: boolean;
  promotionClaims?: PromotionClaim[];
  utilities: any;
  loadingBar: any;
  campaigns: string[];
  isQuickDepositAvailable: boolean;
  pickslip: PickslipState;
  betslip: any;
  verifications?: CreditCardVerification[];
};

export const FirebaseContext = createContext<FirebaseContextObject>(
  {} as FirebaseContextObject,
);

export const FirebaseContextProvider = ({ element }) => {
  const [profile] = useAuthState();

  const [promotionClaims] = usePromotionClaims();

  const [campaigns] = useCampaigns();

  const [userPreferences] = usePreferences();

  const [creditCards] = useCreditCards();

  const isQuickDepositAvailable = useIsQuickDepositAvailable(creditCards);

  const bettingPath = getDefaultBettingPath(userPreferences?.defaultHub);

  const notificationsCount = useNotificationsCount();

  const loggedIn = useIsLoggedIn();

  const profileComplete = useIsProfileComplete();

  const [verifications] = useCreditCardVerifications();

  const [bettingCounts] = useEventCounts();

  const [pickemsCounts] = usePickemsEventCounts();

  const eventCounts = useMemo(
    () => ({ ...bettingCounts, ...pickemsCounts }),
    [bettingCounts, pickemsCounts],
  );

  return (
    <FirebaseContext.Provider
      value={
        {
          eventCounts,
          userPreferences,
          creditCards,
          bettingPath,
          profile,
          notifications: {
            count: notificationsCount,
          },
          loggedIn,
          profileComplete,
          promotionClaims,
          campaigns,
          isQuickDepositAvailable,
          verifications,
        } as any
      }
    >
      {element}
    </FirebaseContext.Provider>
  );
};

import React from "react";
import cx from "classnames";
import type { OddsFormat } from "types/BetTypes";
import { formatOdds } from "sections/Betting/Odds";
import BigNumber from "bignumber.js";

import * as styles from "./BetOdds.module.scss";

// Available Formats:
// - decimal
// - fractional
// - moneyline (american)
// - impliedProbability (50%)
// - hongKong
// - malay
// - indonesian

export type BetOddsProps = {
  base?: number;
  format?: OddsFormat;
  className?: string;
  prefix?: string;
  zero?: string;
  wrap?: boolean;
  settlementOdds?: number;
  isVoid?: boolean;
};

export const BetOdds = (props: BetOddsProps) => {
  const {
    format = "decimal",
    className,
    prefix,
    zero = "-",
    settlementOdds,
    isVoid,
  } = props;

  let odds: string;
  let oldOdds: string;

  // make sure settlementOdds and base are both forced to precision of 2
  const base = new BigNumber(props.base)
    .dp(2, BigNumber.ROUND_FLOOR)
    .toNumber();

  if (isVoid) {
    odds = "VOID";
    oldOdds = formatOdds(base, format, "");
  } else if (
    settlementOdds <= 1 &&
    settlementOdds !== base &&
    (format === "moneyline" || format === "american" || format === "fractional")
  ) {
    odds = `${prefix} ${formatOdds(base, format, "")} - ${(
      (settlementOdds / base) *
      100
    ).toFixed(2)}%`;
  } else if (settlementOdds && settlementOdds !== base) {
    odds = formatOdds(settlementOdds, format, "");
    oldOdds = base <= 0 && zero ? zero : formatOdds(base, format, "");
  } else {
    odds = base <= 0 && zero ? zero : formatOdds(base, format, prefix);
  }

  const oldOddsStyles = {
    textDecoration: "line-through",
    textDecorationThickness: "2px",
  };

  return (
    <div
      title={odds}
      className={cx({
        [styles.odds]: true,
        [className]: className,
      })}
    >
      {oldOdds && (
        <span>
          {prefix} <span style={oldOddsStyles}>{oldOdds}</span>{" "}
        </span>
      )}
      <span>{odds}</span>
    </div>
  );
};

import React, { useContext } from "react";
import { useDispatch } from "hooks";
import { showLoginModal } from "utilities/Auth/authSlice";
import { Button } from "components/Button";
import { ReactComponent as TrashIcon } from "components/assets/trash.svg";
import cx from "classnames";
import * as styles from "./Slip.module.scss";
import { OddsChanges } from "components/OddsChanges";
import { ReactComponent as ArrowDown } from "components/assets/flucs-down.svg";
import { ReactComponent as ArrowUp } from "components/assets/flucs-up.svg";
import Odds from "sections/Betting/Odds";
import { FirebaseContext } from "context/Firebase";
import { Caret } from "components/Caret";

export const Trash = ({ onClick }) => (
  <div onClick={onClick} className={styles.trash}>
    <TrashIcon />
  </div>
);

type SlipProps = {
  selectionsLength: number;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  isLoggedIn: boolean;
  odds: number;
  loading: boolean;
  handleRemoveAllSelections: () => void;
  addToBetSlip: () => void;
  areAnySelectionsNotActive: boolean;
};

export const Slip = ({
  selectionsLength,
  isOpen,
  setIsOpen,
  isLoggedIn,
  odds,
  loading,
  handleRemoveAllSelections,
  addToBetSlip,
  areAnySelectionsNotActive,
}: SlipProps) => {
  const dispatch = useDispatch();
  const { userPreferences } = useContext(FirebaseContext);

  return (
    <div className={cx(styles.slip)} onClick={() => setIsOpen(!isOpen)}>
      <div
        className={cx(styles.showSelections)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Caret variant={isOpen ? "down" : "up"} className={styles.caret} />
      </div>

      <Trash onClick={handleRemoveAllSelections} />
      <div className={styles.text}>
        <div className={styles.label}>LEGS</div>
        <div className={styles.textValue}>{selectionsLength}</div>
      </div>
      <div className={styles.line}>&nbsp;</div>
      {isLoggedIn && (
        <OddsChanges
          odds={Number(odds)}
          className={cx(styles.text, styles.odds)}
        >
          <div className={styles.label}>ODDS</div>

          <div
            className={cx(styles.textValue, {
              [styles.oddsChanging]: loading,
            })}
          >
            <Odds
              base={Number(odds)}
              zero={`-`}
              format={userPreferences.oddsFormat}
            />
            {odds > 0 && (
              <>
                <div className={styles.arrowUp}>
                  <ArrowUp />
                </div>

                <div className={styles.arrowDown}>
                  <ArrowDown />
                </div>
              </>
            )}
          </div>
        </OddsChanges>
      )}
      <div className={styles.buttonContainer}>
        {isLoggedIn ? (
          <Button
            className={styles.addButton}
            loading={loading}
            variant="primary"
            size="sm"
            disabled={
              selectionsLength <= 1 ||
              loading ||
              odds === 0 ||
              areAnySelectionsNotActive
            }
            onClick={(e) => {
              e.stopPropagation();
              addToBetSlip();
            }}
          >
            {areAnySelectionsNotActive
              ? "Selection Suspended"
              : selectionsLength === 1
                ? "Add 1+ Selection"
                : "Add to Betslip"}
          </Button>
        ) : (
          <Button
            className={styles.loginButton}
            loading={loading}
            variant="primary"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              dispatch(showLoginModal());
            }}
          >
            Login to Bet
          </Button>
        )}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { useCountdown } from "hooks/useCountdown";
import { Clamp } from "components/Clamp";
import cx from "classnames";
import type { PickType } from "sections/Entries/types";
import { StreamButton } from "components/layout/StreamButton";

import * as styles from "./Countdown.module.scss";
import { InfoPopover } from "components/InfoPopover";
import { format } from "date-fns";

const Countdown = ({
  scheduledStartTime,
  pick,
  className,
  clampClassName,
  shape,
}: {
  scheduledStartTime: Date;
  pick?: PickType;
  className?: string;
  clampClassName?: string;
  shape?: "rounded" | "default";
}) => {
  const [remaining, secondsToNow] = useCountdown(scheduledStartTime);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isEminent = secondsToNow < 10 * 60; // within 10 mins
  const isSoon = secondsToNow > 10 * 60 && secondsToNow < 20 * 60; // between 10 & 20 mins

  const earlyBirdStream = secondsToNow <= 30 * 60; // 30 mins

  return (
    <div className={cx(styles.root, className)}>
      {earlyBirdStream && pick && (
        <StreamButton
          title={pick.eventName}
          sport={pick.sport}
          url={pick.streamUri}
          startTime={pick.scheduledStartTime}
          status={pick.statusText}
          eventId={pick.eventId}
          isPlaceholder={pick.isPlaceholder}
        />
      )}

      <InfoPopover
        content={format(scheduledStartTime, "dd MMM yy '@' h:mma")}
        open={isOpen}
        onOpenChange={(open) => setIsOpen(open)}
        triggerProps={{
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          },
          onMouseEnter: () => setIsOpen(true),
          onMouseLeave: () => setIsOpen(false),
        }}
      >
        <Clamp
          className={cx(
            styles.countdown,
            {
              [styles.isSoon]: isSoon,
              [styles.isEminent]: isEminent,
            },
            clampClassName,
          )}
          shape={shape}
        >
          {remaining}
        </Clamp>
      </InfoPopover>
    </div>
  );
};

export { Countdown };

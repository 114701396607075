import React, { useContext } from "react";
import { Tab } from "components/Tabs";
import * as styles from "./RaceNumberTab.module.scss";
import cx from "classnames";
import { ReactComponent as SmallPromoIcon } from "components/assets/icon-star-small.svg";
import type { RaceEventType } from "sections/Betting/Race/hooks/RacingTypes";
import { RaceStatusIndicator } from "./RaceStatusIndicator";
import { RaceContext } from "../../Race";
import { motion } from "framer-motion";

export type RaceNumberTabType = RaceEventType & {
  value: string;
};

export default (race: RaceNumberTabType) => {
  const { route, raceMeeting, races } = useContext(RaceContext);
  const currentRaceNumber = raceMeeting?.currentRace?.number;
  const selectedRaceNumber = races?.find((r) => r.id === route?.raceId)?.number;
  const { number, status } = race;
  const isCurrentRace = number === selectedRaceNumber;

  const badge = (
    <>
      {isCurrentRace && (
        <motion.div
          className={cx(styles.arrowUp)}
          layoutId={`race-number-arrow`}
          transition={{ duration: 0.15 }}
        />
      )}
      <RaceStatusIndicator {...race} currentRaceNumber={currentRaceNumber} />
      {race.promotionIsAvailable && (
        <SmallPromoIcon
          className={cx(styles.tabPromoIcon, {
            [styles.inactive]: status !== "OPEN",
          })}
        />
      )}
    </>
  );

  return (
    <Tab value={race?.id?.toString()} badge={badge}>
      <span
        className={cx(styles.raceNumber, {
          [styles.active]: status === "OPEN",
        })}
      >
        R{number}
      </span>
    </Tab>
  );
};

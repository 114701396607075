// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var closeIcon = "Selection-module--closeIcon--e3351";
export var emphasis = "Selection-module--emphasis--34c3f";
export var green = "#57d33b";
export var greenLink = "Selection-module--green-link--04f39";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var outcomeType = "Selection-module--outcomeType--303e2";
export var red = "#ff2741";
export var selection = "Selection-module--selection--d4cb0";
export var selectionDisabled = "Selection-module--selectionDisabled--5ded0";
export var selectionName = "Selection-module--selectionName--fcfd1";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";
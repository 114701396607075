import React, { useState } from "react";
import { CurrencyInput, QuickInput } from "components/CurrencyInput";
import { useBetslip, useFiatCurrency, useIsLoggedIn, useSelector } from "hooks";
import { getCurrencySymbol } from "common";
import cx from "classnames";
import BigNumber from "bignumber.js";
import { selectUsedPromoAmount } from "sections/Betting/Betslip/betslipSlice";
import { ReactComponent as ClearIcon } from "components/assets/close.svg";

import * as styles from "./Stake.module.scss";

type StakeProps = {
  amount: number;
  onChange: (stake: number) => void;
  isPromo: boolean;
  isActive: boolean;
};

const Stake = ({ amount, onChange, isPromo, isActive }: StakeProps) => {
  const {
    props: {
      availableBalance,
      availablePromoBalance,
      betslipIsSubmitting,
      betslipIsReviewingMessages,
      betType,
    },
  } = useBetslip();
  const isMulti = betType === "MULTI";
  const fiatCurrency = useFiatCurrency();
  const currencySymbol = getCurrencySymbol(fiatCurrency);
  const usedPromoBalance = useSelector(selectUsedPromoAmount);
  const isLoggedIn = useIsLoggedIn();

  const [hasFocus, setHasFocus] = useState(false);

  const shouldBeDisabled =
    betslipIsSubmitting || betslipIsReviewingMessages || !isActive;

  const handleChange = (value: number) => {
    onChange(new BigNumber(amount).plus(value).toNumber());
  };

  const promoStake = isPromo ? amount * 100 : 0;
  const remainingPromoBalance = isMulti
    ? availablePromoBalance
    : availablePromoBalance - usedPromoBalance + promoStake;

  return (
    <div>
      <CurrencyInput
        containerClassName={styles.container}
        inputClassName={styles.input}
        wrapperClassName={cx(styles.wrapper, {
          [styles.disabled]: shouldBeDisabled,
        })}
        currency={fiatCurrency}
        onChange={(value) => onChange(value)}
        value={amount}
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
        disabled={shouldBeDisabled}
        renderClearIcon={(props) => (
          <span className={styles.clear} {...props}>
            <ClearIcon />
          </span>
        )}
        iconContainerClassName={styles.iconContainer}
      />
      <QuickInput.Root
        isVisible={hasFocus && !shouldBeDisabled}
        className={styles.quickInput}
        currencySymbol={currencySymbol}
        size={"sm"}
      >
        <QuickInput.Option onChange={handleChange} value={5} />
        <QuickInput.Option onChange={handleChange} value={10} />
        <QuickInput.Option onChange={handleChange} value={50} />
        <QuickInput.Option onChange={handleChange} value={100} />
        {isLoggedIn && availableBalance > 0 && (
          <QuickInput.Option
            onChange={(value) => onChange(value)}
            value={
              isPromo ? remainingPromoBalance / 100 : availableBalance / 100
            }
            label={`Bal`}
          />
        )}
      </QuickInput.Root>
    </div>
  );
};

export { Stake };

import { useHasPermission } from "./useHasPermission";
import { useIsLoggedIn } from "hooks";

const useMustHidePickems = () => {
  const canViewContests = useHasPermission("viewContest");

  return !canViewContests;
};

export default useMustHidePickems;

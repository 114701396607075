import React, { useMemo } from "react";
import cx from "classnames";
import type { CompetitorType } from "types/BetTypes";
import * as styles from "./CompetitorIcon.module.scss";
import appConfig from "appConfig";
import { isAppleWebView } from "utilities/display";
import { buildCloudinaryTransformation } from "utilities/sharedBettingUtilities";

type CompetitorIconProps = {
  competitor: CompetitorType;
  className?: string;
  size?: string | number;
};

export const CompetitorIcon = ({
  competitor,
  className,
  size = "30px",
}: CompetitorIconProps) => {
  const sizeInt = parseInt(size as any, 10);

  let iconUrl = buildCloudinaryTransformation(competitor, sizeInt);

  let competitorTypeClass = styles[competitor?.type?.toLowerCase()];

  const iconStyle = useMemo(
    () => ({
      width: size,
      height: size,
      minWidth: size,
    }),
    [size],
  );

  // show flag if no icon
  if (!competitor?.iconUri && competitor?.nationality) {
    iconUrl = `${
      isAppleWebView() ? appConfig.DOMAIN : ""
    }/flags/${competitor?.nationality?.toLowerCase()}.png`;
    competitorTypeClass = styles.flag;
  }

  if (iconUrl) {
    return (
      <div
        className={cx({
          [styles.icon]: true,
          [className]: !!className,
          [competitorTypeClass]: true,
        })}
        style={iconStyle}
        title={competitor?.name}
      >
        <img src={iconUrl} alt="Competitor Icon" />
      </div>
    );
  } else {
    // fallback to competitor abbreviation
    return (
      <div
        className={cx({
          [styles.icon]: true,
          [className]: !!className,
        })}
        style={{ width: size, height: size, minWidth: size }}
      >
        {(competitor?.abbreviation || competitor?.name)
          ?.substring(0, 3)
          .toUpperCase()}
      </div>
    );
  }
};

export default CompetitorIcon;

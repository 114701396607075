import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import cx from "classnames";
import { ReactComponent as Check } from "./tick.svg";

import * as styles from "./Checkbox.module.scss";

type CheckboxProps = {
  label?: React.ReactNode;
  hasError?: boolean;
  noSpacing?: boolean;
  labelClassName?: string;
} & CheckboxPrimitive.CheckboxProps;

const Checkbox = React.forwardRef<
  React.ElementRef<React.ForwardRefExoticComponent<CheckboxProps>>,
  React.ComponentPropsWithoutRef<React.ForwardRefExoticComponent<CheckboxProps>>
>(
  (
    {
      className,
      noSpacing,
      labelClassName,
      label,
      id,
      disabled,
      hasError,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cx(styles.container, {
          [styles.disabled]: disabled,
          [styles.error]: hasError,
          [styles.noSpacing]: noSpacing,
        })}
      >
        <CheckboxPrimitive.Root
          ref={ref}
          className={cx(styles.checkbox, className)}
          id={id}
          disabled={disabled}
          {...props}
        >
          <CheckboxPrimitive.Indicator className={styles.iconContainer}>
            <Check className={styles.icon} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && (
          <label htmlFor={id} className={cx(styles.label, labelClassName)}>
            {label}
          </label>
        )}
      </div>
    );
  },
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

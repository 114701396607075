import React, { useContext } from "react";
import { Button } from "components/Button";
import { Dialog } from "components/Dialog";
import { FirebaseContext } from "context/Firebase";
import type { BetEntry } from "sections/Entries/types";

import { useShareEntryHandler } from "./useShareEntryHandler";
import { ShareEntryForm } from "./ShareEntryForm";

import * as styles from "./ShareEntryForm.module.scss";

export type ShareEntryActionProps = {
  className?: string;
  referenceId: string;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  icon?: React.ReactNode;
  entry?: [BetEntry, boolean];
};

export const ShareEntryAction = ({
  referenceId,
  className,
  icon,
  isOpen,
  setOpen,
  entry,
}: ShareEntryActionProps) => {
  const { profile } = useContext(FirebaseContext);

  const [handleSubmit, needsSecondSubmit, hasUrl, clearUrl] =
    useShareEntryHandler(referenceId, () => setOpen(false));

  const onFieldTouched = () => {
    if (hasUrl) clearUrl();
  };

  return (
    <>
      {icon && (
        <div className={className} onClick={() => setOpen(true)}>
          {icon}
        </div>
      )}

      <Dialog
        isOpen={isOpen}
        className={styles.dialog}
        title="Share your bet"
        description="Your bet will appear as below"
        onClose={() => setOpen(false)}
      >
        <ShareEntryForm
          entry={entry}
          username={profile?.username}
          onSubmit={handleSubmit}
          onFieldTouched={onFieldTouched}
          submitButton={(isSubmitting: boolean) =>
            needsSecondSubmit ? (
              <Button
                type="submit"
                loading={isSubmitting}
                variant={hasUrl ? "primary" : "info"}
              >
                {hasUrl ? "Copy Link" : "Get Share URL"}
              </Button>
            ) : (
              <Button type="submit" loading={isSubmitting}>
                Share
              </Button>
            )
          }
        />
      </Dialog>
    </>
  );
};

import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box } from "components/Box";
import { Outcome } from "./Outcome";
import { Summary } from "./Summary";
import { Checkbox } from "components/Checkbox";
import { useBetslip, useDispatch, useSelector } from "hooks";
import { SingleCardAlert } from "./SingleCardAlert";
import {
  selectPayoutForSelection,
  selectUsedPromoAmount,
} from "sections/Betting/Betslip/betslipSlice";
import { useSelection, useSelectionAlert } from "../hooks/useSelection";
import { MultiCardAlert } from "./MultiCardAlert";

import * as styles from "./BetCard.module.scss";

type BetCardProps = {
  outcomeIds: string[];
};

const BetCard = ({ outcomeIds }: BetCardProps) => {
  const {
    props: {
      multiStake,
      combinedOdds,
      isMultiUsingPromo,
      multiPayout,
      availablePromoBalance,
      betslipIsReviewingMessages,
      betslipIsSubmitting,
      betType,
      hasSPMarkets,
      multiRejected,
      hasMultiOutright,
    },
    actions: {
      setStakeSingle,
      setStakeMulti,
      setMultiIsUsingPromo,
      setIsUsingPromo,
    },
  } = useBetslip();
  // this would be used for a single selection bet card
  const selection = useSelection(outcomeIds[0]);

  const dispatch = useDispatch();
  const isMulti = betType === "MULTI";
  const stake = isMulti ? multiStake / 100 : selection.stake;
  const odds = isMulti ? combinedOdds : selection.odds;
  const singlePayout = useSelector(selectPayoutForSelection)(outcomeIds[0]);
  const payout = isMulti ? multiPayout : singlePayout;
  const usedSinglePromoBalance = useSelector(selectUsedPromoAmount);
  const usedMultiPromoBalance = isMultiUsingPromo ? multiStake : 0;
  const usedPromoBalance = isMulti
    ? usedMultiPromoBalance
    : usedSinglePromoBalance;
  const remainingPromoBalance = availablePromoBalance - usedPromoBalance;
  const alert = useSelectionAlert(selection);
  const isOutright = selection.sourceType === "outright" || hasMultiOutright;

  // should show promo box if:
  // - there is enough promo balance
  // - we are in active state and not reviewing messages OR when confirming OR when selection is inactive and not an outright
  const isUsingPromo = isMulti ? isMultiUsingPromo : selection.isUsingPromo;
  const hasEnoughPromoBalance =
    remainingPromoBalance !== 0 && remainingPromoBalance / 100 >= stake;
  const shouldHidePromo =
    betslipIsReviewingMessages || betslipIsSubmitting || !!alert || isOutright;

  const shouldShowPromo =
    (!shouldHidePromo && hasEnoughPromoBalance) || isUsingPromo;

  const resetPromo = () => {
    if (isMulti) {
      dispatch(setMultiIsUsingPromo(false));
    } else {
      dispatch(setIsUsingPromo({ outcomeId: outcomeIds[0], active: false }));
    }
  };

  return (
    <motion.div className={styles.cardWrapper}>
      {isMulti ? (
        <MultiCardAlert />
      ) : (
        <SingleCardAlert outcomeId={selection.outcomeId} />
      )}
      <Box className={styles.card} hideHeader>
        {outcomeIds.map((outcomeId, index) => (
          <Outcome
            key={`outcome-${outcomeId}`}
            outcomeId={outcomeId}
            isLast={index === outcomeIds.length - 1}
          />
        ))}
        <div className={styles.footer}>
          <Summary
            isSP={isMulti ? hasSPMarkets : selection.isSP}
            isActive={isMulti ? !multiRejected && !alert : !alert} // if there is an alert we treat the card as inactive
            onSetStake={(newStake) => {
              // if our selection already using promo we want to re-add it to promo balance before calculating new value
              const remainingPromoBalanceWithoutStake = isUsingPromo
                ? remainingPromoBalance + stake * 100
                : remainingPromoBalance;

              if (remainingPromoBalanceWithoutStake / 100 < newStake) {
                resetPromo();
              }
              if (isMulti) {
                dispatch(setStakeMulti({ stake: newStake }));
              } else {
                dispatch(
                  setStakeSingle({
                    outcomeId: outcomeIds[0],
                    stake: newStake,
                  }),
                );
              }
            }}
            stake={stake}
            payout={payout}
            odds={isMulti && hasSPMarkets ? 0 : odds}
            isPromo={isUsingPromo}
            newOdds={selection?.changes?.newOdds}
          />
          <AnimatePresence>
            {shouldShowPromo && (
              <motion.div
                initial={{
                  opacity: 0,
                  height: 0,
                }}
                animate={{
                  opacity: 1,
                  height: `auto`,
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                }}
                className={styles.options}
              >
                <Checkbox
                  className={styles.bonus}
                  id={`bonus-${outcomeIds[0]}`}
                  noSpacing
                  disabled={betslipIsReviewingMessages}
                  label={`Bonus Cash`}
                  labelClassName={styles.label}
                  checked={isUsingPromo}
                  onCheckedChange={(checked) => {
                    if (isMulti) {
                      dispatch(setMultiIsUsingPromo(!!checked));
                    } else {
                      dispatch(
                        setIsUsingPromo({
                          outcomeId: outcomeIds[0],
                          active: !!checked,
                        }),
                      );
                    }
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Box>
    </motion.div>
  );
};

export { BetCard };

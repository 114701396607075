import { useMemo, useContext, useEffect } from "react";
import { usePermissions } from "hooks";
import { PermissionReason } from "utilities/Auth/authSlice";
import { RaceStatuses } from "./RacingTypes";
import { RaceContext } from "../Race";
import { useLocalStorage } from "usehooks-ts";
import type { TabType } from "sections/Betting/Racing/hooks/useRaceRoute";

export const useRaceTabVisibility = () => {
  const {
    races,
    competitors,
    route,
    setRouteValue,
    raceMeeting,
    loading,
    myBets,
    myBetsLoading,
  } = useContext(RaceContext);

  const race = races?.find((r) => r.id === route?.raceId);

  const permissions = usePermissions();

  const [defaultRaceTab, setDefaultRaceTab] = useLocalStorage<TabType>(
    "defaultRaceTab",
    permissions.submitTop4Bet === PermissionReason.GRANTED
      ? "top4"
      : "win-place",
  );

  useEffect(() => {
    if (
      (["top4", "win-place", "same-race-multi"] as TabType[]).includes(
        route.tab,
      )
    )
      setDefaultRaceTab(route.tab);
  }, [route.tab]);

  const raceIsActive = race?.status === RaceStatuses.OPEN;

  const showDeductions = useMemo(() => {
    return !raceIsActive && competitors?.some((x) => x.scratched);
  }, [competitors, raceIsActive]);

  useEffect(() => {
    /// If the state changes there we should make sure the default tab is set for the new state
    if (loading) return;
    if (!race) return;
    if (!raceMeeting) return;

    if (race?.status === RaceStatuses.OPEN) {
      if (
        route.tab === "win-place" ||
        route.tab === "same-race-multi" ||
        route.tab === "top4"
      ) {
        return;
      }

      if (route?.tab === "results" || route?.tab === "deductions") {
        setRouteValue({
          tab: defaultRaceTab,
        });
      }
    } else {
      if (route.tab === "results" || route.tab === "deductions") {
        if (!showDeductions && route.tab === "deductions") {
          setRouteValue({ tab: "results" });
        }
        return;
      }

      if (
        route?.tab === "win-place" ||
        route?.tab === "same-race-multi" ||
        route?.tab === "top4"
      ) {
        setRouteValue({ tab: "results" });
      }
    }

    return;
  }, [race, route, showDeductions, loading, raceMeeting]);

  useEffect(() => {
    if (
      (permissions.submitTop4Bet !== PermissionReason.GRANTED &&
        route.tab === "top4") ||
      (permissions.submitSRMBet !== PermissionReason.GRANTED &&
        route.tab === "same-race-multi")
    ) {
      setRouteValue({ tab: "win-place" });
    }
  }, [
    permissions,
    route.tab,
    raceMeeting?.sport,
    myBets,
    myBetsLoading,
    loading,
  ]);

  useEffect(() => {
    if (route.tab === "my-bets") {
      setRouteValue({ tab: defaultRaceTab });
    }
  }, [route?.raceId]);

  const showTop4 =
    raceIsActive && permissions?.submitTop4Bet === PermissionReason.GRANTED;

  const showSRM =
    raceIsActive && permissions?.submitSRMBet === PermissionReason.GRANTED;

  const showWinPlace =
    raceIsActive && permissions?.submitPlaceBet === PermissionReason.GRANTED;

  return {
    defaultRaceTab,
    showTop4,
    showSRM,
    showWinPlace,
    showDeductions,
    raceIsActive,
  };
};
